import { Form } from 'react-bootstrap';
import styled from 'styled-components';

export const Container = styled(Form)`
  display: flex;
  align-items: flex-end;
  .wrapper-profile {
    max-width: 19.9rem;

    .wrapper-image {
      height: 19.9rem;
      width: 19.9rem;
      margin: auto;
      background-color: ${(props) => props.theme.colors.scrollbar};
      img {
        width: 100%;
        object-fit: scale-down;
      }
    }
  }

  .btn {
    background-color: ${(props) => props.theme.colors.backgrounds.one};
    font-weight: 400;
    width: 100%;
  }

  .btn-update {
    margin-left: -10rem;
    margin-bottom: -7rem;
  }
`;
