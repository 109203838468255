import styled from 'styled-components';

import { Container as ContainerBootstrap } from 'react-bootstrap';

export const Container = styled(ContainerBootstrap)`
  margin-top: var(--default-margin-top);

  .wrapper {
    background-color: ${(props) => props.theme.colors.background};
    border-radius: var(--radius);

    height: 60rem;
    max-width: 802px;
    padding: 2rem;

    @media (max-width: 1000px) {
      max-width: auto;
    }
  }
  .border-th {
    font-size: 1rem;
    padding: 1rem;
    border: 0.1rem solid ${(props) => props.theme.colors.backgrounds.two};
    border-radius: 0.4rem;
    width: 32.7rem;
    display: flex;
    align-items: center;
    th {
      padding: 0;
      width: 6rem;
      line-height: 1rem;
      text-align: center;
      vertical-align: middle;
    }
  }

  table {
    width: 100%;
    color: #000000;
    border-collapse: collapse;
    font-family: var(--font-tertiary);
    thead {
      font-size: 1.4rem;
      margin-left: 18.7rem;
      margin-bottom: 2rem;
    }

    thead {
      display: flex;
      align-items: center;
    }
    tr {
      display: flex;
      align-items: center;
    }

    td {
      div {
        font-size: 1.4rem;
      }
    }

    .td-image {
      display: flex;
      flex: 1;
      align-items: center;
      max-width: 16.5rem;
      margin-right: 2rem;
      .image-box {
        width: 3.5rem;
        height: 3.5rem;
        padding: 0;
        img {
          width: 100%;
          height: 4.5rem;
          object-fit: cover;
        }
      }
      span {
        font-family: var(--font-tertiary);
        font-size: 1.6rem;
        margin: 0 1.4rem;
        max-width: 10.2rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-transform: uppercase;
      }
    }
  }

  .bw {
    width: 3rem;
    margin: auto;
  }

  .scroll {
    max-height: 45rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .eye {
    cursor: pointer;
    fill: ${(props) => props.theme.colors.primary};
    &:hover {
      fill: ${(props) => props.theme.colors.backgrounds.two};
    }
  }

  .box {
    width: 5rem;
    line-height: 5rem;
    text-align: center;
    margin-right: 1.5rem;
  }
  .update {
    margin: 0 1rem;
    width: 7.5rem;
  }

  .activity {
    margin: 0 auto;
  }
`;
