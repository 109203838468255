import { DefaultTheme } from 'styled-components';

const lightTheme: DefaultTheme = {
  title: 'light',

  colors: {
    primary: '#323E48',
    secondary: '#FF5353',
    background: '#F6F6F6',
    backgroundTransparent: 'rgba(246, 246, 246, 0.85)',
    scrollbar: '#E0E0E0',
    shadow: 'rgba(0, 0, 0, 0.25)',
    backgrounds: {
      one: '#E01484',
      two: '#31A6DE',
      three: '#7CBB46',
      four: '#F7C916',
      five: '#EF7E1F',
      six: '#E5E5E5',
      seven: '#337ab7',
      eight: '#F7F7F7'
    }
  }
};

export default lightTheme;
