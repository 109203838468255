import React, { useRef, useEffect } from 'react';

import { Redirect } from 'react-router';

import { Alert, Button, Col, Form, Row } from 'react-bootstrap';

import useLogin from '../../hooks/useLogin';

import { Container } from './styles';
import { useAlert } from '../../hooks/useAlert';
import { Link } from 'react-router-dom';

import Footer from '../../components/Footer';

import loginBg from '../../assets/images/latest_images/login-bg.png';
import emailIco from '../../assets/images/latest_images/email-ico.svg';


const Forgot: React.FC = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const pwdRef = useRef<HTMLInputElement>(null);

  const [userResult, enter] = useLogin();
  const [variant, danger, , clear] = useAlert();

  const handleOnSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (userResult && userResult.err_msg) clear();

    if (emailRef.current && pwdRef.current)
      enter(emailRef.current?.value, pwdRef.current?.value);
  };

  useEffect(() => {
    if (userResult)
      danger(
        userResult?.err_msg || userResult?.err_message || 'Undefined Error'
      );
  }, [userResult]);

  if (userResult?.status === 'success') return <Redirect to='/libraries' />;

  if (localStorage.getItem('users')) {
    return <Redirect to='/libraries' />;
  }

  return (
    <>
      <div className="body__content__main user__account__wrapp">
         <div className="container">
            <div className="form__wrapp__outer">
               <div className="side__wrapp">
                  <div className="image__box">
                     <img src={loginBg} alt="Background-Image" />
                  </div>
               </div>
               <div className="form__wrapp">
                  <div className="form__wrapp__inner">
                    {variant.visible && (
                      <Alert className='mb-4' variant={variant.variant}>
                        {variant.message}
                      </Alert>
                    )}

                     <h1>Reset <span>Password</span></h1>
                     <p>
                       Enter Your Email Below, And We’ll Send You A Link To Reset Your Password.
                     </p>
                     <form onSubmit={handleOnSubmit}>
                        <div className="form-group form__group">
                           <label>Email Address:</label>
                           <div className="input-group input__group">
                              <input type="email" 
                                    className="form-control form__control" 
                                    placeholder="Email Address"
                                    required
                                    ref={emailRef} />

                              <i className="ico__box"><img src={emailIco} alt="Email Icon" /></i>
                           </div>
                        </div>
                       
                        <div className="form__footer">
                           <button type="submit" className="submit__btn">Reset</button>
                    
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <Footer />
    </>
  );
};

export default Forgot;
