import styled from 'styled-components';

import { Container as ContainerBootstrap } from 'react-bootstrap';

export const Container = styled(ContainerBootstrap)`
  margin-top: var(--default-margin-top);

  .wrapper {
    background-color: ${(props) => props.theme.colors.background};
    border-radius: var(--radius);

    height: 60rem;
    max-width: 802px;
    width: 100%;
    padding: 5rem 2rem;
  }

  .total {
    font-family: var(--font-secundary);
    display: flex;
    align-items: center;
    padding: 0.5rem;
    font-size: 1.2rem;

    &:before {
      content: '';
      display: inline-block;
      border-radius: 1rem;
      width: 2rem;
      height: 2rem;
      margin-right: 0.5rem;
    }

    &:nth-child(1):before {
      background-color: ${(props) => props.theme.colors.backgrounds.one};
    }

    &:nth-child(2):before {
      background-color: ${(props) => props.theme.colors.secondary};
    }

    &:nth-child(3):before {
      background-color: ${(props) => props.theme.colors.backgrounds.two};
    }

    &:nth-child(4):before {
      background-color: ${(props) => props.theme.colors.backgrounds.five};
    }

    &:nth-child(5):before {
      background-color: ${(props) => props.theme.colors.backgrounds.three};
    }

    &:nth-child(6):before {
      background-color: ${(props) => props.theme.colors.backgrounds.four};
    }
  }

  .image-box {
    display: flex;
    height: 11rem;
    width: 90%;
  }

  .recharts {
    &-wrapper {
      margin-left: 2rem;
    }

    &-surface {
      background-color: white;
    }
  }
  .custom-tooltip {
    background-color: ${(props) => props.theme.colors.background};
    border-radius: 0.5rem;
    padding: 1rem;

    span {
      font-weight: 500;
    }
  }
`;
