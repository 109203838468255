import React from 'react';
import { Alert, ModalProps } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import RcmModal from '../../../../components/Modal';

import { useAlert } from '../../../../hooks/useAlert';
import useContacts from '../../../../hooks/useContacts';

// import { Container } from './styles';

const Csv: React.FC<ModalProps> = (props:any) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [variant, danger, success, clear] = useAlert();
  const { uploadContacts } = useContacts();

  const importCsv = () => {
    const files = inputRef.current?.files;

    if (files?.length === 0) {
      danger('Please select a file');
      return;
    }
    const file = files?.item(0);
    if (file) {
      uploadContacts(file);
      props.onHide();
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clear();
  };

  return (
    <RcmModal
      title='Import CSV'
      onHide={props.onHide}
      message=''
      show={props.show}
      buttonLabel='Cancel'
      action={importCsv}
      buttonActionLabel='Save'
    >
      <h2>Select a csv file to import</h2>
      <h3>Please follow select below guidelines to format your csv file</h3>

      <p>
        Please
        <Link
          to='/files/sample.csv'
          className='ml-2 mr-2'
          target='_blank'
          download
        >
          Click here
        </Link>
        to download sample.csv file
      </p>
      {variant.visible && (
        <Alert className='mb-4' variant={variant.variant}>
          {variant.message}
        </Alert>
      )}
      <input
        type='file'
        ref={inputRef}
        accept='.csv'
        onChange={handleOnChange}
      />
    </RcmModal>
  );
};

export default Csv;
