import React from 'react';

import { Row, Col, Container as ContainerBoostrap } from 'react-bootstrap';

import { Container } from './styles';

import baenefitsImg from '../../../assets/images/latest_images/inner-bg-2.png';
// import bgImage from '../../../assets/images/latest_images/graphic-bg-4.svg';



const Tips: React.FC = () => {
  return (
    <div className="rcm__benefits">
      <div className="container">
        <div className="rcm__benefits__inner">
          <div className="content__wrapp">
            <h2>Discover the <span>benefits of RCM</span></h2>
            <ul>
              <li>Transform static marketing media into interactive experiences</li>
              <li>Access 4 different digital multimedia content in one RCM Frame, with one scan</li>
              <li>Attractive images and captivating content - No boring dots like old traditional QR codes</li>
              <li>Increase customer engagement and interaction with marketing collateral through the use of mobile technology</li>
              <li>Enhance marketing collateral with various content</li>
              <li>Advanced campaign analytics</li>
              <li>Quick and easy, to create and manage</li>
              <li>Simply and instantly update content as often as desired</li>
              <li>No reprinting or replacing marketing materials saves time and money</li>
            </ul>
          </div>
          <div className="image__box">
            <img src={baenefitsImg} alt="" />
          </div>
        </div>
      </div>
    </div>


   
  );
};

export default Tips;
