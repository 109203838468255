import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Title from '../../../components/Title';

import { Container } from './styles';

const RcmTitle: React.FC = () => {
  const MOBILE_SIZE = 768;

  return (


     <div className="bannre__content__inner__wrapp">
         <h1>Take Your <span>Marketing</span> to the</h1>
         <h2>Next Level With <span>Real Code Media</span><sup>TM</sup></h2>
         <p>With RCM, you can create dynamic multimedia frames to transform your digital marketing campaigns in just a few steps.</p>
         <div className="btn__wrapp">
            <Link to="sign-up">30 - Day Free Trial</Link>
         </div>
     </div>

  );
};
export default RcmTitle;
