import React from 'react';
import { getShow } from '../service/subscription';

import { SubscriptionOptions } from '../interfaces';

function useSubShow() {
  const [subOptions, setSubOptions] = React.useState<SubscriptionOptions[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await getShow();

      if (response.status === 'success') setSubOptions(response.result);
    };
    fetchData();
  }, []);

  return subOptions;
}

export default useSubShow;
