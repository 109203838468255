import React from 'react';
import { Table } from 'react-bootstrap';

import usePurchase from '../../../../hooks/usePurchase';

import { Container } from './styles';

const Purchase: React.FC = () => {
  const purchase = usePurchase();
  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th>DATE</th>
            <th>TYPE</th>
            <th>TRANSACTION</th>
            <th>DESCRIPTION</th>
            <th>AMOUNT</th>
            <th>TAX</th>
            <th>TOTAL</th>
          </tr>
        </thead>
        <tbody>
          {purchase.map((item, index) => (
            <tr key={index}>
              <td className='date'>{item.createdOn}</td>
              <td>{item.transaction}</td>
              <td>{item.description}</td>
              <td>{item.amount}</td>
              <td>{item.taxAmount}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Purchase;
