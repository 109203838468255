import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Login from './Login';
import Purchase from './Purchase';

import { Container } from './styles';

const Transactions: React.FC = () => {
  return (
    <Container>
      <Tabs defaultActiveKey='purchase'>
        <Tab eventKey='purchase' title='Purchase History'>
          <Purchase />
        </Tab>
        <Tab eventKey='login' title='Login History'>
          <Login />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default Transactions;
