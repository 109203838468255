import React, { useState, useEffect } from 'react';

import { useTheme } from 'styled-components';

import { useLocation } from 'react-router-dom';

import { getAll } from '../../service/analytics';

import { Link } from 'react-router-dom';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  BarChart,
  Bar,
  Tooltip,
  TooltipProps
} from 'recharts';

import { Button, Col, Row } from 'react-bootstrap';
import { Analytics, ChartData, Summary } from '../../interfaces';

import Menu from '../Menu';
import { Container } from './styles';
import Footer from '../../components/Footer';
import {
  NameType,
  ValueType
} from 'recharts/types/component/DefaultTooltipContent';

const Chart: React.FC = () => {
  const [data, setData] = useState<ChartData[]>([]);
  const [dataKey, setDataKey] = useState('dayIdStr');
  const [chartType, setChartType] = useState('Line chart');

  const location = useLocation<Summary>();
  const theme = useTheme();

  const [filter, setFilter] = useState<Analytics>({
    name: 'activity',
    dayFilter: false,
    weekFilter: false,
    monthFilter: true,
    quarterFilter: false,
    yearFilter: false,
    industryId: 0,
    frameId:
      location && location.state && location.state.frameId
        ? location.state.frameId
        : 0,
    countryId: 0
  });

  useEffect(() => {
    async function fetchData() {
      const result = await getAll(filter);
      if (result.status === 'success') {
        setData(result.result);
      }
    }

    fetchData();
  }, [filter]);

  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.currentTarget.value;
    const newFilter = { ...filter };

    newFilter.dayFilter = false;
    newFilter.weekFilter = false;
    newFilter.monthFilter = false;
    newFilter.quarterFilter = false;
    newFilter.yearFilter = false;

    if (value === 'Daily') {
      newFilter.dayFilter = true;
      setDataKey('dayIdStr');
    }

    if (value === 'Weekly') {
      newFilter.weekFilter = true;
      setDataKey('weekIdStr');
    }

    if (value === 'Monthly') {
      newFilter.monthFilter = true;
      setDataKey('monthIdStr');
    }

    if (value === 'Quarterly') {
      newFilter.quarterFilter = true;
      setDataKey('quarterIdStr');
    }

    if (value === 'Yearly') {
      newFilter.yearFilter = true;
      setDataKey('yearIdStr');
    }

    setFilter(newFilter);
  };

  const handleOnType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.currentTarget.value;
    setChartType(value);
  };

  function convertName(name: NameType) {
    if (name === 'scanCount') return 'Total Scans';
    if (name === 'bottomRightSwipeCount') return 'Bottom Right Swipes';
    if (name === 'bottomLeftSwipeCount') return 'Bottom Left Swipes';
    if (name === 'topRightSwipeCount') return 'Total Right Swipes';
    if (name === 'topLeftSwipeCount') return 'Total Left Swipes';
    if (name === 'swipeCount') return 'Total Swipes';
    if (name === 'totalViews') return 'Total Views';
    return '';
  }

  const CustomTooltip = ({
    active,
    payload,
    label
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          {payload.map((p) => {
            return (
              <div>
                <span style={{ color: p.color }} className='desc'>
                  {convertName(p.name || '')}
                </span>
                <span style={{ color: p.color }}>{`: ${p.value}`}</span>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <Container>
        <Row>
          <Menu />
          <div className='wrapper'>
            <Row className='mb-5'>
              <Col>
                <Button variant='dark' className='mr-3'>
                  ACTIVITY
                </Button>
                <Button variant='outline-dark' as={Link} to='/performance'>
                  ANALYTICS
                </Button>
              </Col>
            </Row>
            <Row className='mb-7'>
              <Col md={4}>
                <select onChange={handleOnChange}>
                  <option>Daily</option>
                  <option>Weekly</option>
                  <option selected>Monthly</option>
                  <option>Quarterly</option>
                  <option>Yearly</option>
                </select>
              </Col>
              <Col md={4}>
                <select onChange={handleOnType}>
                  <option>Line chart</option>
                  <option>Bar chart</option>
                </select>
              </Col>
            </Row>
            <Row>
              <Col md={9} className='p-0'>
                {data && data.length > 0 && chartType === 'Line chart' && (
                  <LineChart
                    width={580}
                    height={322}
                    data={data}
                    margin={{
                      top: 5,
                      right: 10,
                      left: 10,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey={dataKey} />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Line
                      type='monotone'
                      dataKey='viewCount as Total View'
                      stroke={theme.colors.primary}
                    />
                    <Line
                      type='monotone'
                      dataKey='scanCount'
                      stroke={theme.colors.backgrounds.one}
                    />
                    <Line
                      type='monotone'
                      dataKey='swipeCount'
                      stroke={theme.colors.secondary}
                    />
                    <Line
                      type='monotone'
                      dataKey='topLeftSwipeCount'
                      stroke={theme.colors.backgrounds.two}
                    />
                    <Line
                      type='monotone'
                      dataKey='topRightSwipeCount'
                      stroke={theme.colors.backgrounds.five}
                    />
                    <Line
                      type='monotone'
                      dataKey='bottomLeftSwipeCount'
                      stroke={theme.colors.backgrounds.three}
                    />
                    <Line
                      type='monotone'
                      dataKey='bottomRightSwipeCount'
                      stroke={theme.colors.backgrounds.four}
                    />
                  </LineChart>
                )}

                {data && data.length > 0 && chartType === 'Bar chart' && (
                  <BarChart width={580} height={322} data={data}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey={dataKey} />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey='scanCount' fill={theme.colors.primary} />
                    <Bar
                      dataKey='swipeCount'
                      barSize={20}
                      fill={theme.colors.backgrounds.one}
                    />
                    <Bar
                      barSize={20}
                      dataKey='topLeftSwipeCount'
                      fill={theme.colors.secondary}
                    />
                    <Bar
                      barSize={20}
                      dataKey='topRightSwipeCount'
                      fill={theme.colors.backgrounds.two}
                    />
                    <Bar
                      barSize={20}
                      dataKey='bottomLeftSwipeCount'
                      fill={theme.colors.backgrounds.five}
                    />
                    <Bar
                      barSize={20}
                      dataKey='bottomRightSwipeCount'
                      fill={theme.colors.backgrounds.three}
                    />
                  </BarChart>
                )}
              </Col>
              <Col md={3} className='p-0 pl-5'>
                <span className='total'>Total Scans</span>
                <span className='total'>Total Swipes</span>
                <span className='total'>Total Left Swipes</span>
                <span className='total'>Total Right Swipes</span>
                <span className='total'>Bottom Left Swipes</span>
                <span className='total'>Bottom Right Swipes</span>

                {location &&
                  location.state &&
                  location.state.updatedImageURL && (
                    <img
                      className='image-box'
                      alt='frame'
                      src={`../../../${location.state.updatedImageURL}`}
                    />
                  )}
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
      <Footer className='mt-5' />
    </>
  );
};

export default Chart;
