import { Form } from 'react-bootstrap';
import styled from 'styled-components';

export const Container = styled(Form)`
  margin-top: var(--default-margin-top);
  display: flex;
  align-items: flex-start;

  max-width: 1245px;
  margin: auto;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: var(--radius);
  padding: 3.5rem;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    .text-right {
      text-align: left !important;
      padding-right: 0 !important;
      padding-bottom: 1rem;
      padding-top: 1rem;
    }
  }

  .wrapper-profile {
    max-width: 19.9rem;
    @media (max-width: 768px) {
      max-width: 100vw;
    }

    .wrapper-image {
      height: 19.9rem;
      width: 19.9rem;
      margin: auto;
      background-color: ${(props) => props.theme.colors.scrollbar};
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  textarea {
    height: 100%;
    resize: none;
  }

  button {
    font-weight: 400;
  }

  .mb-5 {
    margin-bottom: 2.5rem !important;
  }

  .button-footer-group {
    @media (max-width: 425px) {
      flex-direction: column-reverse;

      .btn {
        margin-bottom: 1rem;
        margin-right: 0 !important;
      }
    }
  }
`;
