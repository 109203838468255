import React, { useEffect } from 'react';

import { Container } from './styles';
import useSubscription from '../../../hooks/useSub';
import useSubOptions from '../../../hooks/useSubOptions';
import { Button, Col, Row } from 'react-bootstrap';
import { SubscriptionOptions } from '../../../interfaces';

import CreditCard from './CreditCard';
import { Price, prices } from '../../Price/data';
import Card from './Card';

const Subscription: React.FC = () => {
  const subscription = useSubscription();
  const subShow = useSubOptions();

  const [showCreditCardModal, setShowCreditCardModal] = React.useState(false);
  const [price, setPrice] = React.useState<Price>();

  useEffect(() => {
    if (subscription && subShow) {
      const result = subShow.find(
        (sub) => sub.name === subscription.subscriptionLevel
      );

      if (result) {
        setPrice(prices.find((p) => p.name === result.name.toUpperCase()));
        const input = document.getElementById(
          `rb-${result.name.toLocaleLowerCase()}`
        ) as HTMLInputElement;
        console.log(input);
        if (input) input.setAttribute('checked', 'true');
      }
    }
  }, [subscription, subShow]);

  const handleOnBenefitsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    setPrice(prices.find((p) => p.name === value.toUpperCase()));
  };

  return (
    <Container>
      <CreditCard
        show={showCreditCardModal}
        onHide={() => setShowCreditCardModal(false)}
      />
      <Row className='mb-4 mt-4'>
        <Col>
          <b>
            <span className='mr-2'>My Subscription:</span>
            {subscription &&
              `${subscription?.subscriptionLevel}/${subscription?.subscriptionMode}`}
            {!subscription && 'Loading...'}git
          </b>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col className='benefits'>
          <span>Update to:</span>
          {subShow.map((item, index) => (
            <React.Fragment key={index}>
              <input
                type='radio'
                name='benefits'
                id={`rb-${item.name.toLowerCase()}`}
                value={item.name}
                onChange={handleOnBenefitsChange}
              />
              <label htmlFor={`rb-${item.name.toLowerCase()}`}>
                {item.name}
              </label>
            </React.Fragment>
          ))}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          {price && (
            <Card {...price} onClick={() => setShowCreditCardModal(true)} />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Subscription;
