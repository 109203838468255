import { Folder, Model, Result } from '../interfaces';
import { post, deleteApi } from './api';

export async function editCategory(category: Model) {
  const result = await post<Result>(category, `/frame/categories/save`);
  return result.data;
}

export async function createCategories(description: string) {
  const result = await post<Folder>({ description }, 'frame/categories/save');
  return result.data;
}

export async function deleteCategories(id: number) {
  const result = await deleteApi(id, 'frame/categories/remove');
  return result.data;
}
