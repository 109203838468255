import React, { useState, useRef, useEffect } from 'react';

import { Container } from './styles';

import { Frame as FrameInfo, Result } from '../../interfaces';
import { Overlay, Tooltip } from 'react-bootstrap';
import { saveFrameName } from '../../service/frame';

interface FrameInfoProps extends FrameInfo {
  onSelect(event: React.ChangeEvent<HTMLInputElement>): void;
}

const Frame: React.FC<FrameInfoProps> = (props) => {
  const { id, name } = props;
  let { updatedImageUrlMobile } = props;
  updatedImageUrlMobile = `../../..${updatedImageUrlMobile}`;

  const [edit, setEdit] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnDoubleClick = () => {
    setEdit(true);
  };

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = name;
      inputRef.current.focus();
    }
  }, [edit]);

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      setEdit(false);
    }
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setEdit(false);
  };

  const handleOnSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const newFrameName = { ...props };
    newFrameName.name = inputRef.current?.value || name;
    const result = await saveFrameName(newFrameName);
    if (result.data.status === 'success') {
      props.name = inputRef.current?.value || name;
      setEdit(false);
    }
  };

  const handleDragStart = (event: React.DragEvent<HTMLImageElement>) => {
    event.dataTransfer.setData('id', event.currentTarget.id);
  };

  return (
    <Container onDragStart={handleDragStart} id={`${id}`}>
      <div className='checkbox' title={name}>
        <img
          src={`${updatedImageUrlMobile}?${new Date().getTime()}`}
          alt='frame'
        ></img>
        <input type='checkbox' onChange={props.onSelect} id={`${id}`} />
      </div>
      {!edit && (
        <span className='font-tertiary' onDoubleClick={handleOnDoubleClick}>
          {name}
        </span>
      )}
      {edit && (
        <span>
          <form onSubmit={handleOnSubmit}>
            <input
              required
              minLength={5}
              ref={inputRef}
              onBlur={handleOnBlur}
              onKeyDown={handleOnKeyDown}
            ></input>
            <Overlay target={inputRef} show={edit} placement='bottom'>
              <Tooltip id={`id-${name}`}>
                Press ENTER to save or ESC to cancel
              </Tooltip>
            </Overlay>
          </form>
        </span>
      )}
    </Container>
  );
};

export default Frame;
