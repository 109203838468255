import React, { useState } from 'react';

import {
  Button,
  Modal,
  Row,
  Col,
  Container as ContainerBoostrap
} from 'react-bootstrap';

import { Container } from './styles';

import videoUrl from '../../../assets/video/home.mp4';
import thumb from '../../../assets/video/thumb.png';
import play from '../../../assets/video/play.svg';
import image from '../../../assets/images/latest_images/inner-bg.png'

const Video: React.FC = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (


    <div className="new__in__rcm">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="image__box">
              <img src={image} alt="" onClick={handleShow}/>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="content__wrapp">
              <h2>Are you new to</h2>
              <h2><span>REEL CODE MEDIA ™?</span></h2>
              <h3>Here are a few basics to get you started:</h3>
              <p>Reel Code Media™ is a new, interactive visual communications medium that takes digital advertising, marketing and promotions to the next dimension.</p>
              <p>RCM frames let you create powerful digital marketing solutions that engage customer-interaction. Just add the RCM frame around any image, and upload multimedia content to each corner of the frame.A few clicks and it's done!</p>
              <p>Use the RCM Lite App to scan your RCM framed image. Then one at a time, drag each corner of the RCM frame to the center, and a variety of interactive multimedia content will open up from within each of the 4 corners.</p>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>RCM</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className='justify-content-center'>
            <video controls>
              <source src={videoUrl}></source>
            </video>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='dark' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </div>



   
  );
};

export default Video;
