import React, { useState, useRef, useEffect } from 'react';

import { Link, useHistory } from 'react-router-dom';

import Title from '../../components/Title';
import StepUI from '../../components/Step';
import Preview from './Preview';

import useGenerator, { defaultStep } from '../../hooks/useGenerator';
import { useAlert } from '../../hooks/useAlert';

import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';

import { Container } from './styles';

import ContentType from './ContentType';
import CustomIcons from './CustomIcons';

import { ReelGenerator } from '../../interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownload } from '@fortawesome/free-solid-svg-icons';

import layout1 from '../../assets/icons/layout1.svg';
import layout2 from '../../assets/icons/layout2.svg';
import layout3 from '../../assets/icons/layout3.svg';

import RcmTitle from './Title';
import Benefits from '../Benefits';
import Scan from '../Scan';
import TipsDesktop from '../Tips/Desktop';
import TipsMobile from '../Tips/Mobile';
import Try from '../Try';
import Faq from '../Faq';
import Price from '../Price';
import Footer from '../../components/Footer';
import VideoDesktop from '../Video/Desktop';
import VideoMobile from '../Video/Mobile';
import Mobile from './Mobile';
import videoHomeUrl from '../../assets/video/homePlay.mp4'
import { log } from 'console';

const MOBILE_SIZE = 768;

const isMobile = () => {
  return window.innerWidth <= MOBILE_SIZE;
};

const Generator: React.FC = () => {
  const { stepsNew, preview } = useGenerator();
  const history = useHistory();
  const [variant, danger, , clear] = useAlert();

  const inputRef = useRef<HTMLInputElement>(null);

  const imageRef1 = useRef<HTMLImageElement>(null);
  const imageRef2 = useRef<HTMLImageElement>(null);
  const imageRef3 = useRef<HTMLImageElement>(null);

  const Step1 = () => {
    return (
      <StepUI {...step1}>
        <input
          ref={inputRef}
          style={{ display: 'none' }}
          type='file'
          accept='image/png, image/jpeg, video/mp4,video/x-m4v,video/*'
          onChange={handleOnChangePhoto}
        />
      </StepUI>
    );
  };

  const PreviewUI = () => {
    return (
      <Preview {...reelGenerator}>
        <div className="btn__wrapp mt-5 pb-4">
          {currentStep > 0 && currentStep <= 4 && (
            <>
              <Col sm={12} md={4} >
                {currentStep > 1 && (
                  <a
                    className='back__btn'
                    onClick={handleOnBack}
                  >
                    Back
                  </a>
                )}
              </Col>
              <Col sm={12} md={4}>
                <a
                  className='font-secundary large next'
                  onClick={handleOnNext}
                >
                  Next
                </a>
              </Col>
            </>
          )}

          {currentStep > 4 && (
            <>
              <Col md={4} className='p-0'>
                <a
                  className='back__btn'
                  onClick={handleOnBack}
                >
                  Back
                </a>
              </Col>
              <Col sm={12} md={4} className='p-0'>
                <a
                  className='font-secundary large '
                  onClick={register}
                >
                  Save
                </a>
              </Col>
              <Col sm={12} md={2} className='p-0'>
                <a
                  title='Download'
                  className='font-secundary btn-dark-2 '
                  onClick={register}
                >
                  <FontAwesomeIcon icon={faCloudDownload} />
                </a>
              </Col>
            </>
          )}
        </div>
      </Preview>
    );
  };

  const [reelGenerator, setStep] = useState<ReelGenerator>(defaultStep);

  useEffect(() => {
    const newStep = { ...reelGenerator };
    newStep.steps = stepsNew;
    setStep(newStep);

    // console.log('newStep', defaultStep)

    return function cleanup() {
      reelGenerator.image.path = '';
      reelGenerator.currentStep = 1;
      reelGenerator.contentType.fields.forEach((e) => {
        e.value = '';
        e.iconId = 0;
        e.contentTypeId = 0;
      });

      reelGenerator.icons.corners.forEach((e) => {
        e.icon = '';
        e.iconId = 0;
        e.contentTypeId = 1;
      });

      reelGenerator.steps.forEach((e) => {
        e.currentId = 1;
      });
    };
  }, []);

  const [step1, step2, step3, step4] = reelGenerator.steps;
  const { title } = reelGenerator;

  if (step1) step1.onClick = () => inputRef.current?.click();

  reelGenerator.contentType.apply = (event: React.FormEvent<Element>) => {
    event.preventDefault();

    if (
      reelGenerator.contentType.fields.length !== 4 ||
      reelGenerator.contentType.fields.filter((f) => !f.value).length !== 0
    ) {
      danger('Please fill all fields');

      return;
    }

    reelGenerator.contentType.show = false;
    localStorage.setItem('frame', JSON.stringify(reelGenerator));
    handleOnNext();
  };

  reelGenerator.contentType.close = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const newState = { ...reelGenerator };
    newState.contentType.show = false;
    document.body.style.overflow = 'auto';

    const element = document.querySelector('header');
    element?.scrollIntoView();

    setStep(newState);
  };

  reelGenerator.icons.apply = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    if (reelGenerator.icons.corners.filter((c) => !c.icon).length > 0) {
      danger('Please fill all corners');
      return false;
    }

    reelGenerator.icons.show = false;
    handleOnNext();
  };

  reelGenerator.icons.close = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const newState = { ...reelGenerator };
    newState.icons.show = false;
    setStep(newState);
  };

  const handleOnChangePhoto = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.currentTarget.files;

    if (file) {
      if (file[0].size > 10485760 && file[0].type.includes('image')) {
        danger(
          'Error: Image file is too large. The maximum file size for image upload is 10MB.'
        );
        return;
      }

      if (file[0].size > 157286400 && file[0].type.includes('video')) {
        danger(
          'Error: Video file is too large. The maximum file size for video upload is 150MB.'
        );
        return;
      }

      const newStep = { ...reelGenerator };
      newStep.image.path = URL.createObjectURL(file[0]);
      newStep.image.file = file[0];
      newStep.image.name = file[0].name;
      newStep.image.type = file[0].type;

      newStep.layout = 'layout3';
      imageRef3.current?.classList.add('selected');
      newStep.currentStep = 2;

      handleOnNext();
    }
  };

  const handleOnNext = () => {
    if (!reelGenerator.image.file) {
      danger('Please select an image first');
      return;
    }

    if (reelGenerator.currentStep === 2 && !reelGenerator.layout) {
      danger('Please select one layout');
      return;
    }

    if (reelGenerator.contentType.show || reelGenerator.icons.show) {
      return;
    }

    const newStep = { ...reelGenerator };

    if (
      currentStep === 3 &&
      reelGenerator.icons.corners.filter((c) => !c.icon).length > 0
    ) {
      newStep.icons.show = true;
      setStep(newStep);
      return;
    }

    if (
      currentStep === 4 &&
      (reelGenerator.contentType.fields.length === 0 ||
        reelGenerator.contentType.fields.filter((f) => !f.value).length > 0)
    ) {
      newStep.contentType.show = true;
      setStep(newStep);
      return;
    }

    if (variant.message) clear();

    if (currentStep < 5) newStep.currentStep++;
    newStep.steps.forEach((s) => (s.currentId = newStep.currentStep));

    setStep(newStep);
  };

  const handleOnBack = () => {
    if (reelGenerator.icons.show) {
      return;
    }

    if (reelGenerator.contentType.show) {
      return;
    }

    const newStep = { ...reelGenerator };
    newStep.currentStep--;
    newStep.steps.forEach((s) => (s.currentId = newStep.currentStep));

    if (newStep.currentStep === 1) {
      newStep.image.path = '';
      newStep.image.file = undefined;
      newStep.image.name = '';
      newStep.image.type = '';
    }
    setStep(newStep);
  };

  const showIcons = () => {
    if (currentStep === 3 && !reelGenerator.icons.show) {
      const newStep = { ...reelGenerator };
      newStep.icons.show = true;
      setStep(newStep);
    }
  };

  const showContent = () => {
    if (currentStep === 4 && !reelGenerator.contentType.show) {
      const newStep = { ...reelGenerator };
      newStep.contentType.show = true;
      setStep(newStep);
      return;
    }
  };

  const { currentStep } = reelGenerator;

  const handleOnLayoutClick = (event: React.MouseEvent<HTMLElement>) => {
    if (currentStep !== 2) return;

    const newStep = { ...reelGenerator };
    newStep.layout = event.currentTarget.id;

    imageRef1.current?.classList.remove('selected');
    imageRef2.current?.classList.remove('selected');
    imageRef3.current?.classList.remove('selected');

    if (newStep.layout === 'layout1') {
      imageRef1.current?.classList.add('selected');
    }

    if (newStep.layout === 'layout2') {
      imageRef2.current?.classList.add('selected');
    }

    if (newStep.layout === 'layout3') {
      imageRef3.current?.classList.add('selected');
    }

    setStep(newStep);
  };

  const register = () => {
    if (
      reelGenerator.contentType.fields.length === 0 &&
      reelGenerator.contentType.fields.filter((f) => !f.value).length > 0
    )
      return;
    else history.push('/register');
  };

  const [show, setShow] = useState(false)
  useEffect(() => {
    setShow(true)
  }, [])
  // const handleShow = () =>{
  //    setShow(true)
  //    console.log('bb')
     
  // }
  const handleClose = () =>{
    setShow(false)
    
  }


  return (
    <>

      {variant.visible && (
        <Alert className='mb-4' variant={variant.variant}>
          {variant.message}
        </Alert>
      )}

      {/*{isMobile() && (
          <div className='mt-13'>
            <RcmTitle />
            <Title> {title} </Title>
            <Mobile {...reelGenerator} layout='' />
          </div>
        )}*/}


      <>
        <div className="main__banner">
          <div className="bannre__content">
            <div className="container">
              <RcmTitle />
              <div className="banner__media__wrapp">
                <div className="content__list__wrapp">
                  <h3 className='text-center'> {title}</h3>
                  {/* <ul>
                        <li>UPLOAD AN IMAGE<i className="ico__box"><img src="/assets/images/list-co-1.svg" alt="" /></i></li>
                        <li>FRAME LAYOUT<i className="ico__box"><img src="/assets/images/list-co-2.svg" alt="" /></i></li>
                        <li>CHOOSE THE ICON FOR EACH CORNER<i className="ico__box"><img src="/assets/images/list-co-2.svg" alt="" /></i></li>
                        <li>CUSTOMIZE DATA FOR EACH ICON<i className="ico__box"><img src="/assets/images/list-co-2.svg" alt="" /></i></li>
                      </ul> */}

                  <ul>
                    <li><StepUI {...step1}>
                      UPLOAD AN IMAGE
                      <input
                        ref={inputRef}
                        style={{ display: 'none' }}
                        type='file'
                        accept='image/png, image/jpeg, video/mp4,video/x-m4v,video/*'
                        onChange={handleOnChangePhoto}
                      />
                    </StepUI>
                      <i className="ico__box"><img src="/images/assets/list-co-1.svg" alt="" /></i>
                    </li>
                    <li>
                      <StepUI {...step2}>
                        FRAME LAYOUT
                        <div className='children'>
                          <div className="icon__box">
                            <img
                              id='layout1'
                              ref={imageRef1}
                              src={layout1}
                              alt='layout 1'
                              onClick={handleOnLayoutClick}
                            ></img>
                          </div>
                          <div className="icon__box">
                            <img
                              id='layout2'
                              ref={imageRef2}
                              src={layout2}
                              alt='layout 2'
                              onClick={handleOnLayoutClick}
                            ></img>
                          </div>
                          <div className="icon__box">
                            <img
                              id='layout3'
                              ref={imageRef3}
                              src={layout3}
                              alt='layout 3'
                              onClick={handleOnLayoutClick}
                            ></img>
                          </div>
                        </div>
                      </StepUI>
                      <i className="ico__box"><img src="/images/assets/list-co-2.svg" alt="" /></i>
                    </li>
                    <li>
                      <StepUI {...step3} onClick={showIcons}>
                        CHOOSE THE ICON FOR EACH CORNER
                      </StepUI>
                      <i className="ico__box"><img src="/images/assets/list-co-2.svg" alt="" /></i>
                    </li>
                    <li>

                      <StepUI {...step4} onClick={showContent}>
                        CUSTOMIZE DATA FOR EACH ICON
                        <i className="ico__box"><img src="/images/assets/list-co-2.svg" alt="" /></i>
                      </StepUI>

                    </li>
                  </ul>

                </div>
                <CustomIcons {...reelGenerator.icons} />
                <ContentType {...reelGenerator} />

                <div className="image__box">                  
                  <h3>{preview}</h3>
                  {/*<img src="assets/images/banner-media.png" alt="" />*/}

                  <PreviewUI />

                </div>
              </div>



            </div>
          </div>
        </div>
      </>



      <div className="body__content__main">
        <VideoDesktop />
        {/*{isMobile() && <VideoMobile />}*/}
        <Benefits />
        <Scan />
        <TipsDesktop />
        {/*{isMobile() && <TipsMobile />}*/}
        <Try />
        <Price />
        <Faq />
      </div>

      <Footer />

      <Modal show={show} size='lg'>
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>RCM</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className='justify-content-center'>
            <video controls autoPlay>
              <source src={videoHomeUrl}></source>
            </video>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='dark' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Generator;
