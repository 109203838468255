import styled from 'styled-components';

import { Container as ContainerBootstrap } from 'react-bootstrap';
export const Container = styled(ContainerBootstrap)`
  opacity: 95%;
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: var(--radius);

  position: absolute;
  padding: 1rem;
  z-index: 9999;

  width: 98%;
  top: 6rem;
  left: -1rem;
  height: 100%;
  margin-top: calc(var(--padding-large) * -1);
  transition: 1s ease-in-out;

  &.hide {
    opacity: 0;
    z-index: -1;
  }

  .title {
    padding-left: 3rem;
    color: ${(props) => props.theme.colors.background};
  }

  .table {
    width: 95%;
  }

  .wrapper {
    padding-left: 1rem;
    background-color: inherit !important;
  }

  .file-input {
    border: 1px solid ${(props) => props.theme.colors.primary};
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    background-color: ${(props) => props.theme.colors.background};
    span {
      &:hover {
        color: ${(props) => props.theme.colors.backgrounds.one};
        cursor: pointer;
      }
    }
    label {
      max-width: 10rem;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      white-space: nowrap;
      font-size: 1.4rem;
    }
  }

  table {
    border-radius: 1rem;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid ${(props) => props.theme.colors.backgrounds.one};
    margin-right: 1.5rem;
    thead {
      border-style: hidden;
      th {
        &:first-child {
          border-top-left-radius: 1rem;
        }
        &:last-child {
          border-top-right-radius: 1rem;
        }
        background-color: ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.background};
        padding-top: 1.4rem;
        text-align: center;
        font-size: 1.8rem;
      }
    }
    tbody {
      tr {
        border-right: 0.1rem;
        &:first-of-type td {
          border-top: 0;
        }
      }
      td {
        vertical-align: middle;
        text-align: center;
        height: 8rem;
        padding: 1.5rem;
        border-top: 0.1rem solid
          ${(props) => props.theme.colors.backgrounds.one};

        img {
          width: 40%;
          filter: invert(99%) sepia(18%) saturate(123%) hue-rotate(326deg)
            brightness(109%) contrast(93%);
        }

        &:last-of-type {
          border-right: none;
          border-left: 0.1rem solid
            ${(props) => props.theme.colors.backgrounds.one};
        }
      }
    }
  }

  .button {
    &-close {
      svg {
        background-color: ${(props) => props.theme.colors.secondary};
      }
      overflow: hidden;
      button {
        float: right;
        border: none;
        clear: right;
      }
    }
    &-apply {
      float: right;
      margin-right: 1.5rem;
    }
  }

  .form-control {
    border: 1px solid ${(props) => props.theme.colors.primary};

    border-radius: 0.4rem;
    background-color: ${(props) => props.theme.colors.background};
  }

  select {
    margin-bottom: 1rem;
    background-color: ${(props) => props.theme.colors.background};
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAUCAYAAABiS3YzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHISURBVHgBpZW/TsMwEMa/cwtizNoFUgn2FDYkUJio2HgC4AkKT1CxMcIbwMYGLNCtASTEBHkAhsLCGiagf3z4UiUtrdMW+klJbMf38+XsuxBGyPN9B+05TxF7IHLAHGmoBnKfQRgEUZYd2WFlV2mumOaumeJYLRmnuoXD8LHWGHyVGxxYWd+sEOPcwHxzzSHbHY9y2C8sLOL99eU2E7q8Xq6a2UcjYcPyC/OL7vvby9UQdHmtvGtWP8Z/ZLwuzC99GPBjt4skhqibposppJsoSoyVdFQH/rTAmDPL+/FTbgzs2CYxOPrLuMHtpFCi2NNB0xNW30UDCH8D0eAmlUxjz2LjlFa3FpTEE1mrNmdcA95IwF0gNiBng1C1WbHSRYVMsaPy+XoCBtNlAhy3qSoMJCOyYtQDP93fbE8CRL4TxUeqtFau2+OaiCKJMbob6mbNkvA839WKye6fYaRY8r+KMcfOpHcgzxga3tdOZRVMIbGXApNC48G22sYUYu5VrBQaPlyH2nr2JtKhfG3SGaqn3uqWR3l9QROkrWQWMR089QGt0BRuqhYRVwjkWWGgE62+jm1/gExoCpeMaxuvSTzXEVoqtFX7fv0AnZG7QkbhkQ0AAAAASUVORK5CYII=');
  }

  select,
  input {
    font-size: 1.2rem;
    text-transform: uppercase;
    height: 3rem;

    padding: 0.5rem;
    .form-control {
      padding: 0.5rem;
    }
  }

  h1 {
    margin-top: 0;
  }

  .drop {
    display: none;
    position: relative;
    cursor: pointer;
    transition: margin 700ms;
    &.show {
      display: block;
    }
    height: 5rem;
    &-image {
    }
    &-message {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
      font-family: var(--font-secundary);
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: ${(props) => props.theme.colors.backgrounds.eight};
      width: 8rem;

      span {
        padding-bottom: 1rem;
        display: block;
      }

      b {
        font-weight: var(--bold);
      }
    }
  }
  input[type='file']::before {
    content: 'Select a file';
    display: inline-block;

    -webkit-user-select: none;
    cursor: pointer;
    font-size: 1.2rem;
    padding: 0.5rem;
  }
`;
