import styled from 'styled-components';

import { Container as ContainerBootstrap } from 'react-bootstrap';

export const Container = styled(ContainerBootstrap)`
  background-color: #e5e5e566;
  border-radius: calc(var(--radius) * 2);
  box-shadow: 0px 4px 4px ${(props) => props.theme.colors.shadow};

  padding-top: 5.5rem;
  @media (max-width: 769px) {
    padding-top: 1rem;
  }

  h1 {
    display: none;
  }

  .wrapper {
    position: relative;

    height: 38.2rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      mix-blend-mode: multiply;

      @media (max-width: 769px) {
        margin: auto;
      }
    }

    @media (max-width: 769px) {
      img + img {
        object-fit: contain;
      }
    }

    .corner {
      &-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-icon {
        filter: invert(100%) sepia(0%) saturate(7471%) hue-rotate(50deg)
          brightness(131%) contrast(100%);
        width: 5rem;
        height: 5rem;
        mix-blend-mode: difference;
        object-fit: unset;
      }
    }
  }

  .navigation {
    display: flex;

    @media (max-width: 769px) {
      display: none;
    }

    justify-content: space-between;

    text-align: center;

    padding: 4.8rem 0;

    .back::before {
      content: '<';
    }
    .next::after {
      content: '>';
    }
  }

  .bottom-icons {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    @media (max-width: 769px) {
      width: 100%;
      left: 0;
    }
    display: flex;
    justify-content: space-between;
    z-index: 999;

    transition: 1s ease-in-out;
    svg {
      width: 8.5rem;
      height: 8.5rem;
      &:first-of-type {
        left: -5rem;
        bottom: -91px;
      }
      &:last-of-type {
        right: 0rem;
        bottom: -91px;
      }
    }
  }

  .top-icons {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    @media (max-width: 769px) {
      width: 100%;
      left: 0;
    }
    display: flex;
    justify-content: space-between;
    transition: 1s ease-in-out;
    z-index: 999;

    svg {
      width: 8.5rem;
      height: 8.5rem;
      &:first-of-type {
        left: -5rem;
        bottom: -91px;
      }
      &:last-of-type {
        right: 0rem;
        bottom: -91px;
      }
    }
  }

  .hide {
    opacity: 0;
  }

  .pointer {
    transition: 0.5s ease-in-out;
  }

  .focus {
    fill: ${(props) => props.theme.colors.scrollbar};
    opacity: 1;
  }

  img:hover {
    filter: saturate(150%);
  }
`;
