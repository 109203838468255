import React from 'react';

import { useHistory } from 'react-router-dom';

import { Link } from 'react-router-dom';

import { Container } from './styles';

import { Col, Row, Container as ContainerBootstrap } from 'react-bootstrap';

import youtube from '../../assets/images/latest_images/you-tube-ico.svg'
import facebook from '../../assets/images/latest_images/facebbok-ico.svg'
import instagram from '../../assets/images/latest_images/instagram-ico.svg'
import twitter from '../../assets/images/latest_images/twitter-ico.svg'
import footerLogo from '../../assets/images/latest_images/footer-logo.png'

interface FooterProps {
  className?: string;
}

const Footer: React.FC<FooterProps> = (props) => {
  const history = useHistory();

  const handleOnClick = () => {
    const element = document.getElementById('basics');
    if (element != null)
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    else history.push('/faq');
  };

  return (

    <footer>
      <div className="container">
        <div className="footer__nav">
          <div className="footer__brand">
            <img src={footerLogo} alt="Footer Logo" />
          </div>
          <div className="footer__nav__wrapp">
            <ul>
              <li><Link to="terms-and-conditions">Terms & Conditions</Link></li>
              <li><Link to="privacy-policy">Privacy Policy</Link></li>
              <li><Link to="contact-us">Contact Us</Link></li>
              <li><a onClick={handleOnClick} style={{ cursor: 'pointer' }}>FAQ</a></li>
            </ul>
          </div>
          <div className="footer__socila">
            <ul>
              <li><a href="https://youtube.com/" target="_blank"><img src={ youtube } alt="" /></a></li>
              <li><a href="https://facebook.com/" target="_blank"><img src={facebook} alt="" /></a></li>
              <li><a href="https://www.instagram.com/" target="_blank"><img src={instagram} alt="" /></a></li>
              <li><a href="https://twitter.com/" target="_blank"><img src={twitter} alt="twitter" /></a></li>
            </ul>
          </div>
        </div>
        <div className="footer__bottom">
          <p>
            A product and service distributed by Reel Code Media Inc, Copyright &copy;{(new Date().getFullYear())}.
            All rights reserved by Reel Code Media Inc or its licensors.
          </p>
        </div>
      </div>
    </footer>


  );
};

export default Footer;
