import React from 'react';
import { ContactsGroup } from '../interfaces';
import { getGroups, addContactGroup } from '../service/contacts';

function useContactsGroup() {
  const [contactsGroup, setContactsGroup] = React.useState<ContactsGroup[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await getGroups();

      if (response.status === 'success') setContactsGroup(response.result);
    };
    fetchData();
  }, []);

  function addGroup(contact: ContactsGroup) {
    addContactGroup(contact.name).then((resp) => {
      setContactsGroup([...contactsGroup, resp]);
    });
  }

  return [contactsGroup, addGroup] as const;
}

export default useContactsGroup;
