import React, { useEffect, useRef } from 'react';

import { Button, Col, Form, Row, Table } from 'react-bootstrap';

import { confirm } from 'react-bootstrap-confirmation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSave,
  faTrash,
  faFileCsv,
  faEdit
} from '@fortawesome/free-solid-svg-icons';

import useContacts from '../../../hooks/useContacts';

import { Container } from './styles';
import useContactsGroup from '../../../hooks/useContactsGroup';
import Group from './Group';
import { useAlert } from '../../../hooks/useAlert';
import RcmModal from '../../../components/Modal';
import Csv from './Csv';

const Contact: React.FC = () => {
  const [showGroup, setShowGroup] = React.useState(false);
  const [showCsvModal, setShowCsvModal] = React.useState(false);
  const [variant, danger, success, clear] = useAlert();
  const [selected, setSelected] = React.useState<number>();
  const { contacts, addContact, deleteContact, filterContacts, fetchData } =
    useContacts();
  const [contactsGroup, addContactGroup] = useContactsGroup();
  const [groupValue, setGroupValue] = React.useState(-1);

  const refInput = useRef<HTMLInputElement>(null);
  const refEmail = useRef<HTMLInputElement>(null);
  const refGroup = useRef<HTMLSelectElement>(null);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setGroupValue(Number(value));
  };

  const handleOnHideModalGroup = () => {
    setShowGroup(false);
  };

  const handleOnHideModalCsv = () => {
    setShowCsvModal(false);
  };

  const clearForm = () => {
    setSelected(0);
    if (refInput.current) refInput.current.value = '';
    if (refEmail.current) refEmail.current.value = '';
    if (refGroup.current) refGroup.current.value = '-1';
  };

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (refInput.current && refEmail.current && refGroup.current) {
      addContact({
        id: selected || null,
        name: refInput.current.value,
        groupId: Number(refGroup.current.value),
        email: refEmail.current.value,
        itemId: 1
      }).then((resp) => {
        if (resp === 'success') clearForm();
        success('Contact saved successfully!');
      });
    }
    e.preventDefault();
  };

  const findGroupName = (id: number) => {
    const group = contactsGroup.find((item) => item.id === id);
    return group ? group.name : '';
  };

  const handleOnCbChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value, checked } = e.target;

    const inputs = document.querySelectorAll<HTMLInputElement>(
      'input[type="checkbox"]'
    );
    inputs.forEach((input) => {
      input.id !== id && (input.checked = false);
    });

    if (checked) {
      clearForm();
      setSelected(Number(value));
    }
  };

  const handleOnDelete = async () => {
    if (!selected) {
      danger('Please select a contact!');
      return;
    }

    const result = await confirm('Are you sure you want to delete?', {
      title: `Delete the contact ?`,
      okText: 'Yes',
      cancelText: 'No',
      okButtonStyle: 'danger'
    });

    if (result) {
      deleteContact(selected).then((resp) => {
        if (resp === 'success') success('Contact deleted successfully!');
        else danger('Error deleting contact!');
      });
    }
  };

  const handleOnEdit = () => {
    if (selected) {
      const contact = contacts.find((item) => item.id === selected);
      if (contact && refInput.current && refEmail.current && refGroup.current) {
        refInput.current.value = contact.name;
        refEmail.current.value = contact.email;
        refGroup.current.value = String(contact.groupId);
      }
    } else danger('Please select a contact first!');
  };

  const handleOnCsvClick = () => {
    setShowCsvModal(true);
  };

  useEffect(() => {
    if (groupValue === 0) setShowGroup(true);
    else filterContacts(groupValue);
  }, [groupValue]);

  useEffect(() => {
    if (!showCsvModal) fetchData();
  }, [showCsvModal]);

  return (
    <Container>
      {variant.visible && (
        <RcmModal
          show={variant.visible}
          onHide={clear}
          title='Message'
          size='lg'
          message={variant.message || 'Error'}
        />
      )}
      <Group show={showGroup} onHide={handleOnHideModalGroup} />
      <Csv show={showCsvModal} onHide={handleOnHideModalCsv} />
      <Form onSubmit={handleOnSubmit}>
        <Row>
          <Col>
            <Form.Group controlId='formName'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Name'
                required
                ref={refInput}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='formEmail'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter email'
                required
                ref={refEmail}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='formGroup'>
              <Form.Label>Group</Form.Label>
              <Form.Control
                as='select'
                defaultValue='Choose ...'
                required
                onChange={handleOnChange}
                ref={refGroup}
              >
                <option hidden>Choose...</option>
                <option value='0'>Add new group...</option>
                {contactsGroup.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col
            sm={2}
            md={2}
            className='d-flex align-items-center actions-group'
          >
            <Form.Group controlId='formButton' className='actions mb-0 mt-4 '>
              <Button type='submit' className='pt-2 pb-2'>
                <span className='mr-2'>Save</span>
                <FontAwesomeIcon icon={faSave} />
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Group</th>
              </tr>
            </thead>
            <div className='body-container'>
              <tbody>
                {contacts.map(({ id, name, email, groupId }) => (
                  <tr key={id}>
                    <td>
                      <input
                        type='checkbox'
                        id={`cb-${id}`}
                        value={String(id)}
                        onChange={handleOnCbChange}
                      />
                    </td>
                    <td>{name}</td>
                    <td>{email}</td>
                    <td>{findGroupName(groupId)}</td>
                  </tr>
                ))}
              </tbody>
            </div>
          </Table>
        </Col>
      </Row>
      <Row className='footer  '>
        <Col>
          <Button onClick={handleOnEdit}>
            <FontAwesomeIcon icon={faEdit} />
            <span>Edit</span>
          </Button>

          <Button variant='danger' onClick={handleOnDelete}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>

          <Button onClick={handleOnCsvClick}>
            <FontAwesomeIcon icon={faFileCsv} className='mr-2' />
            <span>CSV File</span>
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
