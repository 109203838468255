import React, { useEffect } from 'react';

import { Row, Col, Container as ContainerBoostrap } from 'react-bootstrap';

import { Container } from './styles';

// import benefits from '../../assets/images/about/about-1.png';
// import benefits2 from '../../assets/images/about/about-2.png';
// import benefits3 from '../../assets/images/about/about-3.png';
// import arrow from '../../assets/icons/bold/arrow-circle.svg';

import Footer from '../../components/Footer';

import videoUrl from '../../assets/video/home.mp4';
import capabilities from '../../assets/images/latest_images/inner-bg-6.png';
import frameRCM from '../../assets/images/latest_images/frm-img.png';
import bgExp1 from '../../assets/images/latest_images/inner-bg-7.png';
import bgExp2 from '../../assets/images/latest_images/inner-bg-8.png';
import google from '../../assets/images/latest_images/google-play-badge.svg';
import apple from '../../assets/images/latest_images/app-store-badge.svg';
import creativityBanner from '../../assets/images/latest_images/frm-img-1.png';
import featureBanner from '../../assets/images/latest_images/inner-bg-9.png';

const Scan: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <div className="inner__page__banner">
         <div className="container">
            <div className="heading__wrapp">
               <h1>Get to know <span>Reel Code Media™</span></h1>
            </div>
            <div className="image__box__outer">
               <div className="image__box">
                  {/*<img src="assets/images/inner-bg-5.png" alt="" />*/}
                  <video controls>
                    <source src={videoUrl}></source>
                  </video>
               </div>
            </div>
         </div>
      </div>
      <div className="body__content__main">
         <div className="capabilities__areas">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-lg-6 col-md-6">
                     <div className="image__box">
                        <img src={capabilities} alt="Capabilities" />
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                     <div className="content__wrapp">
                        <h2><span>RCM Capabilities</span> areas as your <span>imagination!</span></h2>
                        <p>Reel Code Media™ is a new, interactive visual communications medium that takes digital advertising, marketing and promotions to the next dimension.</p>
                        <p>RCM frames let you create powerful digital marketing solutions that engage customer interaction. Just add the RCM frame around any image, and upload multimedia content to each corner of the frame. A few clicks and it's done!</p>
                        <p>Use the <a href="#">RCM Lite App</a> to scan your RCM framed image. Then one at a time, drag each corner of the RCM frame to the center, and a variety of interactive multimedia content will open up from within each of the 4 corners.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className="capabilities__wrapp">
            <div className="container">
               <div className="row">
                  <div className="col-lg-9 col-md-9">
                     <div className="content__wrapp">
                        <h2>Unleash the power of RCM</h2>
                        <p>Similar to the QR code, but much more powerful. RCM Frames store website links, videos, advertisements, business cards, brochures, catalogs, menus, addresses, directions on the map, social media accounts, e-commerce, special offers, payments and so much more.</p>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                     <div className="image__box">
                        <img src={frameRCM} alt="Frame Image" />
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className="rcm__experience">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-lg-5 col-md-5">
                     <div className="content__wrapp">
                        <h2><span>RCM User experience</span> start using your RCM lite today</h2>
                        <ul>
                           <li>Get the RCM Lite App from Apple Store or Google Play Store</li>
                           <li>Scan a RCM Frame using the App</li>
                           <li>Slide any of the 4 corners to the center of the RCM Frame</li>
                           <li>Experience multimedia content from each corner</li>
                        </ul>
                        <div className="btn__wrapp">
                          <a href='https://apps.apple.com/us/app/rcm-lite/id1530281250/'
                              target='_blank'
                              rel='noreferrer'>
                            <img src={apple} alt="Apple Store Logo" />
                          </a>
                          <a  href='https://play.google.com/store/apps/details?id=com.dw.rcmlite'
                              target='_blank'
                              rel='noreferrer'>
                            <img src={google} alt="Google Play Logo" />
                          </a>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-7 col-md-7">
                     <div className="image__box">
                        <img src={bgExp1} alt="Experience Image-1" />
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className="rcm__busniess">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-lg-7 col-md-7">
                     <div className="image__box">
                        <img src={bgExp2} alt="Experience Image-2" />
                     </div>
                  </div>
                  <div className="col-lg-5 col-md-5">
                     <div className="content__wrapp">
                        <h2><span>RCM User experience</span> start using your RCM lite today</h2>
                        <ul>
                           <li>Get the RCM Lite App from Apple Store or Google Play Store</li>
                           <li>Scan a RCM Frame using the App</li>
                           <li>Slide any of the 4 corners to the center of the RCM Frame</li>
                           <li>Experience multimedia content from each corner</li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className="creativity__wrapp">
            <div className="container">
               <div className="row">
                  <div className="col-lg-9 col-md-9">
                     <div className="content__wrapp">
                        <h2>Your creativity is your limit</h2>
                        <p>Launch a Marketing CampaignTrack the Campaign with RCM Analyticsshare Your RCM Frames B2B, B2C + Social Media</p>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                     <div className="image__box">
                        <img src={creativityBanner} alt="" />
                     </div>
                  </div>
               </div>
            </div>
         </div>


         <div className="features__content__wrapp">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-lg-5 col-md-5">
                     <div className="content__wrapp">
                        <h2><span>RCM</span> Features</h2>
                        <h3>Design & Management</h3>
                        <p>RCM design and management tools enable you to create and fully custom-ize your RCM Frames quickly and easily. Attractive images within the RCM Frame demand more attention, more scans and more interactions than a lackluster QR code. Effortlessly create a RCM campaign with several framed images aimed at various target audiences. You can run and test the effec-tiveness of a marketing campaign with various images aimed at the same audience. Simply create new marketing materials using the most successful frames.</p>
                        <h3>Content Editing</h3>
                        <p>RCM gives you the flexibility to simply and swiftly update the digital content within each of the four corners of a RCM Frame at any time, and as often as you want. Because the frame can remain the same even when the messages are changed, there is no need to reprint or replace your marketing and promotional materials. This means, RCM also saves you time and money!</p>
                        <h3>Campaign Analytics</h3>
                        <p>RCM Analytics monitors the performance of a RCM Frame campaign giving reports with feedback and insights including user reaction (scan), user response (swipe and open corners) and user interaction (call to action).</p>
                     </div>
                  </div>
                  <div className="col-lg-7 col-md-7">
                     <div className="image__box">
                        <img src={featureBanner} alt="" />
                     </div>
                  </div>
               </div>
            </div>
         </div>


         <div className="capabilities__wrapp">
            <div className="container">
               <div className="row">
                  <div className="col-lg-9 col-md-9">
                     <div className="content__wrapp">
                        <h2>View analytics and easily</h2>
                        <p>Similar to the QR code, but much more powerful. RCM Frames store website links, videos, advertisements, business cards, brochures, catalogs, menus, addresses, directions on the map, social media accounts, e-commerce, special offers, payments and so much more.</p>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                     <div className="image__box">
                        <img src={frameRCM} alt="" />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>


      <Footer />
    </>
  );
};

export default Scan;
