import React, { useEffect, useState } from 'react';
import Footer from '../../components/Footer';

import { Container } from './styles';

import contactus from '../../assets/images/contactus/contact.png';
import contactusLow from '../../assets/images/contactus/contact-low.png';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Menu from '../../components/Menu';
import ProgressiveImage from '../../components/ProgressiveImage';

import useContactus from '../../hooks/useContactUs';
import RcmModal from '../../components/Modal';


import frameRCM from '../../assets/images/latest_images/frm-img-1.png';
import userIco from '../../assets/images/latest_images/user-ico.svg';
import emailIco from '../../assets/images/latest_images/email-ico.svg';



const ContactUs: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const [result, setResult] = useState<string | undefined>();
  const { save, setValue } = useContactus();

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement| HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setValue(name, value);
  };

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const response = await save();
    setResult(response);
  };

  const handleOnHide = () => {
    setResult('');
    const form = document.getElementById('form-contact') as HTMLFormElement;
    if (form) form.reset();
  };

  return (
    <>
      
        {result && (
          <RcmModal
            show={true}
            title='RCM Message'
            message={result === 'Success' ? '' : result}
            onHide={handleOnHide}
          >
            {result === 'Success' && (
              <span>
                <p className='mb-5'>Thank you for contacting us.</p>
                <p className='mb-5'>
                  One of our team members will contact you shortly. In the
                  meantime, you may also find answers to some of your questions
                  on our FAQ page at
                  <a className='pl-2' href='https://reelcodemedia.com/faq'>
                    https://reelcodemedia.com/faq.
                  </a>
                </p>
                <p className='mb-5'>Kind regards,</p>
                <p>RCM Team.</p>
              </span>
            )}
          </RcmModal>
        )}


        <div className="body__content__main cms__page__wrapp contact__wrapp">
         <div className="container">
            <div className="heading__wrapp">
               <h2 style={{ fontSize: '32px' }}>Contact <span>Reel Code Media™</span></h2>
               <p>We're here to help and answer any questions you might have. We look forward to hearing from you!</p>
            </div>
            <div className="contact__wrapp__inner">
               <div className="form__wrapp__outer">
                  <h3>Get in <span>touch</span></h3>
                  <div className="form__wrapp">
                     <form id='form-contact' onSubmit={handleOnSubmit}>
                        <div className="form-group form__group">
                           <div className="row">
                              <div className="col-lg-6 col-md-6">
                                 <label>Name:</label>
                                 <div className="input-group input__group">
                                    <input type="text" 
                                           className="form-control form__control" 
                                           placeholder="Your Name"
                                          name='firstName'
                                          onChange={handleOnChange}
                                          required />
                                    <i className="ico__box"><img src={userIco} alt="" /></i>
                                 </div>
                              </div>
                              <div className="col-lg-6 col-md-6">
                                 <label>Email Address:</label>
                                 <div className="input-group input__group">
                                    <input type="email" 
                                           className="form-control form__control" 
                                           name='email'
                                           placeholder="Email Address" 
                                          required
                                          onChange={handleOnChange}/>
                                    <i className="ico__box"><img src={emailIco} alt="" /></i>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="form-group form__group">
                           <label>Subject:</label>
                           <input type="text" 
                                  className="form-control form__control" 
                                  placeholder="Subject"
                                  name='subject'
                                  onChange={handleOnChange}
                                  required />
                        </div>
                        <div className="form-group form__group">
                           <label>Message</label>
                           <textarea className="form-control form__control" 
                                     name='message'
                                    placeholder='Your message'
                                    onChange={handleOnChange}
                                    required></textarea>
                        </div>
                        <button type="submit" className="submit__btn">Submit</button>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div className="capabilities__wrapp">
         <div className="container">
            <div className="row">
               <div className="col-lg-9 col-md-9">
                  <div className="content__wrapp">
                     <h2>View analytics and easily</h2>
                     <p>Similar to the QR code, but much more powerful. RCM Frames store website links, videos, advertisements, business cards, brochures, catalogs, menus, addresses, directions on the map, social media accounts, e-commerce, special offers, payments and so much more.</p>
                  </div>
               </div>
               <div className="col-lg-3 col-md-3">
                  <div className="image__box">
                     <img src={frameRCM} alt="RCM Frame" />
                  </div>
               </div>
            </div>
         </div>
      </div>

      <Footer />
    </>
  );
};

export default ContactUs;
