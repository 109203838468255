import styled from 'styled-components';

export const Container = styled.div`
  height: 112.10913848876953px;
  width: 112.10913848876953px;

  position: relative;
  background-color: #c4c4c4;

  margin-bottom: 3.5rem;
  margin-right: 3.2rem;

  display: flex;
  .checkbox {
    margin-left: auto;
    padding: 0.7rem;

    input {
      width: 1.8rem;
      height: 1.8rem;
      z-index: 1;
      position: relative;
    }
  }
  span {
    font-size: 1.2rem;
    font-weight: var(--regular);
    position: absolute;
    transform: translateY(2rem);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 11.2rem;
    align-self: flex-end;
    cursor: pointer;

    input {
      width: 100%;
    }
  }

  img {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    aspect-ratio: 1/1;
  }
`;
