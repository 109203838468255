import React from 'react';

import { getCategories } from '../service/api';

import { Model } from '../interfaces';

export default function usePartner() {
  const [folders, setFolder] = React.useState<Model[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await getCategories();
      const { data } = response;
      if (data.status === 'success') setFolder(data.result);
    };
    fetchData();
  }, []);

  return [folders];
}
