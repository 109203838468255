import React, { useEffect, useState } from 'react';

import { Button, Row, Col } from 'react-bootstrap';

import {
  createNewFrame,
  deleteFrame,
  downloadFrame,
  getFrames
} from '../../service/frame';

import { confirm } from 'react-bootstrap-confirmation';

import Menu from '../Menu';
import Folder from '../../components/Folder';
import Frame from '../../components/Frame';

import { useAlert } from '../../hooks/useAlert';

import {
  Frame as FrameInfo,
  FrameSearch,
  Model,
  ReelGenerator
} from '../../interfaces';

import { Arrow, Delete, Edit, Chart, Orderby } from '../../assets/icons/bold';

import { Container } from './styles';
import { Redirect } from 'react-router-dom';
import Footer from '../../components/Footer';
import { getCategories } from '../../service/api';
import { createCategories, deleteCategories } from '../../service/categories';
import { changeFrameIndustry } from '../../service/industry';
import RcmModal from '../../components/Modal';

interface ElementSelected {
  id: number;
  type: 'Folder' | 'Frame';
}

const Library: React.FC = () => {
  const [folders, setFolder] = useState<Model[]>([]);
  const [frames, setFrames] = useState<FrameInfo[]>([]);
  const [logged, setLogged] = useState(true);
  const [variant, danger, success, clear] = useAlert();
  const [orderByName, setOrderByName] = useState('A-Z');
  const [orderByDate, setOrderByDate] = useState('A-Z');

  const [selected, setSelected] = useState<ElementSelected[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const [errorFolder, setErrorFolder] = useState(false);
  const [analytics, setAnalytics] = useState<boolean>();
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    const user = localStorage.getItem('users');
    if (!user) setLogged(false);

    async function createFrame() {
      const frameString = localStorage.getItem('frame');
      let frame: ReelGenerator;

      if (frameString) {
        frame = JSON.parse(frameString);
        try {
          const response = await createNewFrame(frame);
          if (response?.status === 'success') localStorage.removeItem('frame');
        } catch (e) {
          console.error(e);
        }
      }
    }

    createFrame();

    fetchCategories();
  }, []);

  useEffect(() => {
    folders.length > 0 && fetchData();
  }, [folders]);

  useEffect(() => {
    if (url != null) {
      fetch(`${window.location.origin}${url}`)
        .then((res) => {
          return res.blob();
        })
        .then((data) => {
          var a = document.createElement('a');
          a.href = window.URL.createObjectURL(data);
          a.download = url;
          a.click();
        });
    }
  }, [url]);

  async function fetchData(desc: string = '') {
    try {
      let search: FrameSearch = {
        pageSize: 100,
        pageSequence: 0,
        activated: 0,
        search: '',
        frameCategory: !desc ? folders[0].description : desc
      };
      const response = await getFrames(search);
      setFrames(response.data.result.records);
    } catch (e) {
      console.error(e);
    }
  }

  async function fetchCategories() {
    const response = await getCategories();
    const { data } = response;
    if (data.status === 'success') setFolder(data.result);
    else {
      localStorage.removeItem('users');
      setLogged(false);
      danger(data.err_message || data.err_msg || 'Undefined Error');
    }
  }

  useEffect(() => {
    if (edit && selected.filter((e) => e.type === 'Frame').length > 1) {
      danger('You can only select one RCM frame at a time.');
      setEdit(false);
    }
    if (edit && selected.length === 0) {
      danger('Please select a frame.');
      setEdit(false);
    }
  }, [edit]);

  useEffect(() => {
    if (!analytics && selected.length > 1) {
      danger('You can only select one RCM frame at a time.');
      setAnalytics(false);
    }
    if (analytics && selected.length === 0) {
      danger('Please select a frame.');
      setAnalytics(false);
    }
  }, [analytics]);

  function download() {
    if (selected.length > 0) {
      selected.forEach(async (element) => {
        const result = await downloadFrame(element.id);
        if (result.data.status === 'success') {
          setUrl(result.data.result.downloadUrl.replaceAll(' ', '%20'));
        }
      });
    }
  }

  const displayConfirm = async () => {
    if (selected.length > 0) {
      const result = await confirm('Are you sure you want to delete?', {
        title: `Delete the ${selected[0].type}`,
        okText: 'Yes',
        cancelText: 'No',
        okButtonStyle: 'danger'
      });

      if (result) {
        //delete frames first
        selected.sort((a, b) => {
          if (a.type > b.type) return -1;
          if (a.type < b.type) return 1;
          return 0;
        });

        selected.forEach(async (element) => {
          if (element.type === 'Frame') {
            await deleteFrame(element.id);
            success('Campaign has been deleted successfully');
            fetchData();
          } else {
            const result = await deleteCategories(element.id);
            if (result.status === 'success') {
              success('Folder has been deleted successfully');
              fetchCategories();
            } else {
              danger(result.err_msg || 'Error deleting folder');
              setTimeout(() => {}, 1000);
            }
          }
        });
        setSelected([]);
      }
    }
  };

  const createNewFolder = async () => {
    if (selected.length > 0) {
      const result = await createCategories(`Folder_${folders.length}`);
      if (result.status === 'success') {
        selected.forEach((element) => {
          changeFrameIndustry(element.id, result.result.id);
        });

        await fetchCategories();
        setSelected([]);
        setErrorFolder(false);
      }
    } else {
      setErrorFolder(true);
    }
  };

  const handleOnOrderByDate = () => {
    if (orderByDate === 'A-Z') {
      frames.sort((a, b) => {
        if (!a.corners || !a.corners[0] || !a.corners[0].createdOn) return 0;
        if (!b.corners || !b.corners[0] || !b.corners[0].createdOn) return 0;
        if (a.corners[0].createdOn < b.corners[0].createdOn) return -1;
        if (a.corners[0].createdOn > b.corners[0].createdOn) return 1;
        return 0;
      });
      setOrderByDate('Z-A');
      return;
    }

    if (orderByDate === 'Z-A') {
      frames.sort((a, b) => {
        if (!a.corners || !a.corners[0] || !a.corners[0].createdOn) return 0;
        if (!b.corners || !b.corners[0] || !b.corners[0].createdOn) return 0;
        if (a.corners[0].createdOn > b.corners[0].createdOn) return -1;
        if (a.corners[0].createdOn < b.corners[0].createdOn) return 1;
        return 0;
      });
      setOrderByDate('A-Z');
    }
  };

  const handleOnOrderByAlphabet = () => {
    if (orderByName === 'A-Z') {
      frames.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      setOrderByName('Z-A');
      return;
    }

    if (orderByName === 'Z-A') {
      frames.sort((a, b) => {
        if (a.name > b.name) return -1;
        if (a.name < b.name) return 1;
        return 0;
      });
      setOrderByName('A-Z');
    }
  };

  if (edit && selected.filter((e) => e.type === 'Frame').length === 1) {
    const frame = selected.filter((e) => e.type === 'Frame');
    if (frame.length > 0)
      return <Redirect to={`/creative-studio/${frame[0].id}`} />;
  }

  if (selected.length > 0 && analytics) {
    if (selected.length === 1 && selected[0].type === 'Frame')
      return (
        <Redirect
          to={{
            pathname: '/chart',
            state: {
              frameId: selected[0].id
            }
          }}
        />
      );
    else if (selected.length > 1) {
      setAnalytics(false);
    }
  }

  const handleOnSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const frameId = Number(event.currentTarget.id);
    if (!event.target.checked) {
      setSelected(selected.filter((fid) => frameId !== fid.id));
    } else setSelected([...selected, { id: frameId, type: 'Frame' }]);
  };

  const handleOnSelectFolder = (event: React.ChangeEvent<HTMLInputElement>) => {
    const folderId = Number(event.currentTarget.id);

    if (!event.target.checked) {
      setSelected(selected.filter((fid) => folderId !== fid.id));
    } else setSelected([...selected, { id: folderId, type: 'Folder' }]);
  };

  if (!logged) return <Redirect to='/' />;

  return (
    <>
      <Container>
        {variant.visible && (
          <RcmModal
            show={variant.visible}
            onHide={clear}
            title='Message'
            size='lg'
            message={variant.message || 'Error'}
          />
        )}
        <Row>
          <Menu />

          <div className='wrapper'>
            <Row className='actions'>
              <Col md={8}>
                <Button variant='dark' onClick={createNewFolder}>
                  CREATE FOLDER
                </Button>

                {errorFolder && (
                  <span className='error'>
                    Please select a frame to create a new folder
                  </span>
                )}
              </Col>

              <Col md={2} className='orderby'>
                <Button variant='dark' onClick={handleOnOrderByAlphabet}>
                  <img src={Orderby} alt='order by alphabet' />
                </Button>
                <span>a-z</span>
              </Col>

              <Col md={2} className='orderby'>
                <Button variant='dark' onClick={handleOnOrderByDate}>
                  <img src={Orderby} alt='order by date' />
                </Button>
                <span>date</span>
              </Col>
            </Row>

            <Row>
              <Col className='folder'>
                {folders.map((folder) => (
                  <Folder
                    key={folder.id}
                    {...folder}
                    fetchData={fetchData}
                    onSelect={handleOnSelectFolder}
                  />
                ))}
              </Col>
              <Col className='frames'>
                {frames.map((frame) => (
                  <Frame key={frame.id} {...frame} onSelect={handleOnSelect} />
                ))}
              </Col>
            </Row>
          </div>
        </Row>
        <Row className='actions-footer mb-5'>
          <Col md={{ span: 1, offset: 7 }}>
            <Button variant='light' onClick={displayConfirm}>
              <img src={Delete} alt='delete frame' />
            </Button>
            <label>delete</label>
          </Col>
          <Col md={1}>
            <Button onClick={() => setAnalytics(true)} variant='light'>
              <img src={Chart} alt='show analytics' />
            </Button>
            <label>analytics</label>
          </Col>
          <Col md={1}>
            <Button variant='light' onClick={() => setEdit(true)}>
              <img src={Edit} alt='Edit frame' />
            </Button>
            <label className='mr-1'>edit</label>
          </Col>
          <Col md={1}>
            <Button variant='light' onClick={download}>
              <img src={Arrow} alt='Download frame' />
            </Button>
            <label>download</label>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default Library;
