import { User, UserResult } from '../interfaces';
import { get, post, put } from './api';

export function getUser(id: number) {
  return get<UserResult>(`/users/${id}`);
}

export function saveUser(user: User) {
  // must be zero, because total frame with subscriptionFrames too, when profile is just updated, the totalframes is changed
  user.totalFrames = 0;
  saveAddress(user);
  return post(user, '/users/profile/update', true);
}

function saveAddress(user: User) {
  return put(user.address, '/address/update');
}
