import React, { useRef, useEffect, FormEvent } from 'react';

import useUser from '../../../hooks/useUser';
import { useAlert } from '../../../hooks/useAlert';

import { Alert, Button, Col, Row, Form } from 'react-bootstrap';

import { Container } from './styles';

import profile from '../../../assets/images/profile.svg';

const Profile: React.FC = () => {
  const [user, get, save, setUser] = useUser();

  const pwd = useRef<HTMLInputElement>(null);
  const repeatPwd = useRef<HTMLInputElement>(null);
  const [variant, danger, success, clear] = useAlert();
  if (pwd && pwd.current) pwd.current.value = '!@990pass';

  if (repeatPwd && repeatPwd.current) repeatPwd.current.value = '!@990pass';

  useEffect(() => {
    try {
      get();
    } catch (e) {
      danger(`${e}`);
    }
  }, []);

  const handleOnSubmit = async (event: FormEvent<HTMLFormElement>) => {
    const pwdValue = pwd.current?.value;

    if (pwdValue && pwdValue !== '!@990pass') {
      if (pwdValue !== repeatPwd.current?.value) {
        danger('Passwords do not match');
        return;
      }
      if (pwdValue.length < 8) {
        danger('Password should be at least 8 characters long');
        return;
      }
    }

    try {
      await save(new FormData(event.currentTarget));
      success('Profile updated');
    } catch (e) {
      danger(`${e}`);
    }
    event.preventDefault();
  };

  return (
    <>
      {variant.visible && (
        <Alert variant={variant.variant}>{variant.message}</Alert>
      )}
      <Container onSubmit={handleOnSubmit}>
        <Col md='4' className='wrapper-profile mb-3 p-0'>
          <div className='wrapper-image mb-3'>
            <img alt='avatar' src={profile} className='mb-3' />
          </div>
          <Row className='mb-4'>
            <Col>
              <Button className='font-secundary'>UPLOAD AVATAR</Button>
            </Col>
          </Row>

          <Form.Group as={Row} className='mb-4 p-0'>
            <Form.Label column sm='5' htmlFor='password'>
              PASSWORD
            </Form.Label>
            <Col sm='7'>
              <Form.Control id='password' ref={pwd} type='password' />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3 p-0'>
            <Form.Label column sm='5' htmlFor='repeatPassword'>
              REPEAT PASSWORD
            </Form.Label>
            <Col sm='7' style={{ alignSelf: 'center' }}>
              <Form.Control
                id='repeatPassword'
                ref={repeatPwd}
                type='password'
              />
            </Col>
          </Form.Group>
        </Col>
        <Col md='8' className='pr-0'>
          <Form.Group as={Row} className='mb-4'>
            <Form.Label column sm='4' className='pr-0' htmlFor='companyName'>
              COMPANY NAME
            </Form.Label>
            <Col sm='8' className='pr-0'>
              <Form.Control
                name='displayName'
                value={user?.displayName}
                onChange={setUser}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-4'>
            <Form.Label column sm='3' className='pr-0' htmlFor='fisrtName'>
              FIRST NAME
            </Form.Label>
            <Col sm='9' className='pr-0'>
              <Form.Control
                name='firstName'
                value={user?.firstName}
                required
                onChange={setUser}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-4'>
            <Form.Label column sm='2' className='pr-0' htmlFor='website'>
              WEBSITE
            </Form.Label>
            <Col sm='10' className='pr-0'>
              <Form.Control
                name='website'
                value={user?.website}
                onChange={setUser}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-4'>
            <Form.Label column sm='2' className='pr-0' htmlFor='email'>
              E-MAIL
            </Form.Label>
            <Col sm='10' className='pr-0'>
              <Form.Control
                name='email'
                type='email'
                value={user?.email}
                onChange={setUser}
                required
              />
            </Col>
          </Form.Group>

          <hr className='mb-4' />

          <Form.Group as={Row} className='mb-4'>
            <Form.Label column sm='2' htmlFor='address'>
              ADDRESS
            </Form.Label>
            <Col sm='10' className='pr-0'>
              <Form.Control
                name='address.addressLine1'
                onChange={setUser}
                value={user?.address.addressLine1}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-4'>
            <Form.Label column md='2' htmlFor='city'>
              CITY
            </Form.Label>
            <Col md='6' className='pr-0'>
              <Form.Control
                name='address.city'
                value={user?.address.city}
                required
                onChange={setUser}
              />
            </Col>

            <Form.Label column md='1' htmlFor='zip'>
              ZIP
            </Form.Label>
            <Col md='3' className='pr-0'>
              <Form.Control
                name='address.zipCode'
                value={user?.address.zipCode}
                onChange={setUser}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-4'>
            <Form.Label column md='2' htmlFor='state'>
              STATE
            </Form.Label>
            <Col md='5' className='pr-0'>
              <Form.Control
                name='address.state'
                value={user?.address.state}
                required
                onChange={setUser}
              />
            </Col>

            <Form.Label column md='2' htmlFor='country'>
              COUNTRY
            </Form.Label>
            <Col md='3' className='pr-0'>
              <Form.Control
                name='address.country'
                onChange={setUser}
                value={user?.address.country}
                required
              />
            </Col>
          </Form.Group>
        </Col>

        <Button className='btn-update' type='submit'>
          UPDATE
        </Button>
      </Container>
    </>
  );
};

export default Profile;
