import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { Container } from './styles';

import { Button, Col, Row } from 'react-bootstrap';

import Menu from '../Menu';

import { getSummary } from '../../service/frame';

import { Summary } from '../../interfaces';

import Footer from '../../components/Footer';

interface PerformanceProps {
  name: string;
  image: any;
  scans: number;
  total: number;
  topLeft: number;
  topRight: number;
  bottomRight: number;
  bottomLeft: number;
  updated: string;
}

const Performance: React.FC<PerformanceProps> = () => {
  const [frames, setFrames] = useState<Summary[]>([]);
  const [logged, setLogged] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const response = await getSummary();
      if (response.status === 'success') setFrames(response.result.records);
      else {
        setLogged(false);
      }
    }
    fetchData();
  }, []);

  if (!logged) return <Redirect to='/' />;

  return (
    <>
      <Container className='mb-5'>
        <Row>
          <Menu />

          <div className='wrapper'>
            <Row className='mb-7'>
              <Col>
                <Button variant='dark' as={Link} to={'/chart'}>
                  ANALYTICS
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className='scroll'>
                <table>
                  <thead>
                    <th className='pr-3'>SCANS</th>
                    <div className='border-th'>
                      <th>TOTAL</th>
                      <th>
                        <div className='bw'>TOP LEFT</div>
                      </th>
                      <th>
                        <div className='bw'>TOP RIGHT</div>
                      </th>
                      <th>
                        <div className='bw'>BOTTOM RIGHT</div>
                      </th>
                      <th>
                        <div className='bw'>BOTTOM LEFT</div>
                      </th>
                    </div>
                    <th className='pr-3 pl-3'>UPDATED</th>
                    <th className='pl-3'>ACTIVITY</th>
                  </thead>
                  <tbody>
                    {frames &&
                      frames.length > 0 &&
                      frames.map(
                        (
                          {
                            frameName,
                            frameId,
                            updatedImageURL,
                            viewed,
                            scanned,
                            swipedTopLeft,
                            swipedTopRight,
                            swipedBottomRight,
                            swipedBottomLeft,
                            updatedOn
                          },
                          index
                        ) => (
                          <tr key={index}>
                            <td className='td-image'>
                              <div className='image-box'>
                                <img
                                  src={`../../../${updatedImageURL}`}
                                  alt='frame'
                                />
                              </div>
                              <span title={frameName}>{frameName}</span>
                            </td>
                            <td className='box'>
                              <div>{scanned}</div>
                            </td>
                            <td className='box bg-two'>
                              <div>{viewed}</div>
                            </td>
                            <td className='box'>
                              <div>{swipedTopLeft}</div>
                            </td>
                            <td className='box bg-two'>
                              <div>{swipedTopRight}</div>
                            </td>
                            <td className='box'>
                              <div>{swipedBottomRight}</div>
                            </td>
                            <td className='box bg-two'>
                              <div>{swipedBottomLeft}</div>
                            </td>
                            <td className='update'>
                              <div>
                                {new Date(updatedOn).toLocaleDateString()}
                              </div>
                            </td>
                            <td className='activity'>
                              <Link
                                to={{
                                  pathname: '/chart',
                                  state: {
                                    updatedImageURL,
                                    frameId
                                  }
                                }}
                              >
                                <svg
                                  className='eye'
                                  width='25'
                                  height='23'
                                  viewBox='0 0 25 23'
                                  fill='none'
                                >
                                  <ellipse
                                    cx='12.5'
                                    cy='11.5'
                                    rx='12.5'
                                    ry='11.5'
                                  />
                                  <path
                                    d='M12.5 6C14.0512 6 15.522 6.53818 16.8024 7.53455C18.0829 8.52364 19.1732 9.97091 19.9561 11.7818C20.0146 11.92 20.0146 12.08 19.9561 12.2109C18.3902 15.8327 15.6024 18 12.5 18H12.4927C9.39756 18 6.60976 15.8327 5.0439 12.2109C4.98537 12.08 4.98537 11.92 5.0439 11.7818C6.60976 8.16 9.39756 6 12.4927 6H12.5ZM12.5 9.09091C10.8829 9.09091 9.57317 10.3927 9.57317 12C9.57317 13.6 10.8829 14.9018 12.5 14.9018C14.1098 14.9018 15.4195 13.6 15.4195 12C15.4195 10.3927 14.1098 9.09091 12.5 9.09091ZM12.5009 10.1802C13.5033 10.1802 14.3228 10.9948 14.3228 11.9984C14.3228 12.9948 13.5033 13.8093 12.5009 13.8093C11.4911 13.8093 10.6716 12.9948 10.6716 11.9984C10.6716 11.8748 10.6862 11.7584 10.7082 11.642H10.7448C11.557 11.642 12.2155 11.002 12.2448 10.202C12.3253 10.1875 12.4131 10.1802 12.5009 10.1802Z'
                                    fill='#F7F7F7'
                                  />
                                </svg>
                              </Link>
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default Performance;
