import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import { Button, Table, Col, Row, Form } from 'react-bootstrap';

import useIcons from '../../../hooks/useIcons';
import { ContentTypeFields, ReelGenerator } from '../../../interfaces';
import Title from '../../../components/Title';
import CloseButton from '../../../components/CloseButton';

import {
  contentDefault,
  options,
  handlePhoneKeyup,
  handlePhoneFocusLost
} from '../../../service/util';

import { Container } from './styles';

const getId = (target: string) => Number(target.split('-')[1]);

const ContentType: React.FC<ReelGenerator> = (props) => {
  const keys = useIcons();
  const theme = useTheme();
  const [type, setType] = useState({
    type: '',
    id: 0,
    fileName: 'No file chosen'
  });

  const [contentType, setState] = useState<ContentTypeFields[]>(contentDefault);

  function findIcon(name: string = 'COUPON') {
    return keys.find(([key, value]) => key === name)?.[1];
  }

  function handleOnSelectChange(event: React.FormEvent<HTMLSelectElement>) {
    const id = Number(event.currentTarget.id);

    const { value } = event.currentTarget;

    const newState = [...contentType];
    newState[id].contentTypeId = options.findIndex((e) => e.name === value) + 1;

    const input = document.getElementById(`input-${id}`) as HTMLInputElement;

    const newType = options.find((opt) => opt.name === value)?.type;
    const newPatter = options.find((opt) => opt.name === value)?.pattern;
    const newPlaceholder = options.find(
      (opt) => opt.name === value
    )?.placeholder;

    if (event.currentTarget.value !== 'Select') {
      input?.setAttribute('type', newType || 'text');
      input?.removeAttribute('pattern');

      input.removeEventListener('blur', handlePhoneFocusLost, true);
      input.removeEventListener('keyup', handlePhoneKeyup, true);

      if (newType === 'tel') {
        input.addEventListener('blur', handlePhoneFocusLost, true);
        input.addEventListener('keyup', handlePhoneKeyup, true);
      }

      if (newPatter) input?.setAttribute('pattern', newPatter);
      input?.setAttribute('placeholder', 'enter data');

      if (newPlaceholder) input?.setAttribute('placeholder', newPlaceholder);
      input?.removeAttribute('disabled');
      input?.focus();
    } else input?.setAttribute('disabled', 'true');

    if (input) {
      if (event.currentTarget.value === 'Weblink') input.value = 'https://';
      else input.value = '';
    }

    props.contentType.fields = newState;
    setType({ ...type, type: newType || 'text', id });
    setState(newState);
  }

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.currentTarget || event.target;

    const id = getId(target.id);

    if (
      event.target.type === 'file' &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      contentType[id].value = event.target.files[0].name;
    } else contentType[id].value = target.value;
    props.contentType.fields = contentType;

    setType({ ...type, fileName: contentType[id].value });
  }

  function handleOnDivFileClick(event: React.MouseEvent<HTMLDivElement>) {
    const id = Number(event.currentTarget.id.replace('div-', ''));
    const input = document.getElementById(`input-${id}`) as HTMLInputElement;

    if (input) input.click();
  }

  function currentSelect(id: number) {
    const select = document.getElementById(`${id}`) as HTMLSelectElement;
    if (select) {
      return select.value === 'Upload a file';
    }
    return true;
  }

  return (
    <form onSubmit={props.contentType.apply}>
      <Container className={props.contentType.show ? '' : 'hide'}>
        <div className='button-close'>
          <CloseButton
            close={props.contentType.close}
            color={theme.colors.primary}
          />
        </div>
        <div className='title font-tertiary bold'>
          <Title>choose content type</Title>
        </div>
        <Row>
          <Col>
            <div className='wrapper'>
              <Table>
                <thead>
                  <th>ICON</th>
                  <th>CONTENT TYPE</th>
                  <th>DATA</th>
                </thead>
                <tbody>
                  {props.icons.corners.map((c, index) => (
                    <tr key={index}>
                      <td>
                        <img src={findIcon(c.icon)} alt='icon corner'></img>
                      </td>
                      <td>
                        <select
                          onChange={handleOnSelectChange}
                          id={`${index}`}
                          required
                        >
                          <option>Select</option>
                          {options.map((opt) => (
                            <option key={opt.name}>{opt.name}</option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <Form.Control
                          id={`input-${index}`}
                          style={{
                            display: currentSelect(index) ? 'none' : 'block',
                            background: 'trasparent'
                          }}
                          size='lg'
                          required
                          disabled
                          onChange={handleOnChange}
                          placeholder='enter data'
                        />
                        {currentSelect(index) && (
                          <div
                            id={`div-${index}`}
                            className='file-input'
                            onClick={handleOnDivFileClick}
                          >
                            <span>Select a file</span>
                            <label>{type.fileName}</label>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='button-apply'>
              <Button variant='dark' type='submit'>
                Apply
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default ContentType;
