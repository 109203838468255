import styled from 'styled-components';
import { Container as ContainerBootstrap } from 'react-bootstrap';

export const Container = styled(ContainerBootstrap)`
  opacity: 95%;
  border-radius: var(--radius);
  background-color: ${(props) => props.theme.colors.secondary};

  position: absolute;
  padding: 1rem;
  z-index: 9999;

  width: 98%;
  top: 6rem;
  left: -1rem;
  height: 100%;
  margin-top: calc(var(--padding-large) * -1);
  transition: 1s ease-in-out;

  &.hide {
    opacity: 0;
    z-index: -1;
    height: 0;
  }

  .icons-list {
    overflow-y: auto;
    max-height: 50rem;
    filter: invert(99%) sepia(18%) saturate(123%) hue-rotate(326deg)
      brightness(109%) contrast(93%);
    img {
      width: 3.5rem;
      height: 3.5rem;
      margin: 2.5rem;
      cursor: pointer;
    }
  }

  .button {
    &-close {
      svg {
        background-color: ${(props) => props.theme.colors.secondary};
      }
      overflow: hidden;
      button {
        float: right;
        border: none;
        clear: right;
      }
    }

    &-apply {
      position: absolute;
      right: 2rem;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.backgrounds.one};
    border-radius: 1rem;
  }
`;
