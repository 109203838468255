import { Result } from './../interfaces/index';
import { get, post, deleteApi } from './api';
import {
  Contacts,
  ContactsResult,
  ContactsGroup,
  ContactsGroupResult
} from '../interfaces';

export async function getContacts() {
  const result = await get<ContactsResult>('/contacts');
  return result.data;
}

export async function getGroups() {
  const result = await get<ContactsGroupResult>('/contacts/groups');
  return result.data;
}

export async function addContactGroup(name: string) {
  const result = await post<ContactsGroup>({ name }, '/groups');
  return result.data;
}

export async function addContacts(contact: Contacts) {
  const result = await post<Result>([{ ...contact }], '/contacts/save');
  return result.data;
}

export async function deleteContacts(contactId: number) {
  const result = await deleteApi(contactId, '/contacts');
  return result.data;
}

export async function uploadContacts(file: File) {
  const data = {
    upload: file,
    'Content-type': 'text/csv'
  };
  const result = await post<Result>(data, '/contacts/upload', true);
  return result.data;
}
