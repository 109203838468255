import { Position } from '../interfaces';

export const defaultStep = {
  title: 'Create Your Reel Code Media ™',
  currentStep: 1,
  image: { path: '', type: '', name: '' },
  indsutryId: 0,
  name: '',
  layout: 'layout3',
  oldLayout: 'layout3',
  galleryId: 0,
  contentType: {
    show: false,
    fields: []
  },
  icons: {
    show: false,
    corners: [
      { position: Position.cornerTopLeft, contentTypeId: 1, iconId: 0 },
      { position: Position.cornerTopRight, contentTypeId: 1, iconId: 0 },
      { position: Position.cornerBottomLeft, contentTypeId: 1, iconId: 0 },
      { position: Position.cornerBottomRight, contentTypeId: 1, iconId: 0 }
    ]
  },
  steps: [
    {
      id: 1,
      currentId: 1,
      title: '',
      placeholder: '',
      tip: '',
      hoverTip: false
    }
  ]
};

const PageInfo = {
  preview: 'preview',
  stepsNew: [
    {
      id: 1,
      currentId: 1,
      title: 'STEP 1:',
      placeholder: 'upload an image',
      tip: 'Upload any high resolution image (minimum of 600 x 600 pixels)  in PNG, JPG, JPEG, GIF, BMP.',
      hoverTip: false
    },
    {
      id: 2,
      title: 'STEP 2:',
      placeholder: 'frame layout',
      tip: 'Select the RCM frame that best suits your design.',
      hoverTip: false
    },
    {
      id: 3,
      title: 'STEP 3:',
      placeholder: 'choose the icon for each corner',
      tip: 'Select the icon that best represents the content for each corner, drag the selected icon to the desired corner and click on Apply button once all 4 corners are completed.',
      hoverTip: false
    },
    {
      id: 4,
      title: 'STEP 4:',
      placeholder: 'customize data for each icon',
      tip: 'Insert link, email, address, phone number, file, or SMS for each corner and click on Apply button.',
      hoverTip: false
    }
  ],
  stepsLogged: [
    {
      id: 1,
      currentId: 1,
      title: 'STEP 1:',
      placeholder: 'upload an image',
      tip: 'Upload any high resolution image (minimum of 600 x 600 pixels)  in PNG, JPG, JPEG, GIF, BMP.',
      hoverTip: false
    },
    {
      id: 2,
      title: 'STEP 2:',
      placeholder: 'frame layout',
      tip: 'Select the RCM frame that best suits your design.',
      hoverTip: false
    },
    {
      id: 3,
      title: 'STEP 3:',
      placeholder: 'choose the icon for each corner',
      tip: 'Select the icon that best represents the content for each corner, drag the selected icon to the desired corner and click on Apply button once all 4 corners are completed.',
      hoverTip: false
    },
    {
      id: 4,
      title: 'STEP 4:',
      placeholder: 'customize data for each icon',
      tip: 'Insert link, email, address, phone number, file, or SMS for each corner and click on Apply button.',
      hoverTip: false
    }
  ],
  stepsEdit: [
    {
      id: 1,
      currentId: 1,
      title: 'EDIT IMAGE',
      placeholder: '',
      tip: 'Upload any high resolution image (minimum of 600 x 600 pixels)  in PNG, JPG, JPEG, GIF, BMP.',
      hoverTip: true
    },
    {
      id: 2,
      title: 'EDIT RCM FRAME LAYOUT',
      placeholder: '',
      tip: 'Select the RCM frame that best suits your design.',
      hoverTip: true
    },
    {
      id: 3,
      title: 'EDIT RCM ICONS',
      placeholder: '',
      tip: 'Select the icon that best represents the content for each corner, drag the selected icon to the desired corner and click on Apply button once all 4 corners are completed.',
      hoverTip: true
    },
    {
      id: 4,
      title: 'EDIT DATA FOR EACH ICON',
      placeholder: '',
      tip: 'Insert link, email, address, phone number, file, or SMS for each corner and click on Apply button.',
      hoverTip: true
    }
  ]
};

function useGenerator() {
  return PageInfo;
}

export default useGenerator;
