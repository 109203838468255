import styled from 'styled-components';

export const Container = styled.div`
  table {
    thead {
      margin: 0;
      background-color: ${(props) => props.theme.colors.backgrounds.six};
    }
    tr {
      th {
        &:first-child {
          flex: 0 0 5%;
        }
        &:nth-child(2) {
          flex: 0 0 10%;
        }
        &:nth-child(3) {
          flex: 0 0 60%;
        }
        &:last-child {
          flex: 0 0 15%;
        }
      }
    }
    .body-container {
      display: flex;
      overflow: auto;
      height: 20rem;
      justify-content: space-between;
      flex-wrap: wrap;
      font-family: var(--font-secundary);
      tbody {
        flex: 0 0 100%;
      }
    }

    tr {
      flex: 0 0 100%;
      align-items: start;
      line-height: 1.5rem;
      :nth-child(even) {
        background-color: ${(props) => props.theme.colors.backgrounds.six};
      }

      td {
        &:first-child {
          flex: 0 0 5%;
        }
        &:nth-child(2) {
          flex: 0 0 10%;
        }
        &:nth-child(3) {
          flex: 0 0 60%;
        }
        &:last-child {
          flex: 0 0 15%;
        }
      }
    }
  }

  .actions {
    flex: 0 0 100%;
    &-group {
      padding: 0;
    }
    .btn {
      min-width: 5rem;
      border-radius: 5rem;
    }
  }

  .footer {
    div[class^='col'] {
      text-align: right;
    }
    .btn:nth-child(2) {
      margin: 0 1rem;
    }
  }
`;
