export const handlePhoneKeyup = (event: KeyboardEvent) => {
  const element = event.target as HTMLInputElement;

  if (event.key === 'Backspace') return;
  if (element) {
    const { value } = element;
    if (value.length === 1) element.value = `(${value}`;
    if (value.length === 4) element.value = `${value}) `;
    if (value.length === 9) element.value = `${value}-`;
  }
};

export const handlePhoneFocusLost = (event: FocusEvent) => {
  const element = event.target as HTMLInputElement;

  if (element) {
    const { value } = element;
    if (value.length >= 6 && !value.includes('('))
      element.value = `(${value.substring(0, 3)}) ${value.substring(
        3,
        6
      )}-${value.substring(6)}`;
  }
};

export const options = [
  { name: 'Weblink', type: 'url' },
  { name: 'Location Address', type: 'text' },
  {
    name: 'Phone',
    type: 'tel',
    placeholder: '(123) 123-1234',
    pattern: '(\\([0-9]{3}\\)\\s|[0-9]{3}-)[0-9]{3}-[0-9]{4}'
  },
  { name: 'Email', type: 'email', placeholder: 'email@email.com' },
  { name: 'Upload a file', type: 'file' },
  {
    name: 'Sms',
    type: 'tel',
    placeholder: '(123) 123-1234',
    pattern: '(\\([0-9]{3}\\)\\s|[0-9]{3}-)[0-9]{3}-[0-9]{4}'
  }
];

export const contentDefault = [
  {
    contentTypeId: 0,
    value: '',
    iconId: 0
  },
  {
    contentTypeId: 0,
    value: '',
    iconId: 0
  },
  {
    contentTypeId: 0,
    value: '',
    iconId: 0
  },
  {
    contentTypeId: 0,
    value: '',
    iconId: 0
  }
];
