import React from 'react';

import { Container } from './styles';

import { Button, Col, Row } from 'react-bootstrap';

import Profile from './Profile';
import Subscription from './Subscription';

import Menu from '../Menu';
import Footer from '../../components/Footer';
import Transactions from './Transactions';
import Contact from './Contact';
import { Redirect } from 'react-router-dom';

interface AccountProps {
  name: string;
  image: any;
  scans: number;
  total: number;
  topLeft: number;
  topRight: number;
  bottomRight: number;
  bottomLeft: number;
  updated: string;
}

declare type AccountState =
  | 'profile'
  | 'subscription'
  | 'contacts'
  | 'transactions';

const logged = () => {
  return localStorage.getItem('users') ? true : false;
};

const Account: React.FC<AccountProps> = () => {
  const [currentView, setCurrentView] = React.useState<AccountState>('profile');

  const isOutline = (state: AccountState) => {
    return state === currentView ? 'outline-' : '';
  };

  if (!logged()) return <Redirect to='/' />;

  return (
    <>
      <Container className='mb-5'>
        <Row>
          <Menu />

          <div className='wrapper'>
            <Row className='mb-3'>
              <Col className='d-flex justify-content-between'>
                <Button
                  variant={`${isOutline('profile')}dark`}
                  onClick={() => setCurrentView('profile')}
                >
                  PROFILE
                </Button>
                <Button
                  variant={`${isOutline('subscription')}dark`}
                  onClick={() => setCurrentView('subscription')}
                >
                  SUBSCRIPTION
                </Button>

                <Button
                  variant={`${isOutline('transactions')}dark`}
                  onClick={() => setCurrentView('transactions')}
                >
                  TRANSACTIONS
                </Button>
              </Col>
            </Row>
            {currentView === 'profile' && <Profile />}
            {currentView === 'subscription' && <Subscription />}
            {currentView === 'contacts' && <Contact />}
            {currentView === 'transactions' && <Transactions />}
          </div>
        </Row>
      </Container>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default Account;
