import styled from 'styled-components';

export const Container = styled.div`
  .benefits {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.8rem;

    label {
      margin: 0;
      cursor: pointer;
    }
  }

  .benefits-list {
    font-weight: bold;
  }

  .price-card > .price-card-description button {
    border: none;
  }

  .price-card.starter .price-card-title,
  .price-card.starter > .price-card-description button {
    background-color: ${(props) => props.theme.colors.backgrounds.one};
  }

  .price-card.basic .price-card-title,
  .price-card.basic > .price-card-description button {
    background-color: ${(props) => props.theme.colors.backgrounds.two};
  }

  .price-card.advanced .price-card-title,
  .price-card.advanced > .price-card-description button {
    background-color: ${(props) => props.theme.colors.backgrounds.three};
  }

  .price-card.premium .price-card-title,
  .price-card.premium > .price-card-description button {
    background-color: ${(props) => props.theme.colors.backgrounds.five};
  }

  .price-card.gold .price-card-title,
  .price-card.gold > .price-card-description button {
    background-color: ${(props) => props.theme.colors.backgrounds.four};
  }

  .price-card.platinum .price-card-title,
  .price-card.platinum > .price-card-description button {
    background-color: ${(props) => props.theme.colors.primary};
  }
`;
