import styled from 'styled-components';

import { Container as ContainerBootstrap } from 'react-bootstrap';

export const Container = styled(ContainerBootstrap)`
  min-height: 60.2rem;
  margin-top: var(--default-margin-top);
  .wrapper {
    background-color: ${(props) => props.theme.colors.background};
    border-radius: var(--radius);

    height: 100%;
    max-width: 802px;
    width: 100%;
    padding: 5rem 4.5rem 0 5rem;
    margin: 0 auto;
  }

  .actions {
    margin-bottom: 3rem;

    .col-md-8 {
      padding: 0;
    }

    .orderby {
      display: flex;
      justify-content: space-around;
      align-items: center;

      .btn {
        min-width: 1.9rem;
        max-height: 1.9rem;
        min-height: 1.9rem;
        font-size: 0;
        padding: 0.4rem;
        border-radius: 5px;

        img {
          width: 100%;
        }
      }
    }

    &-footer {
      margin-top: 3rem;

      .col-md-2 {
        padding: 0;
        text-align: right;
      }

      .col-md-1 {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        label {
          text-transform: uppercase;
          font-family: var(--font-secundary);
          font-size: 1.5rem;
          line-height: 1.8rem;
          color: ${(props) => props.theme.colors.primary};
        }
      }

      .btn {
        border-radius: 50%;
        min-width: 5.4rem;
        height: 5.4rem;
        margin-bottom: 0.2rem;
        background: #c4c4c4;

        &:hover {
          background: ${(props) => props.theme.colors.backgrounds.four};
        }
      }
    }
  }

  .folder {
    border-radius: var(--radius);
    padding: 1rem;
    background-color: white;
    max-width: 11.6rem;
    font-size: 1rem;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    align-content: baseline;
    border: 0.1rem solid ${(props) => props.theme.colors.background};

    &:hover {
      background: transparent;
      border: 0.1rem solid ${(props) => props.theme.colors.backgrounds.four};
    }
  }

  .frames {
    width: 43.5rem;
    height: 43rem;
    display: flex;
    flex-wrap: wrap;

    align-items: flex-start;
    overflow: auto;
  }

  .error {
    font-size: 1.2rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.secondary};
    position: static;
    padding-left: 1rem;
  }
`;
