import React from 'react';
import { Purchase, User } from '../interfaces';
import { getPurchase } from '../service/purchase';

function usePurchase() {
  const [purchase, setPurchase] = React.useState<Purchase[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const usrStr = localStorage.getItem('users');
      if (usrStr) {
        const users: User = JSON.parse(usrStr);
        const response = await getPurchase(users.userId);

        if (response.status === 'success') setPurchase(response.result);
      }
    };
    fetchData();
  }, []);

  return purchase;
}

export default usePurchase;
