import React from 'react';

import { ThemeContext } from 'styled-components';

import Separator from '../../components/Separator';

import { Row, Col, Container as ContainerBoostrap } from 'react-bootstrap';

import { Container } from './styles';

import benefits1 from '../../assets/images/latest_images/card-img-1.png';
import benefits2 from '../../assets/images/latest_images/card-img-2.png';
import benefits3 from '../../assets/images/latest_images/card-img-3.png';

import Title from '../../components/Title';

const Benefits: React.FC = () => {
  return (

    <div className="why__rcm">
      <div className="container">
        <div className="heading__wrapp">
          <h2>Why Reel <span>CODE MEDIA?</span></h2>
          <p>Reel Code Media™ is a new, interactive visual communications medium that takes digital advertising, marketing and promotions to the next dimension.</p>
        </div>
        <div className="why__rcm__crads">
          <div className="item__card">
            <div className="image__box">
              <img src={benefits1} alt='Design and Management' />
            </div>
            <div className="content__wrapp">
              <h3>Design & Management</h3>
              <p>RCM design and management tools enable you to create and fully customize your RCM Frames quickly and easily. Attractive  images within the RCM Frame demand more attention, more scans and more interactions than a lackluster QR code. Effortlessly  create a RCM campaign with several framed images aimed at various target audiences. You can run and test the effectiveness of a marketing campaign with various images aimed at the same audience. Simply create new marketing materials using the most successful frames.</p>
            </div>
          </div>
          <div className="item__card">
            <div className="image__box">
              <img src={benefits2} alt='Content Editing'/>
            </div>
            <div className="content__wrapp">
              <h3>Content Editing</h3>
              <p>RCM gives you the flexibility to simply and swiftly update the digital content within each of the four corners of a RCM  Frame at any time, and as often as you want. Because the frame can remain the same even when the messages are changed, there  is no need to reprint or replace your marketing and promotional materials. This means, RCM also saves you time and money!</p>
            </div>
          </div>
          <div className="item__card">
            <div className="image__box">
              <img src={benefits3} alt='Design and Management RCM design and management tools' />
            </div>
            <div className="content__wrapp">
              <h3>Campaign Analytics</h3>
              <p>RCM Analytics monitors the performance of a RCM Frame campaign giving reports with feedback and insights including user- reaction (scan), user-response (swipe and open corners) and user-interaction (call to action). The reports show number of RCM scans, swipes, and interaction on a daily, monthly, quarterly and yearly basis. Over time, analytics help you improve a campaign and manage its progress and projected success.</p>
            </div>
          </div>
        </div>
      </div>
    </div>





  );
};

export default Benefits;
