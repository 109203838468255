import styled from 'styled-components';

export const Container = styled.div`
  min-width: 30rem;
  padding: 1rem;
  box-shadow: 0 0 1rem #979797;

  border: 0.1rem solid #323e48;

  font-family: var(--font-tertiary);

  .price-card-checks {
    cursor: pointer;
    width: 80%;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-around;

    font-weight: var(--regular);
    font-size: 1.5rem;
    padding: 2rem;

    div {
      display: flex;
      align-items: center;
    }

    label {
      margin-bottom: 0;
      margin-left: 1rem;
      color: #323e48;
    }

    input {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  .price-card-title {
    color: white;
    border-radius: 1rem;
    padding: 1rem;
    text-align: center;
    margin-bottom: 1rem;
    h4 {
      font-size: 1.6rem;
      margin: 0.5rem 0;
      font-weight: var(--bold);
    }

    div {
      width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      font-weight: var(--regular);
      font-size: 1.5rem;
      span {
        display: block;
        max-width: 100%;
        margin-bottom: 0.5rem;
      }
    }
  }

  .price-card-description {
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem;
    label {
      flex: 1 0 40%;
      border: 0.1rem solid #323e48;
      border-radius: 1rem;
      margin: 0;
      padding: 0.3rem;
      font-weight: var(--regular);
      color: #323e48;
      text-align: center;
      font-size: 1.2rem;
      line-height: 1.2;
    }
  }

  > .price-card-description label:last-child {
    padding: 1.5rem;
    color: #fff;
    right: 0.5rem;
    left: 0.5rem;
    bottom: 0;
    text-align: center;
    cursor: pointer;
  }
`;
