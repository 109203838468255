import { signIn, get } from './api';
import { UserLoginHistoryResult } from '../interfaces';

export async function login(email: string, pwd: string) {
  const result = await signIn(email, pwd);
  if (result.data.status === 'success') {
    localStorage.setItem('users', JSON.stringify(result.data.result));
  }
  return result;
}

export async function getLoginHistory() {
  const result = await get<UserLoginHistoryResult>('/users/login/history');
  return result.data;
}
