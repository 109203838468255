import React from 'react';

interface CloseButtonProps {
  close?: React.MouseEventHandler;
  color: string;
}

const CloseButton: React.FC<CloseButtonProps> = (props) => {
  return (
    <div className='button-close'>
      <button onClick={props.close}>
        <svg width='28' height='28' viewBox='0 0 28 28' fill='none'>
          <rect
            y='-2.12783'
            width='32.0617'
            height='4.66067'
            rx='2.33034'
            transform='matrix(0.704929 0.709278 -0.704929 0.709278 2.67093 2.38139)'
            fill={props.color}
            stroke={props.color}
            strokeWidth='3'
          />
          <rect
            x='-2.11479'
            width='32.0617'
            height='4.66067'
            rx='2.33034'
            transform='matrix(0.704929 -0.709278 0.704929 0.709278 2.71876 22.3222)'
            fill={props.color}
            stroke={props.color}
            strokeWidth='3'
          />
        </svg>
      </button>
    </div>
  );
};

export default CloseButton;
