import React from 'react';
import { UserLoginHistory } from '../interfaces';
import { getLoginHistory } from '../service/login';

function useLoginHistory() {
  const [loginHistory, setLoginHistory] = React.useState<UserLoginHistory[]>(
    []
  );

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await getLoginHistory();
      if (response.status === 'success') setLoginHistory(response.result);
    };
    fetchData();
  }, []);

  return loginHistory;
}

export default useLoginHistory;
