import React from 'react';
import { Contacts } from '../interfaces';
import {
  getContacts,
  addContacts,
  deleteContacts,
  uploadContacts as upload
} from '../service/contacts';

function useContacts() {
  const [contacts, setContacts] = React.useState<Contacts[]>([]);
  const [allContacts, setAllContacts] = React.useState<Contacts[]>([]);

  async function fetchData() {
    const response = await getContacts();
    if (response.status === 'success') {
      setAllContacts(response.result);
      setContacts(response.result);
    }
  }

  async function addContact(contact: Contacts) {
    const result = await addContacts(contact);
    if (result.status === 'success') fetchData();
    return result.status;
  }

  async function deleteContact(id: number) {
    const result = await deleteContacts(id);
    if (result.status === 'success') {
      setContacts(contacts.filter((c) => c.id !== id));
    }
    return result.status;
  }

  async function uploadContacts(file: File) {
    const result = await upload(file);
    if (result.status === 'success') fetchData();
    return result.status;
  }

  function filterContacts(groupId: number) {
    setContacts(allContacts.filter((c) => c.groupId === groupId));
  }

  return {
    contacts,
    addContact,
    deleteContact,
    uploadContacts,
    fetchData,
    filterContacts
  };
}

export default useContacts;
