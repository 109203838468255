import React from 'react';

import { useLocation } from 'react-router-dom';

import { ThemeContext } from 'styled-components';

import Separator from '../../components/Separator';
import Circle from '../../components/Circle';

import { rcmBasics, rcmStudio } from './data';

import { Row, Col, Container as ContainerBoostrap } from 'react-bootstrap';
import { Container } from './styles';
import Footer from '../../components/Footer';



const Faq: React.FC = () => {
  const themeContext = React.useContext(ThemeContext);

  const location = useLocation();

  return (

      <>
        <div className={location.pathname === '/faq' ? 'body__content__main cms__page__wrapp' :''} >

          <div className={location.pathname === '/faq' ? 'container' :''}>
            {location.pathname === '/faq' && (
              <div className="heading__wrapp">
                <h1>FAQ’ <span>s</span></h1>
                <p>RCM frames let you create powerful digital marketing solutions that engage customer-interaction. Just add the RCM frame around any image, and upload multimedia content to each corner.</p>
              </div>
            )}


            <div className="faq__wrapp">
              <div className="container">
                <div className="heading__wrapp">
                  <h2>Still have <span>questions?</span></h2>
                  <p>RCM frames let you create powerful digital marketing solutions that engage customer-interaction. Just add the RCM frame around any image, and upload multimedia content to each corner.</p>
                </div>
                <h3>RCM BASICS:</h3>
                <div className="accordion accordion__wrapp" id="accordionExample1">
                  {rcmBasics.map((basic, index) => (
                  <div className="accordion-item accordion__item" key={index}>
                    <h2 className="accordion-header accordion__header" id="{`cb-${index}`}">
                    <button className="accordion-button accordion__button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#cb-${index}`}
                    aria-expanded="true"
                    aria-controls="collapseOne">
                    <span> {basic.question}</span>
                    </button>
                    </h2>
                    <div id={`cb-${index}`}
                      className="accordion-collapse accordion__collapse collapse"
                      aria-labelledby="{`cb-${index}`}"
                      data-bs-parent="#accordionExample1">
                      <div className="accordion-body accordion__body">
                        <p>{basic.answer}</p>
                        <p className='mb-4 font-bold'>{basic.features}</p>
                        <ul>
                          {basic.features_list?.map((feature, index) => (
                          <li key={index} className='mb-4'>
                            {feature}
                          </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  ))}
                </div>
                <h3>RCM STUDIO:</h3>
                <div className="accordion accordion__wrapp" id="accordionExample2">
                  {rcmStudio.map((basic, index) => (
                  <div className="accordion-item accordion__item" key={index}>
                    <h2 className="accordion-header accordion__header" id="{`cb-${index}`}">
                    <button className="accordion-button accordion__button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#cb-${index}`}
                    aria-expanded="true"
                    aria-controls="collapseOne">
                    <span> {basic.question}</span>
                    </button>
                    </h2>
                    <div id={`cb-${index}`}
                      className="accordion-collapse accordion__collapse collapse"
                      aria-labelledby="{`cb-${index}`}"
                      data-bs-parent="#accordionExample2">
                      <div className="accordion-body accordion__body">
                        <p>{basic.answer}</p>
                      </div>
                    </div>
                  </div>
                  ))}
                </div>
              </div>
            </div>
             
          </div>
        </div>

        {location.pathname === '/faq' && <Footer />}

      </>
    
  );
};

export default Faq;
