import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';

import { Button, Table, Col, Row, Form } from 'react-bootstrap';

import useIcons from '../../../hooks/useIcons';
import { ContentTypeFields, ReelGenerator } from '../../../interfaces';
import Title from '../../../components/Title';

import { Container } from './styles';

import { findById } from '../../../service/icon';
import CloseButton from '../../../components/CloseButton';

import drop from '../../../assets/images/drop.svg';

import { Link } from 'react-router-dom';

import {
  contentDefault,
  options,
  handlePhoneKeyup,
  handlePhoneFocusLost
} from '../../../service/util';

const getId = (target: string) => Number(target.split('-')[1]);

const ContentType: React.FC<ReelGenerator> = (props) => {
  const keys = useIcons();
  const theme = useTheme();
  const [contentType, setState] = useState<ContentTypeFields[]>(contentDefault);
  const [type, setType] = useState({
    type: '',
    id: 0,
    fileName: 'No file chosen'
  });

  const divTip = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!props.contentType.show) return;
    debugger;
    const selects = document.querySelectorAll('select');
    selects.forEach((e) => {
      e.selectedIndex = 0;
    });

    props.icons.corners.sort((a, b) => a.position - b.position);

    props.contentType.fields.forEach((e, index) => {
      const element = document.getElementById(`input-${index}`);
      const select = document.getElementById(`${index}`);

      if (select instanceof HTMLSelectElement) {
        select.selectedIndex = e.contentTypeId;
      }

      if (element instanceof HTMLInputElement) {
        if (element.type !== 'file') element.value = e.value;
        element.removeAttribute('disabled');

        if (e.contentTypeId === 1 && e.value === '') element.value = 'https://';
      }
      if (e.iconId) findById(e.iconId);
    });
    if (props.contentType.fields.length === 4) {
      setState(props.contentType.fields);
    }
  }, [props.contentType.show]);

  function findIcon(name: string = 'COUPON') {
    return keys.find(([key]) => key === name)?.[1];
  }

  function handleOnSelectChange(event: React.FormEvent<HTMLSelectElement>) {
    const id = Number(event.currentTarget.id);

    const { value } = event.currentTarget;

    const newState = [...contentType];
    newState[id].contentTypeId = options.findIndex((e) => e.name === value) + 1;

    const input = document.getElementById(`input-${id}`) as HTMLInputElement;

    const newType = options.find((opt) => opt.name === value)?.type;
    const newPatter = options.find((opt) => opt.name === value)?.pattern;
    const newPlaceholder = options.find(
      (opt) => opt.name === value
    )?.placeholder;

    input?.setAttribute('type', newType || 'text');
    input?.removeAttribute('pattern');
    if (newPatter) input?.setAttribute('pattern', newPatter);
    input?.setAttribute('placeholder', 'enter data');
    if (newPlaceholder) input?.setAttribute('placeholder', newPlaceholder);
    input?.removeAttribute('disabled');
    input?.focus();

    input.removeEventListener('blur', handlePhoneFocusLost, true);
    input.removeEventListener('keyup', handlePhoneKeyup, true);

    if (newType === 'tel') {
      input.addEventListener('blur', handlePhoneFocusLost, true);
      input.addEventListener('keyup', handlePhoneKeyup, true);
    }

    if (input) {
      if (event.currentTarget.value === 'Weblink') input.value = 'https://';
      else input.value = '';

      if (event.currentTarget.value === 'Location Address') {
        if (divTip.current) {
          divTip.current.classList.add('show');
          if (divTip.current.style.marginTop)
            divTip.current.style.marginTop = `${id * 100}px`;
        }
      } else {
        if (divTip.current) divTip.current.classList.remove('show');
      }
    }

    props.contentType.fields = newState;
    setType({ ...type, type: newType || 'text', id });
    setState(newState);
  }

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.currentTarget || event.target;
    const id = getId(target.id);

    if (
      event.target.type === 'file' &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      contentType[id].value = event.target.files[0].name;
    } else contentType[id].value = target.value;

    props.contentType.fields = contentType;

    setType({ ...type, fileName: contentType[id].value });
  }

  function handleOnDivFileClick(event: React.MouseEvent<HTMLDivElement>) {
    const id = Number(event.currentTarget.id.replace('div-', ''));
    const input = document.getElementById(`input-${id}`) as HTMLInputElement;

    if (input) input.click();
  }

  function currentSelect(id: number) {
    const select = document.getElementById(`${id}`) as HTMLSelectElement;
    if (select) {
      return select.value === 'Upload a file';
    }
    return true;
  }

  /* useEffect(() => {
    return function cleanup() {
      const selects = document.querySelectorAll('select');
      selects.forEach((e) => {
        e.selectedIndex = 0;
      });

      const inputs = document.querySelectorAll('input');
      inputs.forEach((e) => {
        e.value = '';
      });
    };
  }, []);*/

  return (
    <form onSubmit={props.contentType.apply}>
      <Container className={props.contentType.show ? '' : 'hide'}>
        <div className='d-flex justify-content-between p-2 mb-3'>
          <div className='title font-tertiary bold'>
            <Title>choose content type</Title>
          </div>
          <div className='button-close'>
            <CloseButton
              close={props.contentType.close}
              color={theme.colors.background}
            />
          </div>
        </div>
        <Row>
          <Col>
            <div className='wrapper'>
              <Table>
                <tbody>
                  {props.icons.corners.map((c, index) => (
                    <tr key={index}>
                      <td>
                        <img src={findIcon(c.icon)} alt='icon corner'></img>
                      </td>
                      <td>
                        <select onChange={handleOnSelectChange} id={`${index}`}>
                          <option selected>Select</option>
                          {options.map((opt) => (
                            <option key={opt.name}>{opt.name}</option>
                          ))}
                        </select>

                        <Form.Control
                          id={`input-${index}`}
                          style={{
                            display: currentSelect(index) ? 'none' : 'block'
                          }}
                          size='lg'
                          required
                          disabled
                          onChange={handleOnChange}
                          placeholder='enter data'
                        />
                        {currentSelect(index) && (
                          <div
                            id={`div-${index}`}
                            className='file-input'
                            onClick={handleOnDivFileClick}
                          >
                            <span>Select a file</span>
                            <label>{type.fileName}</label>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Link to='/files/tutorial.pdf' target='_blank' download>
                <div className='drop' ref={divTip} style={{ marginTop: 0 }}>
                  <div className='drop-image'>
                    <img src={drop} alt='tips'></img>
                  </div>
                  <div className='drop-message'>
                    <span>Don't know how to do it?</span>
                    <span>
                      Check tutorial!
                      <br />
                      <b>PDF</b>
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className='mt-3'>
            <div className='button-apply'>
              <Button variant='dark' type='submit'>
                Apply
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default ContentType;
