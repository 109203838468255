import styled from 'styled-components';

import { Container as ContainerBootstrap } from 'react-bootstrap';

export const Container = styled(ContainerBootstrap)`
  margin-top: var(--default-margin-top);
  .wrapper {
    background-color: ${(props) => props.theme.colors.background};
    border-radius: var(--radius);

    height: 100%;
    min-height: 50rem;
    max-width: 80.2rem;
    width: 100%;
    padding: 5rem 4.5rem 0 5rem;
  }

  .border-th {
    font-size: 1rem;
    padding: 1rem;
    border: 0.1rem solid ${(props) => props.theme.colors.backgrounds.two};
    border-radius: 0.4rem;
    width: 32.7rem;
    display: flex;
    align-items: center;
    th {
      padding: 0;
      width: 6rem;
      line-height: 1rem;
      text-align: center;
      vertical-align: middle;
    }
  }

  table {
    width: 100%;
    color: #000000;
    border-collapse: collapse;
    font-family: var(--font-tertiary);
    thead {
      font-size: 1.4rem;
      margin-left: 18.7rem;
      margin-bottom: 2rem;
    }

    thead {
      display: flex;
      align-items: center;
    }
    tr {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    td {
      div {
        padding: 2rem 2.2rem;
      }
    }

    .td-image {
      display: flex;
      flex: 1;
      align-items: center;
      .image-box {
        width: 3.5rem;
        padding: 0;
        img {
          width: 100%;
        }
      }
      span {
        font-family: var(--font-tertiary);
        font-size: 1.6rem;
        margin: 0 1.4rem;
      }
    }
  }

  .bw {
    width: 3rem;
    margin: auto;
  }

  .btn {
    font-size: 1.6rem;
  }
`;
