import { useState } from 'react';
import { UserResult } from '../interfaces';
import { login } from '../service/login';

function useLogin() {
  const [result, setUser] = useState<UserResult>();

  async function enter(email: string, pwd: string) {
    const data = await login(email, pwd);
    setUser(data.data);
  }

  return [result, enter] as const;
}

export default useLogin;
