import styled from 'styled-components';

import { Container as ContainerBootstrap } from 'react-bootstrap';

export const Container = styled(ContainerBootstrap)`
  background-color: ${(props) => props.theme.colors.background};
  border-radius: var(--radius);

  padding-top: 3.8rem;

  h1 {
    display: none;
  }

  .wrapper {
    position: relative;

    height: 38.2rem;

    .preview {
      height: 21.3rem;
      width: 21.3rem;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      object-fit: cover;
      position: absolute;
      mix-blend-mode: multiply;
    }
    .corner {
      &-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-icon {
        filter: invert(100%) sepia(0%) saturate(7471%) hue-rotate(50deg)
          brightness(131%) contrast(100%);
        width: 3.5rem;
        height: 3.5rem;
        mix-blend-mode: difference;
        object-fit: unset;
        position: absolute;
      }
    }
  }

  .navigation {
    display: flex;
    justify-content: space-between;

    text-align: center;

    padding: 3rem;

    .back::before {
      content: '<';
    }
    .next::after {
      content: '>';
    }
  }

  .bottom-icons {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    display: flex;
    justify-content: space-between;
    z-index: 999;

    transition: 1s ease-in-out;
    svg {
      width: 5.2rem;
      height: 5.2rem;
      &:first-of-type {
        left: -5rem;
        bottom: -91px;
      }
      &:last-of-type {
        right: 0rem;
        bottom: -91px;
      }
    }
  }

  .top-icons {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    display: flex;
    justify-content: space-between;
    transition: 1s ease-in-out;
    z-index: 999;

    svg {
      width: 5.2rem;
      height: 5.2rem;
      &:first-of-type {
        left: -5rem;
        bottom: -91px;
      }
      &:last-of-type {
        right: 0rem;
        bottom: -91px;
      }
    }
  }

  .hide {
    opacity: 0;
  }

  .pointer {
    transition: 0.5s ease-in-out;
  }

  .focus {
    fill: ${(props) => props.theme.colors.scrollbar};
    opacity: 1;
  }

  img:hover {
    filter: saturate(150%);
  }

  .btn {
    font-size: 1.6rem;
  }
`;
