import React, { useCallback, useState, useRef, useEffect } from 'react';

import folderImg from '../../assets/icons/bold/folder.svg';
import { Model } from '../../interfaces';

import { Container } from './styles';

import { Overlay, Tooltip } from 'react-bootstrap';

import { changeFrameIndustry } from '../../service/industry';
import { editCategory } from '../../service/categories';

interface FolderProps extends Model {
  fetchData: (desc: string) => {};
  onSelect(event: React.ChangeEvent<HTMLInputElement>): void;
}

const Folder: React.FC<FolderProps> = (props) => {
  const { id, description } = props;
  const [edit, setEdit] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputCheckboxRef = useRef<HTMLInputElement>(null);

  const containerRef = useRef<HTMLSpanElement>(null);
  let timer: NodeJS.Timer;

  const findFrames = () => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(function () {
      props.fetchData(props.description);
      const container = containerRef.current;
      if (container) {
        const parentElement = container.parentElement;
        parentElement?.childNodes.forEach((element) => {
          if (element instanceof HTMLSpanElement) {
            element.classList.remove('active');
          }
        });
      }
      containerRef.current?.classList.add('active');
    }, 1000);
  };

  const handelOnDoubleClick = () => {
    if (timer) clearTimeout(timer);
    setEdit(true);
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      setEdit(false);
    }
  };

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = props.description;
      inputRef.current.focus();
    }
  }, [edit]);

  const handleOnSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const newFrameName = { ...props };
    newFrameName.description = inputRef.current?.value || description;
    newFrameName.id = id;
    const result = await editCategory(newFrameName);
    if (result.status === 'success') {
      props.description = inputRef.current?.value || description;
      setEdit(false);
    }
  };

  const handleOnDrop = async (event: React.DragEvent<HTMLSpanElement>) => {
    event.preventDefault();
    const frameId = Number(event.dataTransfer.getData('id'));
    if (frameId && frameId > 0) {
      const result = await changeFrameIndustry(Number(frameId), props.id);
      if (result.status === 'success') findFrames();
    }
  };

  const handleOnDragOver = (event: React.DragEvent<HTMLSpanElement>) => {
    event.preventDefault();
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setEdit(false);
  };

  const findFramesByCheckBox = (event: React.MouseEvent<HTMLDivElement>) => {
    console.log(inputCheckboxRef.current?.checked);
    if (inputCheckboxRef.current?.checked) {
      findFrames();
    }
  };

  return (
    <Container
      onDrop={handleOnDrop}
      onDragOver={handleOnDragOver}
      ref={containerRef}
    >
      {!edit && (
        <div className='folder-select' onClick={findFramesByCheckBox}>
          <input
            type='checkbox'
            onChange={props.onSelect}
            id={`${props.id}`}
            ref={inputCheckboxRef}
          />
        </div>
      )}
      <img src={folderImg} alt='folder' onClick={findFrames} />
      <div className='folder-name'>
        {!edit && (
          <span onClick={findFrames} onDoubleClick={handelOnDoubleClick}>
            {props.description}
          </span>
        )}
      </div>
      {edit && (
        <form onSubmit={handleOnSubmit}>
          <input
            required
            minLength={5}
            ref={inputRef}
            onBlur={handleOnBlur}
            onKeyDown={handleOnKeyDown}
          />
          <Overlay target={inputRef} show={edit} placement='bottom'>
            <Tooltip id={`id-${props.description}`}>
              Press ENTER to save or ESC to cancel
            </Tooltip>
          </Overlay>
        </form>
      )}
    </Container>
  );
};

export default Folder;
