import styled from 'styled-components';

import { Container as ContainerBootstrap } from 'react-bootstrap';
export const Container = styled(ContainerBootstrap)`
  opacity: 95%;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: var(--radius);

  position: absolute;
  padding: 1rem;
  z-index: 9999;

  width: 40%!important;
  top: 50%;
  left:8%;
  height: 100%;
  margin-top: calc(var(--padding-large) * -1);
  transition: 1s ease-in-out;
  max-height: 56rem;

  &.hide {
    opacity: 0;
    z-index: -1;
  }

  .title {
    padding-left: var(--padding-large);
    color: ${(props) => props.theme.colors.primary};
  }

  .wrapper {
    padding: var(--padding-large);
  }

  .file-input {
    border: 1px solid ${(props) => props.theme.colors.primary};
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    line-height: 2.4rem;
    span {
      &:hover {
        color: ${(props) => props.theme.colors.backgrounds.one};
        cursor: pointer;
      }
    }
    label {
      max-width: 26rem;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      white-space: nowrap;
      font-size: 1.4rem;
    }
  }

  table {
    border-radius: 1rem;
    border-collapse: collapse;

    box-shadow: 0 0 0 1px ${(props) => props.theme.colors.primary};

    thead {
      border-style: hidden;
      th {
        &:first-child {
          border-top-left-radius: 1rem;
        }
        &:last-child {
          border-top-right-radius: 1rem;
        }
        background-color: ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.background};
        padding-top: 1.4rem;
        text-align: center;
        font-size: 1.8rem;
      }
    }
    tbody {
      tr {
        border-right: 0.1rem;
      }
      td {
        vertical-align: middle;
        text-align: center;
        height: 8rem;
        padding: 1rem 1.5rem;
        border-right: 0.1rem solid ${(props) => props.theme.colors.scrollbar};
        img {
          width: 50%;
        }

        &:last-of-type {
          border-right: none;
        }
      }
    }
  }

  .button {
    &-close {
      overflow: hidden;
      button {
        float: right;
        border: none;
        clear: right;
      }
    }

    &-apply {
      position: absolute;
      right: calc(var(--padding-large) + 1rem);
      bottom: 0.5rem;
    }
  }

  .form-control {
    border: 1px solid ${(props) => props.theme.colors.primary};
    padding: 0 1rem;
    border-radius: 0.4rem;
    min-width: 34rem;
  }

  select {
    padding: 0.7rem;
    min-width: 15rem;
  }
`;
