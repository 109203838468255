import React from 'react';
import { ContactUs } from '../interfaces';
import { addContactus } from '../service/contactus';

const DEFAULT_VALUE: ContactUs = {
  firstName: '',
  lastName: '',
  email: '',
  message: '',
  subject: '',
  company: '',
  industry: 1
};

function useContactus() {
  const [contactus, setState] = React.useState<ContactUs>(DEFAULT_VALUE);

  const save = async () => {
    debugger;
    if (!contactus) return;
    const result = await addContactus(contactus);
    return result.data.status;
  };

  const setValue = (name: string, value: string) => {
    setState({ ...contactus, [name]: value });
  };

  return { save, setValue };
}

export default useContactus;
