import { Result } from '../interfaces';
import { get } from './api';

//20 == other and 1 == default
export async function createIndustry(id: number) {
  return get<Result>(`/frame/industry/${id}/20/1`);
}

export async function changeFrameIndustry(frameId: number, industryId: number) {
  const result = await get<Result>(
    `/frame/industry/${frameId}/20/${industryId}`
  );
  return result.data;
}
