import React from 'react';
import { useTheme } from 'styled-components';

import { Button, Col, Row } from 'react-bootstrap';

import { Container } from './styles';

import useIcons from '../../../hooks/useIcons';

import { CustomIconsProps } from '../../../interfaces';
import CloseButton from '../../../components/CloseButton';

const CustomIcons: React.FC<CustomIconsProps> = (props) => {
  const keys = useIcons();
  const theme = useTheme();
  const { apply, close } = props;

  const handleDragStart = (event: React.DragEvent<HTMLImageElement>) => {
    event.dataTransfer.setData('id', event.currentTarget.id);
  };

  return (
    <form onSubmit={apply}>
      <Container className={props.show ? '' : 'hide'}>
        <div className='button-close'>
          <CloseButton close={close} color={theme.colors.background} />
        </div>
        <Row>
          <Col className='icons-list'>
            {keys.map(([key, value]) => (
              <img
                id={key}
                draggable={true}
                key={key}
                src={value}
                alt={`Option ${key}`}
                title={`${key}`}
                onDragStart={handleDragStart}
              ></img>
            ))}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='button-apply'>
              <Button variant='dark' type='submit'>
                Apply
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default CustomIcons;
