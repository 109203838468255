import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

    :root{
        --radius: .8rem;     
        --padding-large: 5.6rem;
        --font-primary: 'Inter';
        --font-secundary: 'Lato';
        --font-tertiary: 'Roboto';
        --font-fourth:  'Work Sans';
        --bold:700;
        --regular:400;
        --default-margin-top:12rem;
    }

    *{
        margin: 0;
        padding:0;
        box-sizing: border-box;
        list-style: none;
        text-decoration: none;
    }
    
    a{
        color: ${(props) => props.theme.colors.primary};
        &:hover{
            text-decoration: none;
        }
    }

    html{
        font-size: 62.5%;
        font-family: sans-serif;        
    }

    body{
        
        font-size: 1.6em;
        font-family: var(--font-primary);        
        font-style: normal;
        font-weight: 400;
        
        padding: 0;        
        line-height: 2.1rem;
        letter-spacing: 0em;
        
        overflow-x:hidden;
        background-color: ${(props) => props.theme.colors.backgrounds.eight};
    }

    ::-webkit-scrollbar {
        width: 1rem;
    }
    ::-webkit-scrollbar-thumb {
        background-color:${(props) => props.theme.colors.scrollbar};         
        border-radius: 1rem;
    }

    .btn{        
        font-size: 2.2rem;
        font-family: var(--font-secundary) ;
        text-transform: capitalize;
        padding: 1rem 1.5rem;
        min-width: 12.8rem;
        border-radius: .5rem;
        &-primary{
            background-color: ${(props) => props.theme.colors.backgrounds.two};
            border-color: ${(props) => props.theme.colors.backgrounds.two};
            &:hover,
            &:active{
                background-color:${(props) =>
                  props.theme.colors.backgrounds.two};
            }
        }
        &-dark{
            background-color: ${(props) => props.theme.colors.primary};
        }

        &.btn-outline-primary{
            border-width: .1rem;
            border-style: solid;
            border-color: ${(props) => props.theme.colors.backgrounds.two};
            color: ${(props) => props.theme.colors.backgrounds.two}
        }

        &.btn-success{
            background-color:${(props) => props.theme.colors.backgrounds.three}
        }

        &-dark-2{
            background-color: #828282;
            font-size: 1.5rem!important;
        }
    }
   
    .font{

        &-primary{
            font-family: var(--font-primary) ;   
            font-size: 1.4rem;  
            font-weight: 700;        
        } 
        
        &-secundary{
            font-family: var(--font-secundary) ; 
            font-size: 1.4rem;  
            font-weight: 700;        
        }    

        &-tertiary{
            font-family: var(--font-tertiary) ; 
            font-size: 1.4rem;  
            font-weight: 700;        
        }    

        &-color{
            &-primary{
                color: ${(props) => props.theme.colors.primary};
            }
            &-one{
                color: ${(props) =>
                  props.theme.colors.backgrounds.one} !important;
            }

            &-two{
                color: ${(props) =>
                  props.theme.colors.backgrounds.two} !important;
            }
        }

        &-weight{
            &-500{
                font-weight: 500;
            }

            &-600{
                font-weight: 600;
            }
        }
    }

              
    .bold{        
        line-height: 1.7rem;        
    }

    .font-bold{
        font-weight: var(--bold)
    }

    .regular{        
        line-height: 1.9rem;    
    }
    
    .large{
        font-size: 2rem;       
        line-height: 2.4rem;
        text-transform: capitalize;
    }
    
    .sm-large{
        font-size: 2.4rem;
        line-height: 2.9rem;
        text-transform: uppercase;
    }

    .md-large{
        font-size: 2.6rem;               
        text-transform: uppercase;
    }  

    .x-large{
        font-size: 3.6rem;               
        text-transform: capitalize;
        @media (max-width: 390px){
            font-size: 3.0rem;      
        }
    } 

    .x-md-large{
        font-size: 4.8rem;
    }
    
    .xx-large{
        font-size: 6rem !important;
        line-height: 7.2rem;
        
        @media (max-width: 760px){            
            font-size: 5rem !important;
            line-height: 5.2rem !important;
            text-align: center;                    
        }
        @media (max-width: 425px){            
            font-size: 3rem !important;
            line-height: 3.6rem !important;
                             
        }
    }

    .text-transform{
        &-uppercase{
            text-transform: uppercase;
        }
        &-lowercase{
            text-transform: lowercase;
        }
        &-capitalize{
            text-transform: capitalize;
        }
    }

    .shadow{
        &-sm{
            box-shadow: 0px 4px 4px ${(props) =>
              props.theme.colors.shadow} !important;
        }
    }

    .container{

        max-width:1300px;

        &-fluid{
            padding: 0;
        }
    }

    .container + .container-fluid{
        padding: 1rem;
    }

    h1{
        text-transform: capitalize;
    }

    //spacing
    .ml-6{
        margin-left: 6rem !important;
    }
    .pb-5{
        padding-bottom: 5rem !important;
    }
    .pt-5{
        padding-bottom: 5rem !important;
    }
    .pb-7{
        padding-bottom: 7rem !important;
    } 
    .pb-10{
        padding-bottom: 10rem !important;
    } 
    .pb-20{
        padding-bottom: 20rem !important;
    } 
    .pt-7{
        padding-top: 7rem !important;
    }

    .pt-10{
        padding-top: 10rem !important;
    }

    .pt-20{
        padding-top: 20rem !important;
    }

    .mb-10{
        margin-bottom: 10rem !important;
    }
   
    .mb-16{
        margin-bottom: 1.6rem !important;
    }
    .mb-36{
        margin-bottom: 3.6rem !important;
    }

    .mt-6{
        margin-top: 6rem !important;
    }
    .mt-13{
        margin-top: 13rem !important;
        @media(max-width:425px){
            margin-top: 8rem !important;
        }
    }
    .mt-20{
        margin-top: 20rem !important;
    }
    .mb-5{
        margin-bottom: 5rem !important;
    }
    .mb-6{
        margin-bottom: 6rem !important;
    }
    .mb-7{
        margin-bottom: 7rem !important;
    }
    .mb-9{
        margin-bottom: 9rem !important;
    }
    .mt-5{
        margin-top: 5rem !important;
    }
    .mt-7{
        margin-top: 7rem !important;
    }
    .mt-8{
        margin-top: 8rem !important;
    }
    .mt-9{
        margin-top: 9rem !important;
    }
    .mb-8{
        margin-bottom: 8rem!important;
    }   
       
    .bg{
        &-clear{
            background-color: #e5e5e566;
        }
        &-two{
        background-color: ${(props) => props.theme.colors.backgrounds.two};
        }
        &-six{
            background-color: ${(props) => props.theme.colors.backgrounds.six};
        }
        &-color{
            background-color: ${(props) => props.theme.colors.background};
        }
    }
    //mobile
    .mb{

        &-pt-1{
            padding-top:1rem !important;
        }
        &-pt-2{
            padding-top:2rem !important;
        }
        &-pt-3{
            padding-top:3rem !important;
        }
        &-pt-4{
            padding-top:4rem !important;
        }
        &-pt-5{
            padding-top:5rem !important;
        }
    }


    .form-control,
    form{
        font-family: var(--font-secundary) ;
        font-size:1.6rem;
        font-weight:400;               
    }

    input.form-control{
        padding: 1.7rem;
    }

    input[type='file']::file-selector-button {
    visibility: hidden;
  }
  input[type='file']::before {
    content: 'Select a file';
    display: inline-block;

    -webkit-user-select: none;
    cursor: pointer;
    font-size: 1.6rem;
  }
  input[type='file']:hover::before {
    color: ${(props) => props.theme.colors.backgrounds.one};
  }
    .col-form-label{
        line-height: 1.9rem;
    }    

    select{
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url("data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAYCAYAAADkgu3FAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIhSURBVHgBrZa/btNAHMe/d64QZaFrhVQ8wMBmMlYomAERWChPgPIEtE+Q+AlK38A8AWXCTDUEiU6R2TsYlo6kC0qJej9+P8cOdcg5TuuvlJx1d77P3e/fWWGJvEcdX2t6SdC+gnEBtSH9BKT8lyrQBzNRh8lxlFato6oASqHHPx91RAjNBIEN+B/I8/0NbdZ7/OYuVpYaMTAYDj6+nR9x5iGOWT9iyA6uppu89c7m3Xs4/XHy2Qq6s/XgG4E8XF/+5tb9s9OfJ8dFhy4eWu1OryHIVIr2xc8lkOd3XG76aFgSTCWQpn8dDYP84lRT01HNEL6CtMLrrM2J7qJJkpSG0M2S0yo6MEBgHc7zUDPRta+BNBlEIWk84UBJFswIhl8+7bJdQtjlZrmJCpDYWKIxiaOU9Pk8jCFRXwLJMep9BQjqz63bGsvVn8Li0SXYDKINjuqkxZqYx17xSjAM40h88VA6CghQYfpcdOP3mTYXFwnqKTvZyhAOJLFGdpZW+9mvovwvE18LCWWAevOlqg8HUTf3kTpATRGUVxvC4vR4J20GMnrMZZ1GaFhEiJOvUTwDiQ0N6QANiyboFs+z8E74spIdoDmVbttSHpEzfmWpACtDJM8udyzMoFb7ufjsDVYUb3KkSO1xlIXzY/aPk+2nnnKc/TofJzng0DjjPfH3ojlLa4K3/cLTa2aH/fdY6iK/4FIWoYoXR2xA3+GchzZAob9eeev5o7AQtQAAAABJRU5ErkJggg==");
        background-repeat: no-repeat;
        background-position-x: 98%;
        background-position-y: 4px;
        border: 1px solid ${(props) => props.theme.colors.primary};
        border-radius: 5px;
        width: 100%;
        padding: 1rem;
        padding-right: 2rem;
    }

    
    span.error{
        font-size: 1.2rem;
        color:red;
        position: absolute;
    }

    .bg-color-two{
        background-color: ${(props) =>
          props.theme.colors.backgrounds.two} ;        
    }

    .modal{
        top: 5rem;
        
        &-title{
            font-size: 2.4rem ;
        }
        &-body{            
            padding: 3rem;
            @media (max-width: 424px){
                padding:0;
            }
        }
        &-dialog{
            /*min-width:75vw;           */
        }
    }

    video {
        width: 100%;
    }

    @media (max-width:376px){
        
        figure,img{
            max-width:350px !important;
            min-width:auto !important;
        }
    }

`;
