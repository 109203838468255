import { useState } from 'react';

import { Variant } from 'react-bootstrap/esm/types';

interface Alert {
  variant: Variant;
  message?: string;
  visible: boolean;
}

export function useAlert() {
  const [variant, setState] = useState<Alert>({
    variant: 'primary',
    visible: false
  });

  const danger = (message: string): void => {
    const newState = { ...variant };
    newState.message = message;
    newState.visible = true;
    newState.variant = 'danger';
    setState(newState);
  };

  const success = (message: string): void => {
    const newState = { ...variant };
    newState.message = message;
    newState.visible = true;
    newState.variant = 'success';
    setState(newState);
  };

  const clear = (): void => {
    const newState = { ...variant };
    newState.message = '';
    newState.visible = false;

    setState(newState);
  };

  return [variant, danger, success, clear] as const;
}
