import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';

import { useHistory, useLocation } from 'react-router-dom';

import {
  Container as ContainerBootstrap,
  Col,
  Row,
  Button
} from 'react-bootstrap';

import { Container } from './styles';

import logo from '../../assets/images/latest_images/logo.png';
import Menu from '../Menu';

const Header: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const pathname = location.pathname;

  const MOBILE_SIZE = 768;

  const logInOut = () => {
    if (logged()) {
      localStorage.removeItem('users');
      history.push('/generator');
    } else {
      history.push('/libraries');
    }
  };

  const isLoginPage = () => {
    return pathname.includes('login') || pathname.includes('forgot');
  };

  const logged = () => {
    return localStorage.getItem('users');
  };

  const handleOnClickPrice = () => {
    const element = document.getElementById('price');
    if (element != null)
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    else history.push('/price');
  };

  const isActive = (path: string) => {
    return pathname.includes(path) ? 'active' : '';
  };
  useEffect(() => {
    if (
      !logged() &&
      !pathname.includes('generator') &&
      !pathname.includes('login') &&
      !pathname.includes('register') &&
      !pathname.includes('price') &&
      !pathname.includes('features') &&
      !pathname.includes('faq') &&
      !pathname.includes('contact-us') &&
      !pathname.includes('terms-and-conditions') &&
      !pathname.includes('privacy-policy') &&
      !pathname.includes('sign-up') &&
      !pathname.includes('forgot') &&
      pathname !== '/'
    )
      history.push('/login');

    if (pathname.includes('generator') && logged()) history.push('/libraries');
  }, [location.pathname]);

  if (
    logged() &&
    (pathname.includes('login') ||
      pathname.includes('generator') ||
      pathname === '/')
  )
    history.push('/libraries');

  return (


    <nav className="navbar navbar-expand-lg navbar__top">
       <div className="container">
          <Link className="navbar-brand navbar___brand" to="/">
            <img src={logo} alt='logo' />
          </Link>

          <button className="navbar-toggler navbar__toggler hamburger__menu" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="menu"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
             <ul className="navbar-nav navbar__nav">
                <li className={isActive('features')}>
                  <Link to="features">Features</Link>
                </li>
                <li className={isActive('price')}>
                  <a onClick={handleOnClickPrice}>Pricing</a>
                </li>
                <li className={isActive('contact-us')}>
                  <Link to="contact-us">Contact Us</Link>
                </li>
             </ul>
          </div>
          <div className="nav__info__right">
             <div className="user__account__btn">
             { window.innerWidth > MOBILE_SIZE && (
              <>
                <a onClick={logInOut} className="login__btn">
                  {logged() ? 'Logout' : 'Log in'}
                </a>
                {!logged() && window.innerWidth > MOBILE_SIZE && (
                <Link to="sign-up">Sign Up</Link>
                 )}
              </>
              )}
             </div>
          </div>
       </div>
    </nav>



  
  );
};

export default Header;
