import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import { Alert, Button, Col, Row, Form } from 'react-bootstrap';
import styled from 'styled-components';

import { Container } from './styles';

import profile from '../../assets/images/profile.svg';
import useRegister from '../../hooks/useRegister';
import { useAlert } from '../../hooks/useAlert';
import Footer from '../../components/Footer';

const Banner = styled.h1`
  background-color: ${(props) => props.theme.colors.backgrounds.five};
  text-align: center;
  font-weight: 800;
  padding: 2.3rem 0;
  color: white;
  max-width: 1443px;
  margin: 2.4rem auto;
`;

const NewAccount: React.FC = () => {
  const [account, save] = useRegister();
  const [variant, danger, ,] = useAlert();

  const [profileImage, setProfileImage] = useState(profile);

  const [errors, setError] = useState({
    email: '',
    password: {
      match: '',
      size: ''
    }
  });

  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const confirmEmailRef = useRef<HTMLInputElement>(null);
  const companyNameRef = useRef<HTMLInputElement>(null);
  const webSiteNameRef = useRef<HTMLInputElement>(null);
  const pwdRef = useRef<HTMLInputElement>(null);
  const confirmPwdRef = useRef<HTMLInputElement>(null);
  const addressRef = useRef<HTMLTextAreaElement>(null);
  const zipRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);
  const countryRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleOnSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (errors.email || errors.password.match || errors.password.size) {
      return;
    }

    if (account.result) {
      account.result.firstName = firstNameRef.current?.value || '';
      account.result.lastName = lastNameRef.current?.value || '';

      account.result.email = emailRef.current?.value || '';
      account.result.confirmEmail = confirmEmailRef.current?.value || '';

      account.result.displayName = companyNameRef.current?.value || '';
      account.result.website = webSiteNameRef.current?.value || '';

      account.result.password = pwdRef.current?.value || '';
      account.result.repeatPassword = confirmPwdRef.current?.value || '';

      account.result.addressLine1 = addressRef.current?.value || '';
      account.result.zipCode = zipRef.current?.value || '';
      account.result.country = countryRef.current?.value || '';
      account.result.city = cityRef.current?.value || '';
      account.result.state = stateRef.current?.value || '';

      save();
    }
  };

  const handleOnChangePhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files;

    if (file) {
      if (file[0].size > 10485760 && file[0].type.includes('image')) {
        danger(
          'Error: Image file is too large. The maximum file size for image upload is 10MB.'
        );
        return;
      }

      if (file[0].size > 157286400 && file[0].type.includes('video')) {
        danger(
          'Error: Video file is too large. The maximum file size for video upload is 150MB.'
        );
        return;
      }

      setProfileImage(URL.createObjectURL(file[0]));
    }
  };

  const handleOnEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const email = emailRef.current?.value;
    const repeatEmail = confirmEmailRef.current?.value;

    if (email && repeatEmail) {
      const newErrors = { ...errors };
      newErrors.email = '';

      if (email !== repeatEmail) {
        newErrors.email = 'Confirm Email do not match';
      } else {
        const reg = new RegExp('^\\S+@\\S+$');

        if (!reg.test(email)) {
          newErrors.email = 'The email address you entered is incorrect';
        }
      }
      setError(newErrors);
    }
  };

  const handleOnPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const pwd = pwdRef.current?.value;
    const repeatPwd = confirmPwdRef.current?.value;

    if (pwd) {
      const newErrors = { ...errors };
      newErrors.password.match = '';
      newErrors.password.size = '';
      if (pwd !== repeatPwd) {
        newErrors.password.match = 'Passwords do not match';
      }
      if (pwd.length < 8) {
        newErrors.password.size =
          'Password should be at least 8 characters long';
      }

      setError(newErrors);
    }
  };

  useEffect(() => {
    if (account.status === 'fail') danger(account.err_msg || 'API Error');
  }, [account.status]);

  if (account.status === 'success') {
    return (
      <div className='container font-primary large'>
        Thanks for registering with Reel Code Media. An activation email has
        been sent to <em>{account.result?.email}</em>.
      </div>
    );
  }

  const handleOnClickAvatar = () => {
    inputRef.current?.click();
  };

  return (
    <>
      <div className='container'>
        <h2 className='font-primary'>CREATE AN ACCOUNT</h2>
      </div>
      <Banner className='font-primary'>
        Sign up for RCM 30-day free trial | No commitments, cancel anytime.
      </Banner>
      {variant.message && (
        <div className='container'>
          <Alert variant={variant.variant}>{variant.message}</Alert>
        </div>
      )}

      <Container onSubmit={handleOnSubmit}>
        <Col md='4' sm={12} className='wrapper-profile mb-3 p-0'>
          <div className='wrapper-image mb-3'>
            <img alt='avatar' src={profileImage} className='mb-3'></img>
          </div>
          <Row className='mb-5'>
            <Col>
              <Button
                variant='dark'
                className='font-secundary w-100'
                onClick={handleOnClickAvatar}
              >
                UPLOAD AVATAR
              </Button>
              <input
                ref={inputRef}
                style={{ display: 'none' }}
                type='file'
                accept='image/png, image/jpeg'
                onChange={handleOnChangePhoto}
              />
            </Col>
          </Row>
        </Col>
        <Col md='10' sm='12'>
          <Form.Group as={Row} className='mb-5'>
            <Form.Label column sm='2' className='pr-0' htmlFor='fisrtName'>
              FIRST NAME
            </Form.Label>
            <Col sm='4' className='pr-0'>
              <Form.Control id='fisrtName' ref={firstNameRef} required />
            </Col>
            <Form.Label
              column
              sm='2'
              className='pr-0 text-right'
              htmlFor='lasName'
            >
              LAST NAME
            </Form.Label>
            <Col sm='4' className='pr-0 '>
              <Form.Control id='lastName' ref={lastNameRef} required />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-5'>
            <Form.Label column sm='2' className='pr-0' htmlFor='email'>
              E-MAIL
            </Form.Label>
            <Col sm='4' className='pr-0'>
              <Form.Control
                id='email'
                autoComplete='off'
                type='email'
                required
                ref={emailRef}
                onChange={handleOnEmailChange}
              />
              {errors.email && <span className='error'>{errors.email}</span>}
            </Col>
            <Form.Label
              column
              sm='2'
              className='pr-0 text-right'
              htmlFor='repeatEmail'
            >
              E-MAIL
            </Form.Label>
            <Col sm='4' className='pr-0'>
              <Form.Control
                id='repeatEmail'
                required
                placeholder='Confirm email'
                autoComplete='off'
                onChange={handleOnEmailChange}
                ref={confirmEmailRef}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-5'>
            <Form.Label column sm='2' className='pr-0' htmlFor='companyName'>
              COMPANY NAME
            </Form.Label>
            <Col sm='4' className='pr-0'>
              <Form.Control id='companyName' ref={companyNameRef} required />
            </Col>

            <Form.Label
              column
              sm='2'
              className='pr-0 text-right'
              htmlFor='website'
            >
              WEBSITE
            </Form.Label>
            <Col sm='4' className='pr-0'>
              <Form.Control id='website' ref={webSiteNameRef} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-5 p-0'>
            <Form.Label column sm='2' className='pr-0' htmlFor='password'>
              PASSWORD
            </Form.Label>
            <Col sm='4' className='pr-0'>
              <Form.Control
                id='password'
                ref={pwdRef}
                required
                type='password'
                onChange={handleOnPasswordChange}
              />
              {errors.password.size && (
                <span className='error'>{errors.password.size}</span>
              )}
            </Col>

            <Form.Label
              column
              sm='2'
              className='pr-0 text-right'
              htmlFor='repeatPassword'
            >
              REPEAT PASSWORD
            </Form.Label>
            <Col sm='4' className='pr-0' style={{ alignSelf: 'center' }}>
              <Form.Control
                id='repeatPassword'
                ref={confirmPwdRef}
                required
                onChange={handleOnPasswordChange}
                type='password'
              />
              {errors.password.match && confirmPwdRef.current?.value && (
                <span className='error'>{errors.password.match}</span>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-8 pr-0'>
            <Col sm='6' className='d-flex pr-0'>
              <Form.Label column sm='4' htmlFor='address' className='pr-0 pl-0'>
                ADDRESS
              </Form.Label>
              <Col className='pr-0 pl-2 d-inline-block' sm='8'>
                <Form.Control id='address' as='textarea' ref={addressRef} />
              </Col>
            </Col>
            <Col sm='6'>
              <Row className='mb-5'>
                <Form.Label column md='4' htmlFor='city' className='text-right'>
                  CITY
                </Form.Label>
                <Col md='3' className='pr-0'>
                  <Form.Control id='city' ref={cityRef} />
                </Col>

                <Form.Label column md='2' htmlFor='zip' className='text-right'>
                  ZIP
                </Form.Label>
                <Col md='3' className='pr-0'>
                  <Form.Control id='zip' ref={zipRef} />
                </Col>
              </Row>
              <Row>
                <Form.Label
                  column
                  md='4'
                  htmlFor='state'
                  className='text-right'
                >
                  STATE
                </Form.Label>
                <Col md='3' className='pr-0'>
                  <Form.Control id='state' ref={stateRef} required />
                </Col>

                <Form.Label column md='2' htmlFor='country'>
                  COUNTRY
                </Form.Label>
                <Col md='3' className='pr-0'>
                  <Form.Control id='country' ref={countryRef} required />
                </Col>
              </Row>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Col className='pr-0 d-flex justify-content-end button-footer-group'>
              <Button
                as={Link}
                to='/'
                className='font-secundary mr-4'
                variant='dark'
              >
                CANCEL
              </Button>

              <Button className='font-secundary mr-4' variant='dark'>
                RESET
              </Button>

              <Button variant='dark' type='submit' className='font-secundary'>
                SUBMIT
              </Button>
            </Col>
          </Form.Group>
        </Col>
      </Container>
      <Footer />
    </>
  );
};

export default NewAccount;
