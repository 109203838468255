import React from 'react';
import { Container } from './styles';

interface TitleProps {
  className?: 'font-secundary large' | string;
}

const Title: React.FC<TitleProps> = (props:any) => {
  const { className } = props;

  return <Container className={className}>{props.children} </Container>;
};

export default Title;
