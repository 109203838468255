import styled from 'styled-components';
import { Container as ContainerBootstrap } from 'react-bootstrap';

export const Container = styled(ContainerBootstrap)`
  opacity: 95%;
  border-radius: var(--radius);
  background-color: ${(props) => props.theme.colors.background};

  position: absolute;
  padding: 1rem;
  z-index: 9999;

  width: 40%!important;
  top: 50%;
  left:8%;
  height: 100%;
  margin-top: calc(var(--padding-large) * -1);
  transition: 1s ease-in-out;
  max-height: 56rem;

  @media (max-width: 769px) {
    max-height: 95%;
  }

  &.hide {
    opacity: 0;
    z-index: -1;
    height: 0;
  }

  .icons-list {
    overflow-y: auto;
    max-height: 50rem;
    img {
      width: 5rem;
      height: 5rem;
      margin: 3.5rem;
      cursor: pointer;
    }
  }

  .button {
    &-close {
      overflow: hidden;
      button {
        float: right;
        border: none;
        clear: right;
      }
    }

    &-apply {
      position: absolute;
      right: 2rem;
      bottom: 2rem;
      @media (max-width: 769px) {
        bottom: -5rem;
      }
    }
  }
`;
