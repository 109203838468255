import styled from 'styled-components';

import { Container as ContBootstrap } from 'react-bootstrap';

export const Container = styled(ContBootstrap)`
  margin-bottom: 4.8rem;
  cursor: pointer;

  .font-secundary {
    font-size: 1.6rem;
    @media (max-width: 769px) {
      font-size: 1.2rem;
    }
    font-weight: 700;
    text-transform: capitalize;
  }

  .children {
    display: flex;

    img {
      align-self: center;
      width: 4.3rem;
      height: 4.3rem;
      cursor: pointer;
      transition: 0.3s;

      &:nth-of-type(2) {
        margin: 0 0.2rem;
      }

      &.selected {
        border: 0.3rem solid ${(props) => props.theme.colors.primary};
        border-radius: 0.7rem;
        box-sizing: content-box;
      }
    }
  }

  &:nth-child(5) {
    margin-bottom: 0;
  }

  span {
    cursor: pointer;
    color: ${(props) => props.theme.colors.background};

    align-self: center;
  }

  .step {
    position: relative;
    border-radius: var(--radius);

    display: flex;
    justify-content: space-between;
    min-height: 7.6rem;
    max-height: 7.6rem;
  }

  .box {
    position: absolute;
    top: 0;
    right: -2rem;
    width: 7.8rem;
    height: 4.5rem;
    background: white;
    border-radius: 0.5rem;
    z-index: 1000;
    opacity: 0;
    transition: 0.5s ease-in-out;

    &.show {
      opacity: 1;
    }
  }

  .hover-tip {
    .tip {
      display: none;
    }
    i:hover + .box {
      opacity: 1;
      .tip {
        display: block;
      }
    }
  }

  .tip {
    margin-left: 7.8rem;
    padding: 2rem;
    font-size: 1.4rem;
    font-style: italic;
    width: 25rem;
    text-align: justify;
    background: white;
    border-radius: 0.5rem;
    z-index: 3;
    transition: 1s ease-in-out;
  }

  i {
    align-self: flex-start;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.background};
    border: none;
    position: relative;
    z-index: 1001;
    width: 2.3rem;
    text-align: center;
    font-weight: bold;
    margin-top: 1rem;
  }

  &:nth-child(2) {
    .border {
      border-color: #e01484d6 !important;
      @media (max-width: 768px) {
        border-width: 0.5rem !important;
        box-shadow: inset 0 0 0 1rem
          ${(props) => props.theme.colors.backgrounds.one};
      }
    }
    .step {
      background-color: ${(props) => props.theme.colors.backgrounds.one};

      i {
        color: ${(props) => props.theme.colors.backgrounds.one};
        &.show {
          background-color: ${(props) => props.theme.colors.backgrounds.one};
          color: ${(props) => props.theme.colors.backgrounds.six};
        }
        @media (max-width: 769px) {
          &.show {
            color: ${(props) => props.theme.colors.backgrounds.one};
            background-color: ${(props) => props.theme.colors.backgrounds.six};
          }
        }
      }
    }
  }

  &:nth-child(3) {
    .step {
      background-color: ${(props) => props.theme.colors.backgrounds.two};
      i {
        color: ${(props) => props.theme.colors.backgrounds.two};
        &.show {
          background-color: ${(props) => props.theme.colors.backgrounds.two};
          color: ${(props) => props.theme.colors.backgrounds.six};
        }
      }
    }
  }

  &:nth-child(4) {
    .border {
      border-color: ${(props) =>
        props.theme.colors.backgrounds.three} !important;
    }
    .step {
      span {
        width: 26.5rem;
      }
      background-color: ${(props) => props.theme.colors.backgrounds.three};
      i {
        color: ${(props) => props.theme.colors.backgrounds.three};
        &.show {
          background-color: ${(props) => props.theme.colors.backgrounds.three};
          color: ${(props) => props.theme.colors.backgrounds.six};
        }
      }
    }
  }
  &:nth-child(5) {
    .border {
      border-color: ${(props) =>
        props.theme.colors.backgrounds.four} !important;
    }
    .step {
      background-color: ${(props) => props.theme.colors.backgrounds.four};
      i {
        color: ${(props) => props.theme.colors.backgrounds.four};
        &.show {
          background-color: ${(props) => props.theme.colors.backgrounds.four};
          color: ${(props) => props.theme.colors.backgrounds.six};
        }
      }
    }

    .tip {
      margin-top: -5.8rem;
    }
  }

  .tip-mobile {
    display: none;
  }
  @media (max-width: 769px) {
    font-size: 1.3rem;
    margin-bottom: 1.9rem;
    font-family: var(--font-fourth);
    .tip-mobile {
      &.show {
        display: block;
      }

      padding: 1.2rem 1rem;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      font-size: 1.2rem;
      font-style: italic;
    }
  }

  .border {
    border: 0.3rem solid !important;
    padding: 0.7rem;
    border-radius: 1rem;
    @media (max-width: 780px) {
      border: inherit;
      padding: 0;
    }
  }

  @media (max-width: 780px) {
    i {
      display: none;
    }
  }
`;
