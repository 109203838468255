import {
  SubscriptionOptionsResult,
  UserSubscriptionResult
} from '../interfaces';
import { get } from './api';

export async function getSubscription() {
  const result = await get<UserSubscriptionResult>(`/users/subscription`);
  return result.data;
}

export async function getShow() {
  const result = await get<SubscriptionOptionsResult>(`/subscription/show`);
  return result.data;
}
