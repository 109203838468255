import React, { useState, useEffect, useRef } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { Container } from './styles';

import { Alert, Col, Row, Button } from 'react-bootstrap';

import Menu from '../Menu';

import useGenerator, { defaultStep } from '../../hooks/useGenerator';
import useFolders from '../../hooks/useFolders';
import { useAlert } from '../../hooks/useAlert';
import { Frame as FrameInterface, ReelGenerator } from '../../interfaces';

import StepUI from '../../components/Step';
import Preview from './Preview';
import Title from '../../components/Title';

import ContentType from './ContentType';
import CustomIcons from './CustomIcons';

import {
  findFrame,
  createNewFrame,
  updateCornerValues,
  saveFrameName,
  downloadFrame,
  changeLayout,
  changeImage
} from '../../service/frame';

import layout1 from '../../assets/icons/layout1.svg';
import layout2 from '../../assets/icons/layout2.svg';
import layout3 from '../../assets/icons/layout3.svg';

import { findById } from '../../service/icon';
import Footer from '../../components/Footer';
import RcmModal from '../../components/Modal';

import { changeFrameIndustry } from '../../service/industry';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownload } from '@fortawesome/free-solid-svg-icons';

const CreativeStudio: React.FC = () => {
  const { stepsLogged, stepsEdit } = useGenerator();
  const [variant, danger, success, clear] = useAlert();
  const [created, setCreated] = useState(false);
  const [logged, setLogged] = useState(true);
  const [errorSizeImage, setErrorSizeImage] = useState(false);

  const [showFolders, setShowFolders] = useState(false);
  const [saveAfterChooseFolder, setSaveAfterChooseFolder] = useState(false);
  const [folders] = useFolders();
  const [folderId, setFolderId] = useState(0);
  const [newImage, setNewImage] = useState(false);

  const [reelGenerator, setStep] = useState<ReelGenerator>(defaultStep);

  const [currentId, setCurrentId] = useState('');
  const [step1, step2, step3, step4] = reelGenerator.steps;
  const { title, currentStep } = reelGenerator;

  const inputRef = useRef<HTMLInputElement>(null);
  const frameNameRef = useRef<HTMLInputElement>(null);

  const imageRef1 = useRef<HTMLImageElement>(null);
  const imageRef2 = useRef<HTMLImageElement>(null);
  const imageRef3 = useRef<HTMLImageElement>(null);

  if (reelGenerator.steps.length !== 4) {
    const newState = { ...reelGenerator };
    newState.steps.pop();
    stepsLogged.forEach((s) => (s.hoverTip = true));
    newState.steps = stepsLogged;
    setStep(newState);
  }

  let { id } = useParams<{ id?: string }>();

  //isEditing
  if (id && id !== currentId) {
    setCurrentId(id);
  }

  //wasEditing
  if (currentId && !id) {
    setCurrentId('');
    setStep({ ...defaultStep });
  }

  reelGenerator.contentType.apply = (event: React.FormEvent<Element>) => {
    event.preventDefault();
    if (
      reelGenerator.contentType.fields.length !== 4 ||
      reelGenerator.contentType.fields.filter((f) => !f.value).length !== 0
    ) {
      danger('Please fill all fields');
      return;
    }

    reelGenerator.contentType.show = false;

    handleOnNext();
  };

  reelGenerator.contentType.close = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const newState = { ...reelGenerator };
    newState.contentType.show = false;
    setStep(newState);
  };

  reelGenerator.icons.apply = (event: React.FormEvent<Element>) => {
    event.preventDefault();

    if (reelGenerator.icons.corners.filter((c) => !c.icon).length > 0) {
      danger('Please fill all corners');
      return false;
    }

    reelGenerator.icons.show = false;
    setStep({ ...reelGenerator });
  };

  reelGenerator.icons.close = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const newState = { ...reelGenerator };
    newState.icons.show = false;
    setStep(newState);
  };

  const setStepByIndex = (index: number) => {
    if (currentStep === index) return;

    const newStep = { ...reelGenerator };
    newStep.currentStep = index;
    newStep.steps.forEach((s) => (s.currentId = index));
    if (index === 3) newStep.icons.show = true;
    if (index === 4) newStep.contentType.show = true;
    setStep(newStep);
  };

  const handleOnSave = async () => {
    save();
  };

  const handleOnSaveAndDownload = () => {
    save(true);
  };

  const save = async (download = false) => {
    if (currentId) {
      const id = Number(currentId);

      if (
        frameNameRef.current?.value &&
        frameNameRef.current.value.length > 1
      ) {
        reelGenerator.name = frameNameRef.current.value;
      }

      if (newImage) await changeImage(id, reelGenerator);
      else if (reelGenerator.layout !== reelGenerator.oldLayout) {
        await changeLayout(
          id,
          reelGenerator.layout || 'layout1',
          reelGenerator.galleryId
        );
      }

      await updateCornerValues(reelGenerator, id, download);

      if (folderId) {
        await changeFrameIndustry(id, folderId);
      }

      if (download) downloadFrameById(id);
      setCreated(true);
    } else {
      if (!folderId) {
        setShowFolders(true);
        setSaveAfterChooseFolder(true);
        return;
      }

      const result = await createNewFrame(reelGenerator, download);

      if (result?.status === 'success') {
        const frameName = result.result as FrameInterface;
        if (
          frameNameRef.current?.value &&
          frameNameRef.current.value.length > 1
        ) {
          reelGenerator.name = frameNameRef.current.value;
          frameName.name = reelGenerator.name;
          await saveFrameName(frameName);
        }

        if (folderId) {
          await changeFrameIndustry(frameName.id, folderId);
        }

        if (download) {
          downloadFrameById(frameName.id);
        }

        setCreated(true);
      } else {
        result?.err_msg ? danger(result.err_msg) : danger('Error');
      }
    }
  };

  const handleOnChangePhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files;
    if (file) {
      if (file[0].size > 10485760 && file[0].type.includes('image')) {
        danger(
          'Error: Image file is too large. The maximum file size for image upload is 10MB.'
        );
        return;
      }

      if (file[0].size > 157286400 && file[0].type.includes('video')) {
        danger(
          'Error: Video file is too large. The maximum file size for video upload is 150MB.'
        );
        return;
      }

      const newStep = { ...reelGenerator };
      newStep.image.path = URL.createObjectURL(file[0]);
      newStep.image.file = file[0];
      newStep.image.name = file[0].name;
      newStep.image.type = file[0].type;

      if (file[0].type.includes('image')) {
        const image = new Image();
        image.src = newStep.image.path;

        image.onload = () => {
          if (image.width < 600 || image.height < 600) {
            danger('Error: Image must be at least 600px wide and 600px tall.');
            setErrorSizeImage(true);
          }
        };
      }

      newStep.layout = 'layout3';
      imageRef3.current?.classList.add('selected');
      newStep.currentStep = 2;
      setNewImage(true);
      handleOnNext();
    }
  };

  const handleOnLayoutClick = (event: React.MouseEvent<HTMLElement>) => {
    if (currentStep !== 2) return;

    const newStep = { ...reelGenerator };
    newStep.layout = event.currentTarget.id;

    imageRef1.current?.classList.remove('selected');
    imageRef2.current?.classList.remove('selected');
    imageRef3.current?.classList.remove('selected');

    if (newStep.layout === 'layout1') {
      imageRef1.current?.classList.add('selected');
    }

    if (newStep.layout === 'layout2') {
      imageRef2.current?.classList.add('selected');
    }

    if (newStep.layout === 'layout3') {
      imageRef3.current?.classList.add('selected');
    }

    setStep(newStep);
  };

  const handleOnNext = () => {
    if (errorSizeImage) return;

    if (!reelGenerator.image.file && !currentId) {
      danger('Please select an image first');
      return;
    }

    if (reelGenerator.currentStep === 2 && !reelGenerator.layout) {
      danger('Please select one layout');
      return;
    }

    if (reelGenerator.contentType.show || reelGenerator.icons.show) {
      return;
    }

    const newStep = { ...reelGenerator };

    if (
      currentStep === 3 &&
      reelGenerator.icons.corners.filter((c) => !c.icon).length > 0
    ) {
      newStep.icons.show = true;
      setStep(newStep);
      return;
    }

    if (
      currentStep === 4 &&
      (reelGenerator.contentType.fields.length === 0 ||
        reelGenerator.contentType.fields.filter((f) => !f.value).length > 0)
    ) {
      newStep.contentType.show = true;
      setStep(newStep);
      return;
    }

    if (variant.message) clear();

    if (currentStep < 5) newStep.currentStep++;
    newStep.steps.forEach((s) => (s.currentId = newStep.currentStep));

    setStep(newStep);
  };

  const handleOnStep1Click = () => {
    if (currentId && currentStep !== 1) {
      setStepByIndex(1);
      inputRef.current?.click();
    } else if (!currentId && currentStep === 1) {
      //new frame
      inputRef.current?.click();
    }
  };

  const handleOnStep2Click = () => {
    if (currentId) setStepByIndex(2);
  };

  const handleOnStep3Click = () => {
    if (currentStep === 3 && !reelGenerator.icons.show) {
      const newStep = { ...reelGenerator };
      newStep.icons.show = true;
      setStep(newStep);
    } else if (currentId) setStepByIndex(3);
  };

  const handleOnStep4Click = () => {
    if (currentStep === 4 && !reelGenerator.contentType.show) {
      const newStep = { ...reelGenerator };
      newStep.contentType.show = true;
      setStep(newStep);
    } else if (currentId) setStepByIndex(4);
  };

  const handleOnBack = () => {
    if (currentStep === 0) return;
    if (reelGenerator.contentType.show) return;

    const newStep = { ...reelGenerator };
    newStep.currentStep--;
    newStep.steps.forEach((s) => (s.currentId = newStep.currentStep));
    if (newStep.currentStep === 1) {
      newStep.image.path = '';
      newStep.image.file = undefined;
      newStep.image.name = '';
      newStep.image.type = '';
    }
    setStep(newStep);
  };

  const downloadFrameById = async (id: number) => {
    const file = await downloadFrame(id);
    if (file.data.status === 'success') {
      const url = file.data.result.downloadUrl.replaceAll(' ', '%20');

      fetch(`${window.location.origin}${url}`)
        .then((res) => {
          return res.blob();
        })
        .then((data) => {
          var a = document.createElement('a');
          a.href = window.URL.createObjectURL(data);
          a.download = url;
          a.click();
        });
    }
  };

  useEffect(() => {
    async function fetchData() {
      const result = await findFrame(currentId);

      //redirect
      if (!result || !result.status) {
        setCreated(true);
        return;
      }

      if (result.status === 'success') {
        const data = result.result;
        const newReel = { ...reelGenerator };

        newReel.title = 'EDIT YOUR REEL CODE MEDIA';
        newReel.indsutryId = data.industryId;
        newReel.name = data.name;

        newReel.image.path = `${window.location.origin}/${data.imageUrl}`;
        const splittedName = data.imageUrl.split('/');
        newReel.image.name = splittedName[splittedName.length - 1];

        newReel.layout =
          data.idFrameType === 0
            ? 'layout3'
            : data.idFrameType === 1
            ? 'layout1'
            : 'layout2';

        if (newReel.layout === 'layout1') {
          imageRef1.current?.classList.add('selected');
        }

        if (newReel.layout === 'layout2') {
          imageRef2.current?.classList.add('selected');
        }

        if (newReel.layout === 'layout3') {
          imageRef3.current?.classList.add('selected');
        }

        newReel.oldLayout = newReel.layout;
        newReel.galleryId = data.galleryId;

        newReel.steps = stepsEdit;
        newReel.currentStep = 3;
        newReel.steps.forEach((e) => (e.currentId = 3));

        newReel.contentType.fields = [];

        data.corners?.sort((a, b) => a.cornerTypeId - b.cornerTypeId);

        data.corners?.forEach((c, index) => {
          newReel.icons.corners[index].icon = findById(c.iconId);
          newReel.icons.corners[index].iconId = c.iconId;
          newReel.contentType.fields.push({
            contentTypeId: c.contentTypeId,
            value: c.contentValue,
            iconId: c.iconId,
            cornerId: c.id
          });
        });

        setStep(newReel);
      } else {
        danger(result.err_msg || result.err_message || 'Undefined Error');
        setLogged(false);
      }
    }

    if (currentId) {
      try {
        fetchData();
      } catch (e) {
        console.error(e);
      }
    }
  }, [currentId]);

  useEffect(() => {
    return function cleanup() {
      reelGenerator.image.path = '';
      reelGenerator.currentStep = 1;
      reelGenerator.contentType.fields.forEach((e) => {
        e.value = '';
        e.iconId = 0;
        e.contentTypeId = 0;
      });

      reelGenerator.icons.corners.forEach((e) => {
        e.icon = '';
        e.iconId = 0;
        e.contentTypeId = 1;
      });

      reelGenerator.steps.forEach((e) => {
        e.currentId = 1;
      });
    };
  }, []);

  useEffect(() => {
    if (!errorSizeImage) return;
    const newStep = { ...reelGenerator };

    newStep.image.path = '';

    newStep.contentType.fields.forEach((e) => {
      e.contentTypeId = 0;
      e.value = '';
      e.iconId = 0;
    });

    newStep.steps.forEach((e) => {
      e.currentId = 1;
    });
    newStep.icons.corners.forEach((e) => {
      e.icon = '';
    });
    newStep.currentStep = 1;

    setErrorSizeImage(false);
    setStep(newStep);
  }, [errorSizeImage]);

  useEffect(() => {
    if (!showFolders && saveAfterChooseFolder) {
      save();
      setSaveAfterChooseFolder(false);
    }
  }, [showFolders]);

  if (created) {
    return <Redirect to='/libraries' />;
  }

  if (!logged) return <Redirect to='/' />;

  const handleOnShowFolders = () => {
    setShowFolders(true);
  };

  const handleOnFolderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.currentTarget.id;

    folders.forEach((f) => {
      if (`input-${f.id}` !== id) {
        const input = document.getElementById(`input-${f.id}`);
        if (input instanceof HTMLInputElement) input.checked = false;
      }
    });

    setFolderId(Number(id.replace('input-', '')));
  };

  return (
    <>
      <Container>
        {variant.visible && (
          <Alert className='mb-4' variant={variant.variant}>
            {variant.message}
          </Alert>
        )}
        <RcmModal
          show={showFolders}
          onHide={() => setShowFolders(false)}
          title='Folders'
          buttonLabel='Select'
          message='Select a folder to save your reel code media'
        >
          <Row>
            <Col className='folder'>
              {folders.map(({ id, description }) => (
                <div key={id} className='folder-modal'>
                  <input
                    className='folder-modal-input'
                    type='checkbox'
                    id={`input-${id}`}
                    onChange={handleOnFolderChange}
                  />
                  <label className='folder-modal-label' htmlFor={`input-${id}`}>
                    {description}
                  </label>
                </div>
              ))}
            </Col>
          </Row>
        </RcmModal>
        <Row>
          <Menu />
          <Col md={8} className='wrapper' sm={12}>
            <Col className='container-steps' md={8}>
              <div>
                <Title>{title}</Title>
              </div>
              <Container fluid>
                <StepUI {...step1} onClick={handleOnStep1Click}>
                  <input
                    ref={inputRef}
                    style={{ display: 'none' }}
                    type='file'
                    accept='image/png, image/jpeg, video/mp4,video/x-m4v,video/*'
                    onChange={handleOnChangePhoto}
                  />
                </StepUI>

                <StepUI {...step2} onClick={handleOnStep2Click}>
                  <img
                    id='layout1'
                    src={layout1}
                    ref={imageRef1}
                    alt='vertical'
                    onClick={handleOnLayoutClick}
                  ></img>
                  <img
                    id='layout2'
                    src={layout2}
                    ref={imageRef2}
                    alt='horizontal'
                    onClick={handleOnLayoutClick}
                  ></img>
                  <img
                    id='layout3'
                    src={layout3}
                    ref={imageRef3}
                    alt='veritacal and horizontal'
                    onClick={handleOnLayoutClick}
                  ></img>
                </StepUI>
                <StepUI {...step3} onClick={handleOnStep3Click} />
                <StepUI {...step4} onClick={handleOnStep4Click} />
                <CustomIcons {...reelGenerator.icons} />
                <ContentType {...reelGenerator} />
              </Container>
            </Col>
            <Col sm={12} md={4} className='container-preview '>
              <Container fluid>
                <Preview {...reelGenerator}>
                  {currentStep > 0 && currentStep <= 4 && (
                    <Row className='navigation'>
                      <Col sm={12} md={6}>
                        {currentStep > 1 && (
                          <Button
                            variant='primary'
                            className='font-secundary large back'
                            onClick={handleOnBack}
                          >
                            Back
                          </Button>
                        )}
                      </Col>
                      <Col sm={12} md={6}>
                        <Button
                          variant='primary'
                          className='font-secundary large next'
                          onClick={handleOnNext}
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  )}

                  {currentId && currentStep >= 2 && currentStep <= 4 && (
                    <Row className='navigation'>
                      <Col sm={12} md={4}>
                        <Button
                          variant='primary'
                          className='font-secundary large '
                          onClick={handleOnSave}
                        >
                          Save
                        </Button>
                      </Col>
                      <Col sm={12} md={4} style={{ textAlign: 'right' }}>
                        <Button
                          variant='dark'
                          className='font-secundary large btn-dark-2'
                          onClick={handleOnSaveAndDownload}
                          title='Download'
                        >
                          <FontAwesomeIcon icon={faCloudDownload} />
                        </Button>
                      </Col>
                    </Row>
                  )}

                  {currentStep === 5 && (
                    <Row style={{ height: 'auto' }}>
                      <Col className='input-name-frame mt-4 mb-4'>
                        <input
                          placeholder='NAME YOUR RCM FRAME'
                          ref={frameNameRef}
                        ></input>
                        <span onClick={handleOnShowFolders}>
                          <svg>
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M0 7.72354C0 7.10335 0.521038 6.60001 1.16303 6.60001L11.0195 6.60001L7.17823 2.90434C6.72077 2.46541 6.72387 1.75534 7.17513 1.31641C7.62793 0.877488 8.36452 0.874493 8.82043 1.31342L14.6573 6.92808C14.6945 6.96479 14.7289 7.00275 14.7606 7.04283L14.6573 6.92808C14.7192 6.98705 14.7734 7.05218 14.8193 7.12198C14.8289 7.139 14.8392 7.1556 14.849 7.17248C14.8693 7.20445 14.8869 7.23843 14.9026 7.27319C14.9056 7.28296 14.9095 7.29195 14.9133 7.30101C14.9294 7.3358 14.9425 7.37215 14.9536 7.4091C14.9591 7.43202 14.9652 7.45481 14.9705 7.47786C14.9784 7.50599 14.9838 7.53428 14.9881 7.56279C14.99 7.58572 14.9928 7.60968 14.9948 7.63384C14.9988 7.66402 15 7.69373 15 7.72354L14.9924 7.81193L14.9878 7.87593C14.9874 7.87898 14.987 7.88203 14.9865 7.88507L15 7.72354C15 7.8067 14.9905 7.88904 14.9719 7.9691C14.9652 7.99228 14.9591 8.01507 14.9524 8.03757C14.9425 8.07493 14.9294 8.11128 14.9144 8.14688C14.9095 8.15513 14.9056 8.16412 14.9015 8.17306C14.8869 8.20865 14.8693 8.24264 14.85 8.27571C14.8392 8.29148 14.8289 8.30808 14.8182 8.3244C14.8013 8.35251 14.782 8.37919 14.7614 8.40507C14.7256 8.44857 14.6872 8.4903 14.6459 8.5291L8.82043 14.1337C8.36452 14.5726 7.62793 14.5696 7.17513 14.1307C6.72387 13.6902 6.72077 12.9802 7.17823 12.5427L11.0195 8.84707H1.16303C0.521038 8.84707 0 8.34373 0 7.72354Z'
                              fill='#31A6DE'
                            />
                          </svg>
                        </span>
                      </Col>
                    </Row>
                  )}
                </Preview>
                {currentStep === 5 && (
                  <Row className='navigation'>
                    <Col
                      sm={12}
                      md={4}
                      style={{ textAlign: 'left', width: '100%' }}
                    >
                      <Button
                        variant='primary'
                        className='font-secundary large '
                        onClick={handleOnBack}
                      >
                        Back
                      </Button>
                    </Col>
                    <Col
                      sm={12}
                      md={4}
                      style={{ textAlign: 'left', width: '100%' }}
                    >
                      <Button
                        variant='primary'
                        className='font-secundary large '
                        onClick={handleOnSave}
                      >
                        Save
                      </Button>
                    </Col>
                    <Col sm={12} md={4} style={{ textAlign: 'right' }}>
                      <Button
                        variant='dark'
                        className='font-secundary large btn-dark-2'
                        onClick={handleOnSaveAndDownload}
                        title='Download'
                      >
                        <FontAwesomeIcon icon={faCloudDownload} />
                      </Button>
                    </Col>
                  </Row>
                )}
              </Container>
            </Col>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default CreativeStudio;
