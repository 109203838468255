const Icons = {
  twitter: {
    image: 'twitter',
    ids: { bottomLeft: 209, bottomRight:210, topLeft:211, topRight:212 }
  },

  instagram: {
    image: 'instagram',
    ids: { bottomLeft: 201, bottomRight:202, topLeft:203, topRight:204 }
  },

  portfolio:{
    image: 'portfolio',
    ids: { bottomLeft: 197, bottomRight:198, topLeft:199, topRight:200 }
  },
  
  msm:{
    image: 'message',
    ids: { bottomLeft: 193, bottomRight:194, topLeft:195, topRight:196 }
  }, 

  information:{
    image: 'information',
    ids: { bottomLeft: 189, bottomRight:190, topLeft:191, topRight:192 }
  },

  email:{
    image: 'email',
    ids: { bottomLeft: 213, bottomRight:214, topLeft:215, topRight:216 }
  },

  youtube:{
    image: 'youtube',
    ids: { bottomLeft: 217, bottomRight:218, topLeft:219, topRight:220 }
  },

  wifi:{
    image: 'wifi',
    ids: { bottomLeft: 221, bottomRight:222, topLeft:223, topRight:224 }
  },

  calendar:{
    image: 'calendar',
    ids: { bottomLeft: 86, bottomRight:34, topLeft:8, topRight:60 }
  },

  music:{
    image: 'music',
    ids: { bottomLeft: 99, bottomRight:47, topLeft:21, topRight:73 }
  },

  menu: {
    image: 'menu',
    ids: { bottomLeft: 173, bottomRight:135, topLeft:116, topRight:154 }
  },

  announcement: {
    image: 'announcement',
    ids: { bottomLeft: 90, bottomRight:38, topLeft:12, topRight:64 }
  },

  coupons: {
    image: 'coupons',
    ids: { bottomLeft: 165, bottomRight:127, topLeft:108, topRight:146 }
  },

  location: {
    image: 'location',
    ids: { bottomLeft: 170, bottomRight:132, topLeft:113, topRight:151 }
  },
  
  photo: {
    image: 'photo',
    ids: { bottomLeft: 174, bottomRight:136, topLeft:117, topRight:155 }
  },

  calling: {
    image: 'calling',
    ids: { bottomLeft: 87, bottomRight:35, topLeft:9, topRight:61 }
  },

  linkedin: {
    image: 'linkedin',
    ids: { bottomLeft: 205, bottomRight:206, topLeft:207, topRight:208 }
  },

  corner: {
    image: 'corner',
    ids: { bottomLeft: 79, bottomRight:27, topLeft:1, topRight:53 }
  },

  sale: {
    image: 'sale',
    ids: { bottomLeft: 179, bottomRight:141, topLeft:122, topRight:160 }
  },

  shop: {
    image: 'shop',
    ids: { bottomLeft: 102, bottomRight:50, topLeft:24, topRight:76 }
  },

  mapping: {
    image: 'mapping',
    ids: { bottomLeft: 98, bottomRight:46, topLeft:72, topRight:98 }
  },

  video: {
    image: 'video',
    ids: { bottomLeft: 80, bottomRight:28, topLeft:2, topRight:54 }
  },

  document: {
    image: 'document',
    ids: { bottomLeft: 166, bottomRight:128, topLeft:109, topRight:147 }
  },

  link: {
    image: 'link',
    ids: { bottomLeft: 96, bottomRight:44, topLeft:18, topRight:70 }
  },

  resume:{
    image:'resume',
    ids: { bottomLeft: 101, bottomRight:49, topLeft:23, topRight:75 }
  },

  mail:{
    image:'mail',
    ids: { bottomLeft: 97, bottomRight:45, topLeft:19, topRight:71 }
  },

  facebook:{
    image:'facebook',
    ids: { bottomLeft: 225, bottomRight:226, topLeft:227, topRight:228 }
  }

};

export function findById(id: number) {
  let icon = '';
  let BreakException = {};
  try {
    let entries = Object.entries(Icons);
   /* entries.forEach(([key, value]) => {
      const result = value.ids.find((v) => v === id);
      if (result) {
        icon = value.image;
        throw BreakException;
      }
    });*/

    entries.forEach(([key, value]) => {
      if(value.ids.bottomLeft === id) {
        icon = value.image;
        throw BreakException;
      }

      if(value.ids.bottomRight === id) {
        icon = value.image;
        throw BreakException;
      }

      if(value.ids.topRight === id) {
        icon = value.image;
        throw BreakException;
      }

      if(value.ids.topLeft === id) {
        icon = value.image;
        throw BreakException;
      }

    });

  } catch (e) {
  } finally {
    return icon;
  }
}

export enum IconPosition {
  TL,
  TR,
  BL,
  BR
}

export function findByName(name: string, position:IconPosition) {
 
  let icon = 0;
  let BreakException = {};
  try {
    let entries = Object.entries(Icons);
    entries.forEach(([key, value]) => {
      if (value.image === name) {
        
       
        switch(position){
          
          case IconPosition.TL:{
            icon = value.ids.topLeft;
            throw BreakException;
          }

          case IconPosition.TR:{
            icon = value.ids.topRight; 
            throw BreakException;
          }

          case IconPosition.BL:{
            icon = value.ids.bottomLeft; 
            throw BreakException;
          }

          case IconPosition.BR:{
            icon = value.ids.bottomRight; 
            throw BreakException;
          }

        }                  
        
      }
    });
  } catch (e) {
  } finally {   
    return icon;
  }
}
