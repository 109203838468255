import React from 'react';

import { Container } from './styles';

import { Price } from '../../../Price/data';
import { Button } from 'react-bootstrap';

interface CardSubProps extends Price {
  onClick: () => void;
}

const Card: React.FC<CardSubProps> = (props) => {
  return (
    <Container className={`price-card ${props.name.toLowerCase()}`}>
      <div className='price-card-title'>
        <h4>{props.name}</h4>
        <div>
          {props.prices.map((p, index) => (
            <>
              <span>{p}</span>
              {index === 0 ? <span>|</span> : ''}
            </>
          ))}
        </div>
      </div>

      <div className='price-card-description'>
        {props.options
          .filter((f) => !f.includes('FREE'))
          .map((p) => (
            <label>{p}</label>
          ))}
        <Button onClick={props.onClick} style={{ flex: '0 0 100%' }}>
          Update
        </Button>
      </div>
    </Container>
  );
};

export default Card;
