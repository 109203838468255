import React from 'react';

import { Row, Col, Container as ContainerBoostrap } from 'react-bootstrap';

import { Container } from './styles';

import banner from '../../assets/images/latest_images/inner-bg-1.png';
import google from '../../assets/images/latest_images/google-play-badge.svg';
import apple from '../../assets/images/latest_images/app-store-badge.svg';
import frameRCM from '../../assets/images/latest_images/frm-img.png';



const Scan: React.FC = () => {
  return (

    <>
    <div className="rcm__frame">
      <div className="container">
        <div className="rcm__frame__inner">
          <div className="image__box">
            <img src={banner} alt="Banner-Img" />
          </div>
          <div className="content__wrapp">
            <h2>How do i scan & <span>RCM FRAME?</span></h2>
            <ul>
              <li><span>Get the RCM Lite App from App Store or Google Play Store</span></li>
              <li><span>Scan a RCM Frame using the App</span></li>
              <li><span>Slide any of the 4 corners to the center of the RCM Frame</span></li>
              <li><span>Experience multimedia content from each corner</span></li>
            </ul>
            <div className="btn__wrapp">
              <a href='https://apps.apple.com/us/app/rcm-lite/id1530281250/'
                  target='_blank'
                  rel='noreferrer'>
                <img src={apple} alt="Apple Store Logo" />
              </a>
              <a  href='https://play.google.com/store/apps/details?id=com.dw.rcmlite'
                  target='_blank'
                  rel='noreferrer'>
                <img src={google} alt="Google Play Logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div className="capabilities__wrapp">
        <div className="container">
           <div className="row">
              <div className="col-lg-9 col-md-9">
                 <div className="content__wrapp">
                    <h2>Capabilities are as limitless as your imagination</h2>
                    <p>Similar to the QR code, but much more powerful. RCM Frames store website links, videos, advertisements, business cards, brochures,  catalogs, menus, addresses, directions on the map, social media accounts, e-commerce, special offers, payments and so much more.</p>
                 </div>
              </div>
              <div className="col-lg-3 col-md-3">
                 <div className="image__box">
                    <img src={frameRCM} alt="Frame-image" />
                 </div>
              </div>
           </div>
        </div>
     </div>
    </>


  );
};

export default Scan;
