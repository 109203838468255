import React from 'react';
import { Table } from 'react-bootstrap';

import useLoginHistory from '../../../../hooks/useLoginHistory';

import { Container } from './styles';

const Login: React.FC = () => {
  const history = useLoginHistory();

  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th className='date'>Login Time</th>
            <th>User ID</th>
          </tr>
        </thead>
        <tbody>
          {history.map((item, index) => (
            <tr key={index}>
              <td className='date'>{item.loginTime}</td>
              <td>{item.emailId}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Login;
