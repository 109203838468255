import React from 'react';

import { Button, Modal, ModalProps } from 'react-bootstrap';
import { Container } from './styles';

interface RcmModalProps extends ModalProps {
  title: string;
  message: string;
  buttonLabel?: string;
  action?: () => void;
  buttonLabelAction?: string;
}

const RcmModal: React.FC<RcmModalProps> = (props:any) => {
  return (
    <Container
      {...props}
      size={props.size || 'lg'}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='mb-3'>{props.message}</p>
        {props.children}
      </Modal.Body>
      <Modal.Footer>
        {props.action && (
          <Button variant='success' onClick={props.action}>
            {props.buttonActionLabel}
          </Button>
        )}
        <Button onClick={props.onHide}>{props.buttonLabel || 'Close'}</Button>
      </Modal.Footer>
    </Container>
  );
};

export default RcmModal;
