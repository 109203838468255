import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from './styles/global';
import light from './styles/themes/light';

import Generator from './pages/Generator';
import Dashboard from './pages/Dashboard';
import CreativeStudio from './pages/CreativeStudio';
import Library from './pages/Library';
import Performance from './pages/Performance';
import Account from './pages/Account';
import Price from './pages/Price';

import Header from './components/Header';
import NewAccount from './pages/NewAccount';
import SignUp from './pages/SignUp';

import gif from './assets/images/loadingRCM.gif';

import Login from './pages/Login';
import Chart from './pages/Chart';
import About from './pages/About';
import ContactUs from './pages/ContactUs';
import TermsConditions from './pages/TermsConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Faq from './pages/Faq';
import Forgot from './pages/Forgot';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={light}>
      <img
        id='page-loader'
        src={gif}
        style={{ display: 'none' }}
        alt='loading'
      />
      <GlobalStyle />
      <Router>
        <Header />
        <Switch>
          <Route path='/' exact component={Generator} />
          <Route path='/generator' component={Generator} exact />
          <Route path='/creative-studio/:id' component={CreativeStudio} />
          <Route path='/creative-studio' component={CreativeStudio} />
          <Route path='/dashboard' component={Dashboard} />
          <Route path='/libraries' component={Library} />
          <Route path='/faq' component={Faq} />
          <Route path='/contact-us' component={ContactUs} />
          <Route path='/performance' component={Performance} />
          <Route path='/account' component={Account} />
          <Route path='/register' component={NewAccount} />
          <Route path='/sign-up' component={SignUp} />
          <Route path='/terms-and-conditions' component={TermsConditions} />
          <Route path='/privacy-policy' component={PrivacyPolicy} />
          <Route path='/price' component={Price} />
          <Route path='/login' component={Login} />
          <Route path='/chart' component={Chart} />
          <Route path='/features' component={About} />
          <Route path='/forgot' component={Forgot} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
