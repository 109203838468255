import styled from 'styled-components';

import { Container as ContainerBootstrap } from 'react-bootstrap';

export const Container = styled(ContainerBootstrap)`
  margin-top: var(--default-margin-top);
  margin-bottom: 2rem;
  .container {
    max-width: 1440px;
  }

  h1 {
    margin-top: 3.8rem;
    margin-bottom: 3.2rem;
  }

  .wrapper {
    background-color: ${(props) => props.theme.colors.background};
    border-radius: var(--radius);
    display: flex;
    height: 100%;

    @media (max-width: 1024px) {
      flex: 0 0 100%;
      max-width: initial;
    }
  }

  .container-steps {
    background-color: #e5e5e566;
    padding: 0 4rem;
    padding-bottom: 6rem;
  }

  .container-fluid {
    margin-top: 0;
  }

  .container-preview {
    h1 {
      display: block;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    max-height: 33.9rem;
    padding-top: 9.5rem;

    .container {
      background-color: white;
      padding-top: 0;
    }

    .row {
      height: 25.5rem;
    }

    .wrapper {
      border-radius: var(--radius);
      background-color: white;
      height: 22.3rem;
    }
  }

  .navigation {
    padding: 3rem 2rem;
    margin-top: 1rem;

    &.row {
      height: auto;
    }
    div {
      padding: 0;
    }

    button {
      min-width: auto;
      padding: 1rem;
    }
  }

  .input-name-frame {
    padding-top: 3rem;
    position: relative;
    input {
      border: 1px solid ${(props) => props.theme.colors.backgrounds.two};
      padding: 1.2rem;
      border-radius: 0.5rem;
      width: 100%;
      &::placeholder {
        opacity: 65%;
        color: ${(props) => props.theme.colors.backgrounds.two};
        text-align: justify;
        font-size: 1.4rem;
      }
    }
    span {
      position: absolute;
      bottom: 0%;
      right: 6%;
      height: 4.5rem;
      transform: translateY(-2%);
      padding-left: 1rem;
      cursor: pointer;
      display: flex;
      align-items: center;

      :hover {
        background-color: ${(props) => props.theme.colors.backgrounds.eight};
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        border-left: 1px solid ${(props) => props.theme.colors.backgrounds.two};
      }
      svg {
        width: 2.5rem;
        height: 2rem;
      }
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
  }
`;
