import React, { useRef } from 'react';
import RcmModal from '../../../../components/Modal';

import { Form, Row, Col, ModalProps, Button } from 'react-bootstrap';
import Title from '../../../../components/Title';

// import { Container } from './styles';

const Group: React.FC<ModalProps> = (props:any) => {
  const refInput = useRef<HTMLInputElement>(null);

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (refInput.current) {
      const contactGroup = {
        id: 0,
        name: refInput.current.value
      };

      props.onHide();
    }
    e.preventDefault();
  };

  return (
    <RcmModal
      title='Update Card Details'
      message=''
      show={props.show}
      onHide={props.onHide}
    >
      <Form onSubmit={handleOnSubmit}>
        <Row>
          <Col>
            <Form.Group controlId='cardNumber'>
              <Form.Label>Card Number</Form.Label>
              <Form.Control
                type='text'
                placeholder='Number'
                required
                ref={refInput}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='formDate'>
              <Form.Label>Please select the expire date</Form.Label>
              <Row>
                <Col md={6}>
                  <Form.Control as='select' required>
                    <option hidden>MM</option>
                    {Array.from(Array(12).keys()).map((month) => (
                      <option key={month}>{month + 1}</option>
                    ))}
                  </Form.Control>
                </Col>
                <Col md={6}>
                  <Form.Control as='select' required>
                    <option hidden>YY</option>
                    {Array.from(Array(20).keys()).map((year) => (
                      <option key={year}>
                        {year + (new Date().getFullYear() - 5)}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId='formCCV'>
              <Form.Label>Card Number</Form.Label>
              <Form.Control
                type='text'
                placeholder='CCV Number'
                required
                ref={refInput}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='formName'>
              <Form.Label>Card Number</Form.Label>
              <Form.Control
                type='text'
                placeholder='Card Holder Name'
                required
                ref={refInput}
              />
            </Form.Group>
          </Col>
        </Row>
        <h3 className='mb-3'>Update Personal Information </h3>
        <Row>
          <Col>
            <Form.Group controlId='formName'>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Your First Name'
                required
                ref={refInput}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='lastName'>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Your Last Name'
                required
                ref={refInput}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group controlId='formName'>
              <Form.Label>Country</Form.Label>
              <Form.Control type='text' required ref={refInput} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='lastName'>
              <Form.Label>State</Form.Label>
              <Form.Control type='text' required ref={refInput} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId='formName'>
              <Form.Label>Zip Code</Form.Label>
              <Form.Control
                type='text'
                placeholder='Street Address'
                required
                ref={refInput}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='lastName'>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control type='text' required ref={refInput} />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className='d-flex align-items-center actions-group '>
            <Button variant='success' type='submit'>
              <span className='mr-2'>Save</span>
            </Button>
          </Col>
        </Row>
      </Form>
    </RcmModal>
  );
};

export default Group;
