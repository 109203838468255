import React from 'react';

import { Step } from '../../interfaces';

import { Col, Row } from 'react-bootstrap';

import { Container } from './styles';

interface StepUIProps extends Step {
  childrenInTip?: boolean;
  className?: string;
}

const StepUI: React.FC<StepUIProps> = (props:any) => {
  const {
    id,
    title,
    placeholder,
    tip,
    currentId,
    onClick,
    hoverTip,
    className
  } = props;

  return(
    <Container onClick={onClick} fluid key={title} className={`step-container ${className} list__item__inner`}>
      
    {props.childrenInTip === undefined && (
      <>{props.children}</>
    )}
    </Container>
  )

  return (
    <Container fluid key={title} className={`step-container ${className}`}>
      <Row onClick={onClick}>
        <Col className={`${currentId === id ? 'border' : ''}`}>
          <Col
            sm={12}
            md={hoverTip ? 12 : 8}
            className={`step ${currentId === id && !hoverTip ? 'show' : ''} ${
              hoverTip ? 'hover-tip' : ''
            } `}
          >
            <span className='d-flex font-secundary'>
              {title} {placeholder}
            </span>
            {props.childrenInTip === undefined && (
              <div className='children'>{props.children}</div>
            )}
            <i className={`${currentId === id && !hoverTip ? 'show' : ''}`}>
              i
            </i>
            <div
              className={`${currentId === id && !hoverTip ? 'show' : ''} box`}
            >
              <div className='tip'>{tip}</div>
            </div>
          </Col>
        </Col>
        <Col
          sm={12}
          className={`tip-mobile  ${
            currentId === id && !hoverTip ? 'show' : ''
          }`}
        >
          {!props.childrenInTip && tip}
          {props.childrenInTip && props.children}
        </Col>
      </Row>
    </Container>
  );
};

export default StepUI;
