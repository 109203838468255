export const rcmBasics = [
  {
    question: 'What is the difference between RCM and QR code?',
    answer: [
      'RCM uses the latest technology to bring you fast, reliable, and direct connections to 4 multimedia channels, rather than one.  RCM allows full color and design customization to make it more attractive than the black and white dots on QR codes. RCM frames are dynamic (rather than static), which means that they can be programmed to change content at any time and as many times as desired.'
    ],

    features: 'Added features to RCM:',
    features_list: [
      'Real time analytics to track performance',
      'Advanced customization and branding',
      'Ready-to-print attractive RCM frames'
    ]
  },
  {
    question: 'How do I create my RCM frame?',
    answer: [
      'Once you create an account and select the plan that suits your needs, you will have access to the RCM Studio dashboard to upload an image of your choice, create your RCM frame, and add content to all 4 corners of your image. It takes seconds to create a frame and add content.'
    ]
  },
  {
    question:
      'Can I change the content of an existing RCM frame? How long does it take to change the content on my RCM frame?',
    answer: [
      'Yes, you may change the content as many time as you wish. It takes seconds to edit your RCM frame content with your RCM Studio dashboard. The changes made take effect immediately, in real-time.'
    ]
  },
  {
    question: 'How do I print a RCM framed Image?',
    answer: [
      'Once your RCM framed image is created with the Studio dashboard, you will have the option to download the image in high resolution, ready to be printed and utilized within your marketing materials.'
    ]
  },
  {
    question:
      'What is the smallest size I can print a RCM frame and still be able to scan it?',
    answer: [
      'The size of the frame should be at least 0.4 inch x 0.4 inch (1 cm x 1 cm).'
    ]
  },
  {
    question:
      'Is it possible to create different RCM frames for the same content?',
    answer: [
      'Yes, you may create as many frames as you like with the same content. In fact, creating different frames may help you to identify what image is more attractive to your clientele since you can see the total number of scan per RCM frame and compare them.'
    ]
  },
  {
    question:
      'Can I use a company logo, slogan, icon, or text for a call to action?',
    answer: [
      'Yes, we can embed an image, lettering, and picture into an RCM frame.'
    ]
  },
  {
    question: 'What kind of information can be stored in a RCM frame?',
    answer: [
      'You have the option to include 4 multimedia channels to each RCM frame. You may use images, sounds, music, videos, addresses, navigation data, email messages, phone numbers , URLs (homepages, Youtube, Facebook, Instagram), contact information, and much more. However, we suggest that you use a link to a website when it comes to images, sounds, or videos in order to speed up the content download to viewers.'
    ]
  },
  {
    question: 'How users can read/scan a RCM frame?',
    answer: [
      'Users download the RCM Lite App, free of charge, from Google Play or Apple Store and scan the RCM frame to view the multimedia content associated to each of the four corners of the frame.'
    ]
  },
  {
    question: 'Is it possible to count the RCM frame scans?',
    answer: [
      'Yes, our RCM Analytic Reports were designed to assist advertisers and marketing managers to track, measure and compare not only the number of total scans per image but also the number of views and interactions for each of the four multimedia channels in real time.'
    ]
  },
  {
    question: 'How long are the RCM frames valid? Do they expire?',
    answer: [
      'Our RCM frames do not expire or become invalid. However, they will work according to the lifetime of your subscription. When you renew your subscription, your RCM frame will also be renewed automatically.'
    ]
  },
  {
    question: 'Is RCM saving my data?',
    answer: [
      'We do not save or reuse your data by any means. We do track frame information, such as how many times the frame was scanned, but we do not track frame information generated by users’ usage (users are anonymous).  We do not track users information in any form.'
    ]
  }
];

export const rcmStudio = [
  {
    question: 'How do I create my RCM frame on the Dashboard?',
    answer: [
      ' Upload an image by clicking on step one. Click Next button under the image. Select the RCM frame that best suits your image and click Next button. Press Step 3 button to view and select the icons for each corner of your image. Drag desired icon to each of the corners of your image. Once you finish adding icons to all 4 corners, press Apply button followed by Next button. Click on Step 4 and select the content type and inset data (link, email, address, phone number, file, or SMS for each corner. Press Apply. You are now ready to name, save or download your RCM.'
    ]
  },
  {
    question: 'How do I change the content on my RCM frame?',
    answer: [
      'Select a RCM Frame to edit content. After selecting a RCM Frame, press EDIT button. Next, you will be able to change the image, frame layout, icons and content of your RCM Frame.'
    ]
  },
  {
    question:
      'What is the ideal image size and format to import and create my RCM Frame?',
    answer: [
      'We recommend the images to be imported in high resolution, minimum pixel size is 600x600px and in the following formats PNG, JPG, JPEG, GIF, BMP. '
    ]
  },
  {
    question:
      'What is the ideal format and size to import my image for content?',
    answer: [
      'To create content, RCM supports images in PNG, JPG, JPEG, GIF, BMP, minimum pixel size of 600x600px.'
    ]
  },
  {
    question: 'What kind of information can be stored in a RCM frame? ',
    answer: [
      'You have the option to include 4 multimedia channels to each RCM frame. You may use images, sounds, music, videos, addresses, navigation data, email messages, phone numbers ,URLs (homepages, Youtube, Facebook, Instagram), contact information, and much more. However, we suggest that you use a link to a website when it comes to images, sounds, or videos in order to speed up the content download to viewers.'
    ]
  },
  {
    question: 'What is the ideal format to import my video for content?',
    answer: [
      'To create content, RCM supports videos in MP4, FLV, WEBM, MPG, OGV, MOV, WMV, M4V, 3GPP, 3GP, QUICKTIME, MKV.'
    ]
  },
  {
    question:
      'What are the supported formats that I can export a created RCM framed image?',
    answer: ['The supported format to export a RCM framed image is PNG.']
  },
  {
    question:
      'What is the minimum and maximum distance between the RCM Code and a scanning device?',
    answer: [
      'Users are able to scan any image as long as the entire frame is “inside” the marks on the app, and the device’s can fully focus on the RCM frame. '
    ]
  }
];
