import styled from 'styled-components';

import { Col } from 'react-bootstrap';

export const Container = styled(Col)`
  border-radius: var(--radius);
  min-height: 60rem;

  background-color: ${(props) => props.theme.colors.primary};

  padding-top: 6rem;

  @media (max-width: 1024px) {
    max-width: 100%;
    flex: 0 0 auto;
    padding: 0;
    margin-bottom: 1rem;
  }

  ul {
    font-family: var(--font-secundary);
    font-size: 3rem;
    font-weight: var(--bold);
    padding-left: 4rem;
    text-transform: uppercase;

    @media (max-width: 1024px) {
      display: flex;
      justify-content: space-around;
      padding-left: 0;
      font-size: 2rem;
      margin-bottom: 0;
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;
      text-align: center;
    }

    li {
      .active {
        pointer-events: none;
      }
      &:nth-child(1) .active {
        color: ${(props) => props.theme.colors.secondary};
      }

      &:nth-child(2) .active {
        color: ${(props) => props.theme.colors.backgrounds.four};
      }

      &:nth-child(3) .active {
        color: ${(props) => props.theme.colors.backgrounds.two};
      }

      &:nth-child(4) .active {
        color: ${(props) => props.theme.colors.backgrounds.one};
      }

      a {
        color: ${(props) => props.theme.colors.background};
      }

      padding: 3rem 0;

      @media (max-width: 768px) {
        padding: 1rem 0;
        flex: 100%;
      }
    }
  }
`;
