import { useState } from 'react';
import { RegisterResult } from '../interfaces';

import { newAccount } from '../service/api';

function useRegister() {
  const [register, setState] = useState<RegisterResult>({
    result: {
      country: 'United States',
      countryId: 177,
      email: '',
      password: '',
      firstName: '',
      addressLine1: '',
      city: '',
      zipCode: '',
      state: '',
      captcha: '',
      industry: 16,
      website: '',
      lastName: '',
      confirmEmail: '',
      challengeField: '',
      responseField: '',
      phone: '12015555555',
      displayName: '',
      repeatPassword: '',
      domainId: 1
    },
    status: ''
  });

  async function save() {
    if (register.result) {
      const result = await newAccount(register.result);
      if (result.data.status === 'success') setState(result.data);
      else {
        const newData = { ...register };
        newData.status = 'fail';
        newData.err_msg = result.data.err_msg;
        setState(newData);
      }
    }
  }

  return [register, save] as const;
}

export default useRegister;
