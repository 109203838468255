import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import { Container } from './styles';

const Menu: React.FC = () => {
  const location = useLocation();

  function isCurrentLocation(value: string) {
    if (location.pathname.startsWith(value)) return 'active';
  }

  return (
    <>
      <h2
        className='container font-secundary'
        style={{ fontWeight: 900, fontSize: '4.7rem' }}
      >
        DASHBOARD
      </h2>
      <Container md={4} sm={12}>
        <ul>
          <li>
            <Link
              to='/creative-studio'
              className={isCurrentLocation('/creative-studio')}
            >
              Creative Studio
            </Link>
          </li>
          <li>
            <Link to='/libraries' className={isCurrentLocation('/libraries')}>
              LIBRARY
            </Link>
          </li>
          <li>
            <Link
              to='/performance'
              className={isCurrentLocation('/performance')}
            >
              Performance
            </Link>
          </li>
          <li>
            <Link to='/account' className={isCurrentLocation('/account')}>
              Account
            </Link>
          </li>
        </ul>
      </Container>
    </>
  );
};

export default Menu;
