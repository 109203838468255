import React, { useState } from 'react';

import { Row, Col } from 'react-bootstrap';

// import preview from '../../../assets/images/preview.jpg';
import preview from '../../../assets/images/latest_images/banner-media.png'

import overlay from '../../../assets/images/overlay.png';
import overlayLeftRight from '../../../assets/images/overlay-lf.png';
import overlayTopBottom from '../../../assets/images/overlay-tb.png';

import { Container } from './styles';
import { ReelGenerator, Position } from '../../../interfaces';

import Title from '../../../components/Title';

import useIcons from '../../../hooks/useIcons';

const Preview: React.FC<ReelGenerator> = (props) => {
  const { image, children, currentStep, icons, layout } = props;
  const { cornerTopLeft, cornerTopRight, cornerBottomLeft, cornerBottomRight } =
    Position;

  const [corners, setCorners] = useState({ ...icons.corners });

  const keys = useIcons();

  function findIcon(name: string = 'COUPON') {
    return keys.find(([key, value]) => key === name)?.[1];
  }

  function getOverlay() {
    if (layout === 'layout1') return overlayLeftRight;

    if (layout === 'layout2') return overlayTopBottom;

    if (layout === 'layout3') return overlay;
  }

  const handleOnDrop = (event: React.DragEvent<HTMLSpanElement>) => {
    event.preventDefault();
    const cornerId = event.currentTarget.id
      ? Number(event.currentTarget.id)
      : 0;
    props.icons.corners[cornerId].icon = event.dataTransfer.getData('id');
    event.currentTarget.focus();
    setCorners({ ...props.icons.corners });
    event.currentTarget.classList.remove('focus');
  };

  const handleOnDragOver = (event: React.DragEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.currentTarget.classList.add('focus');
    Array.from(document.querySelectorAll('.focus'))
      .find((e) => e.id !== event.currentTarget.id)
      ?.classList.remove('focus');
  };

  return (
    <Container className='preview'>
      <Row>
        <Col>
          <Title>Preview</Title>
          <div className='wrapper'>
            <div className={`top-icons ${currentStep > 2 ? '' : 'hide'}`}>
              <span
                onDrop={handleOnDrop}
                onDragOver={handleOnDragOver}
                className='corner-content'
                id={`${cornerTopLeft}`}
              >
                <svg>
                  <path
                    className='pointer'
                    d='M84.9328 40.1257C83.7103 17.7583 65.176 0 42.5 0C19.0274 0 0 19.0274 0 42.5C0 65.345 18.0256 83.9792 40.6309 84.9569L40.5878 85H85V40.0584L84.9328 40.1257Z'
                  />
                </svg>

                {corners[0].icon && (
                  <img
                    className='corner-icon'
                    src={findIcon(corners[0].icon)}
                    alt='corner icon'
                  ></img>
                )}
              </span>
              <span
                onDrop={handleOnDrop}
                onDragOver={handleOnDragOver}
                className='corner-content'
                id={`${cornerTopRight}`}
              >
                <svg>
                  <path
                    d='M0.0672467 40.1257C1.29838 17.7583 19.824 0 42.5 0C65.9726 0 85 19.0274 85 42.5C85 65.345 66.9744 83.9792 44.3691 84.9569L44.4122 85H0V40.0584L0.0672467 40.1257Z'
                    className='pointer'
                  />
                </svg>
                {corners[1].icon && (
                  <img
                    onDrop={handleOnDrop}
                    onDragOver={handleOnDragOver}
                    className='corner-icon'
                    src={findIcon(corners[1].icon)}
                    alt='corner icon'
                  ></img>
                )}
              </span>
            </div>

            <img alt='preview' src={image.path ? image.path : preview} />
            {currentStep >= 2 && layout && (
              <img alt='preview' src={getOverlay()} />
            )}

            <div className={`bottom-icons ${currentStep > 2 ? '' : 'hide'}`}>
              <span
                onDrop={handleOnDrop}
                onDragOver={handleOnDragOver}
                className='corner-content'
                id={`${cornerBottomLeft}`}
              >
                <svg>
                  <path
                    d='M84.9328 44.8743C83.7103 67.2417 65.176 85 42.5 85C19.0274 85 0 65.9726 0 42.5C0 19.655 18.0256 1.02077 40.6309 0.0431069L40.5878 0H85V44.9416L84.9328 44.8743Z'
                    className='pointer'
                  />
                </svg>

                {corners[2].icon && (
                  <img
                    className='corner-icon'
                    src={findIcon(corners[2].icon)}
                    alt='corner icon'
                  ></img>
                )}
              </span>
              <span
                onDrop={handleOnDrop}
                onDragOver={handleOnDragOver}
                className='corner-content'
                id={`${cornerBottomRight}`}
              >
                <svg>
                  <path
                    d='M0.0672467 44.8743C1.29838 67.2417 19.824 85 42.5 85C65.9726 85 85 65.9726 85 42.5C85 19.655 66.9744 1.02077 44.3691 0.0431069L44.4122 0H0V44.9416L0.0672467 44.8743Z'
                    className='pointer'
                  />
                </svg>
                {corners[3].icon && (
                  <img
                    className='corner-icon'
                    src={findIcon(corners[3].icon)}
                    alt='corner icon'
                  ></img>
                )}
              </span>
            </div>
          </div>
        </Col>
      </Row>
      {children}
    </Container>
  );
};

export default Preview;
