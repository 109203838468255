import React from 'react';
import { UserSubscription } from '../interfaces';
import { getSubscription } from '../service/subscription';

function useSub() {
  const [subscription, setSubscription] = React.useState<UserSubscription>();

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await getSubscription();

      if (response.status === 'success') setSubscription(response.result);
    };
    fetchData();
  }, []);

  return subscription;
}

export default useSub;
