import React, { useState, useEffect, useRef } from 'react';

import {
  Button,
  Modal,
  Row,
  Col,
  Container as ContainerBoostrap,
  Form
} from 'react-bootstrap';

import { Container } from './styles';

import videoUrl from '../../assets/video/home.mp4';
import thumb from '../../assets/video/thumb.png';
import play from '../../assets/video/play.svg';
import useRegister from '../../hooks/useRegister';
import { useAlert } from '../../hooks/useAlert';


import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';

import signupBg from '../../assets/images/latest_images/frm-img-1.png';
import userIco from '../../assets/images/latest_images/user-ico.svg';
import emailIco from '../../assets/images/latest_images/email-ico.svg';
import passwordIco from '../../assets/images/latest_images/view-ico.svg';

const SignUp: React.FC = () => {
  const [show, setShow] = useState(false);
  const [, danger, ,] = useAlert();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const pwdRef = useRef<HTMLInputElement>(null);

  const [account, save] = useRegister();

  const handleOnSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (account.result) {
      account.result.firstName = firstNameRef.current?.value || '';
      account.result.lastName = lastNameRef.current?.value || '';

      account.result.email = emailRef.current?.value || '';
      account.result.confirmEmail = '';

      account.result.displayName = account.result.firstName;
      account.result.website = '';

      account.result.password = pwdRef.current?.value || '';
      account.result.repeatPassword = pwdRef.current?.value || '';

      account.result.addressLine1 = '';
      account.result.zipCode = '';
      account.result.country = '';
      account.result.city = '';
      account.result.state = '';

      save();
    }
  };

  useEffect(() => {
    if (account.status === 'fail') danger(account.err_msg || 'API Error');
  }, [account.status]);

  if (account.status === 'success') {
    return (
      <div className='container font-primary large center__text'>
        <h2>Thanks for registering with Reel Code Media. An activation email has
        been sent to <b>{account.result?.email}</b>.</h2>
        <div className="mt-4">
          <Link to="login">Go to Login</Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="body__content__main user__account__wrapp">
        <div className="container">
          <div className="form__wrapp__outer">
            <div className="side__wrapp">
              <div className="image__box">
                <img src={signupBg} alt="Sign-up Background" />
              </div>
            </div>
            <div className="form__wrapp">
              <div className="form__wrapp__inner">
                <h1>Let’s Get <span>Started</span></h1>
                <p>Sign up for a free account and start your 30 day trial. 
                  <Link to="login"> Already have accout ? Sign In</Link>
                </p>

                <form onSubmit={handleOnSubmit}>
                  <div className="form-group form__group">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <label htmlFor="fisrtName">First Name</label>
                        <div className="input-group input__group">
                          <input type="text" 
                                 className="form-control form__control" 
                                 placeholder="First Name" 
                                 id='fisrtName' 
                                 ref={firstNameRef} 
                                 required/>
                          <i className="ico__box"><img src={userIco} alt="first-name-icon" /></i>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <label htmlFor="lastName">Last Name</label>
                        <div className="input-group input__group">
                          <input type="text" 
                                 className="form-control form__control" 
                                 placeholder="Last Name" 
                                 id='lastName' 
                                 ref={lastNameRef} 
                                 required/>
                          <i className="ico__box"><img src={userIco} alt="last-name-icon" /></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group form__group">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <label htmlFor="email">Email</label>
                        <div className="input-group input__group">
                          <input type="email" 
                                className="form-control form__control" 
                                placeholder="Email" 
                                id='email'
                                autoComplete='off'
                                required
                                ref={emailRef}/>
                          <i className="ico__box"><img src={emailIco} alt="email-icon" /></i>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <label htmlFor="password">Password</label>
                        <div className="input-group input__group">
                          <input type="password" 
                                className="form-control form__control" 
                                placeholder="Password"
                                id='password'
                                ref={pwdRef}
                                autoComplete='off'
                                required />
                          <i className="ico__box"><img src={passwordIco} alt="password-icon" /></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form__footer">
                    <button type="submit" className="submit__btn create__account">Create Account</button>
                  </div>
                  <div className="form-group form__group">
                    <div className="input-group input__group">
                      <label className="checkbox" htmlFor="AcceptCheck">
                        <input type="checkbox" 
                               className="crat__check" 
                               name="accept" 
                               id="AcceptCheck" 
                               defaultChecked={true} 
                               readOnly={true} />
                        <span className="checkmark"></span>
                        <p>By clicking Create Account', I agree to Reel Code Media's <Link to="terms-and-conditions">Terms of
                        Service</Link> and acknowledge its <Link to="privacy-policy">Privacy Policy</Link>.</p>
                        </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SignUp;
