import styled from 'styled-components';

export const Container = styled.span`
  margin-bottom: 1.2rem;
  display: flex;
  cursor: pointer;
  position: relative;
  flex: 100%;
  align-items: center;

  border-bottom: 0.1rem solid ${(props) => props.theme.colors.background};

  img {
    margin-bottom: 0.2rem;
  }

  &.active {
    .folder-name span {
      font-weight: bold;
    }
  }

  .folder {
    &-name {
      max-width: 4rem;
      line-height: 1.2rem;
      display: inline-block;
      margin-left: 1rem;
    }
    &-select {
      position: absolute;
      top: 0.2rem;
      right: 0.2rem;
    }
  }

  input[type='checkbox'] {
    cursor: pointer;
  }

  form {
    display: inline;

    input {
      width: 8rem;
      font-size: 1.2rem;
    }
  }
`;
