import React from 'react';

import { Container } from './styles';

import { Link, useHistory } from 'react-router-dom';

import { Price } from '../data';

const Card: React.FC<Price> = (props) => {
  let history = useHistory()


  return (

    <div className="card__item">
       <div className="card__header">
          <h3>{props.name}</h3>
          <div className="plan__subscraption">

          {props.prices.map((p, index) => (
            <React.Fragment key={index}>
              <div className="plan__tenure">{p}</div>
            </React.Fragment>
          ))}
          </div>
       </div>
       <div className="card__body">
          <div className="plan__basis">

            {props.types.map((p, index) => (
              <div key={index} onClick={()=>{history.push('/register')}}>
                <input id={p} type='radio' />
                <label htmlFor={p}>{p}</label>
              </div>
            ))}
          </div>

          <ul>
              {props.options.map((p, index) => (
                <li key={index}>{p}</li>
              ))}
          </ul>
          <div className="btn__wrapp">
             <Link to="sign-up">Start Free Trial</Link>
          </div>
       </div>
    </div>


    
  );
};

export default Card;
