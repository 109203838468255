import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import { Container } from './styles';
import { Row } from 'react-bootstrap';

import { ThemeContext } from 'styled-components';

import Footer from '../../components/Footer';

import { prices } from './data';
import Card from './Card';

import frameRCM from '../../assets/images/latest_images/frm-img-1.png';

// import bgImage from '../../assets/images/latest_images/cms-bg.svg';
// import bgImageGreen from '../../assets/images/latest_images/green-bg.svg';


const Price: React.FC = () => {
  const location = useLocation();

  return (
    <>

      {location.pathname !== '/price' && (  
        <div className="choose__plan" id='price'>
          <div className="container">
            <div className="heading__wrapp">
              <h2>Choose <span>your plan</span></h2>
              <p>RCM offers six different membership plans to accomodate businesses and projects of all sizes.</p>
            </div>
            <div className="plan__cards">
              {prices.map((p) => (
              <Card key={p.name} {...p} />
              ))}
              
            </div>
          </div>
        </div>
      )}





      {location.pathname === '/price' && (
        <>
          <div className="body__content__main cms__page__wrapp">
             <div className=" price__plan__list">
                <div className="container">
                   <div className="heading__wrapp">
                      <h3>NO SURPISES. CANCEL ANYTIME.</h3>
                      <h2 style={{ fontSize: '32px' }}>Flexible Plans That <span>Grow With You</span></h2>
                      <p>RCM offers six different membership plans to accomodate businesses and projects of all sizes.</p>
                      <h4>Get Started for free with our 30 day trial</h4>
                   </div>
                   <div className="choose__plan">
                      <div className="plan__cards">
                        {prices.map((p) => (
                          <Card key={p.name} {...p} />
                        ))}
                         
                      </div>
                   </div>
                </div>
             </div>
          </div>

          <div className="capabilities__wrapp">
             <div className="container">
                <div className="row">
                   <div className="col-lg-9 col-md-9">
                      <div className="content__wrapp">
                         <h2>View analytics and easily</h2>
                         <p>Similar to the QR code, but much more powerful. RCM Frames store website links, videos, advertisements, business cards, brochures, catalogs, menus, addresses, directions on the map, social media accounts, e-commerce, special offers, payments and so much more.</p>
                      </div>
                   </div>
                   <div className="col-lg-3 col-md-3">
                      <div className="image__box">
                         <img src={frameRCM} alt="Frame-image" />
                      </div>
                   </div>
                </div>
             </div>
          </div>

          <Footer />
        </>
      )}
    </>
  );
};

export default Price;
