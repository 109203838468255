import React, { useEffect } from 'react';
import Footer from '../../components/Footer';

// import Title from '../../components/Title';
// import { Container } from './styles';



const PrivacyPolicy: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
       <div className="body__content__main cms__page__wrapp">
        <div className="container">
            <div className="heading__wrapp">
               <h1>Privacy <span>Policy</span></h1>
            </div>
            <p>Last Updated: January 27, 2021.</p>
            <ul>
                <li><strong>Personal Information.</strong> REELCODEMEDIA.COM is owned and operated by Reel Code Media, Inc. (“RCM” or “we” or “us”). You may choose to provide personal information (such as your name, address, telephone number and email address) on this web site. Here are some of the ways you may provide the information and the types of information you may submit. We also tell you how we may use the information.</li>

                <li><strong>Contact Us.</strong> If you email us through a Contact link we may provide on this site, we ask you for information such as your name and email address so we can respond to your questions and comments. You may choose to provide additional information as well.</li>

                <li><strong>Questionnaires and Surveys.</strong> We occasionally may ask visitors to our web site to complete online surveys and opinion polls about their activities, attitudes and interests. These surveys help us serve you better and improve the usefulness of our site. In conducting these surveys we may ask you for your name and email address.</li>

                <li><strong>Send Content to a Friend.</strong> You may be able to send certain content to your friends. To do so, we ask you to provide your name and your friend’s email address. We use this information to send the content to your friend.</li>

                <li><strong>Non-Personal Information.</strong> We collect certain aggregate and non-personal information when you visit this web site. Aggregate and non-personal information does not relate to a single identifiable visitor. It tells us such things as how many users visited our site and the pages accessed. By collecting this information, we learn how to best tailor our web site to our visitors. We collect this information either through “cookie” technology or with “web beacons,” as explained below.</li>

                <li><strong>Cookies.</strong> Like many companies, we use browser cookies on this web site. Browser cookies are bits of text that are placed on your computer’s hard drive when you visit certain web sites. We use browser cookies to tell us, for example, whether you’ve visited us before or if you’re a new visitor and to help us identify site features in which you may have the greatest interest. Browser cookies may enhance your online experience by saving your preferences while you are visiting a particular site. The “help” portion of the toolbar on most browsers will tell you how to stop accepting new browser cookies, how to be notified when you receive a new browser cookie, and how to disable existing browser cookies. Remember though, without browser cookies, you may not be able to take full advantage of all our web site features. We also use flash cookies on our web site to enhance your online experience. Flash cookies are similar to browser cookies, except that they can store data more complex than simple text. Flash cookies, by themselves, cannot do anything to or with the data on your computer. In addition, flash cookies cannot access or remember your email address or other personal information unless you provide the information on this site. We may use flash cookies to provide you with a better user experience.</li>

                <li><strong>Web Beacons.</strong> Certain pages on our site may contain “web beacons” (also known as Internet tags, pixel tags and clear GIFs). These web beacons allow third parties to obtain information such as the IP address of the computer that downloaded the page on which the beacon appears, the URL of the page on which the beacon appears, the time the page containing the beacon was viewed, the type of browser used to view the page, and the information in cookies set by the third party. We use log files to store the data that is collected through web beacons. REELCODEMEDIA.COM also uses third party web beacons to help manage online advertising. These web beacons may be provided by our ad management partners. These files enable our ad management partners to recognize a unique cookie on your web browser, which in turn enables us to learn which advertisements bring users to our web site. The cookie on your web browser was placed by REELCODEMEDIA.COM, or by another advertiser who works with our ad management partners . With both cookies and web beacon technology, the information that we collect and share is anonymous and not personally identifiable. It does not contain your name, address, telephone number, or email address.</li>

                <li><strong>Information We Share.</strong> We do not sell or otherwise disclose personal information about our web site visitors, except as described here. We may share information provided by our visitors to reelcodemedia.com with service providers we have retained to perform services on our behalf. These service providers are contractually restricted from using or disclosing the information except as necessary to perform services on our behalf or to comply with legal requirements. In addition, we may disclose information about you (i) if we are required to do so by law or legal process, (ii) to law enforcement authorities or other government officials, or (iii) when we believe disclosure is necessary or appropriate to prevent physical harm or financial loss or in connection with an investigation of suspected or actual illegal activity. We reserve the right to transfer any information we have about you in the event we sell or transfer all or a portion of our business or assets. Should such a sale or transfer occur, we will use reasonable efforts to direct the transferee to use personal information you have provided through this web site in a manner that is consistent with this Privacy Statement</li>

                <li><strong>Information We Transfer.</strong> We may transfer the personal information we collect on this site to other countries where we do business, but we will do so only for the purposes described above. When we transfer your information to other countries, we will protect that information as described here unless otherwise required by applicable laws.</li>

                <li><strong>Links to Other Sites.</strong> We provide links to other web sites for your convenience and information. These sites may have their own privacy statements in place, which we recommend you review if you visit any linked web sites. We are not responsible for the content of linked sites or any use of the sites.</li>

                <li><strong>Children’s Privacy.</strong> This web site is not directed to children under the age of thirteen and we do not knowingly collect personal information from children under the age of thirteen on the site. If we become aware that we have inadvertently received personal information from a visitor under the age of thirteen on the site, we will delete the information from our records. Some links on reelcodemedia.com take web site visitors to sites that are directed to kids. These web sites have their own privacy notices that address children’s privacy. We encourage you to read these privacy notices if you visit any linked sites directed to children.</li>

                <li><strong>How We Protect Personal Information.</strong> We maintain administrative, technical and physical safeguards to protect against unauthorized disclosure, use, alteration or destruction of the personal information you provide on this web site. We use secure socket layer (SSL) technology to help keep the personal information you provide on this site secure.</li>

                <li><strong></strong>Mobile Information We Collect. Personally identifiable information (e.g. Name, email address) we collect consists only of what you share with us. For example, certain products may ask for this type of information so that we may contact you by phone or email at your request. You choose what, how, and when you want to share.</li>

                <li><strong>Updates to Our Privacy Statement.</strong> This Privacy Statement may be updated periodically and without prior notice to you to reflect changes in our online information practices. We recommend that you revisit this page from time-to-time to make note of any significant changes to our Privacy Statement. A date will be placed at the top of the statement to indicate when it was most recently updated.</li>

                <li><strong>Location Based Services.</strong> If you use any location-enabled products, you may be sending us location information. REELCODEMEDIA.COM does not store or use this information other than to provide the service you requested. For example, a mobile product may use GPS data to find a nearby restaurant you requested. Location-enabled features are opt-in and you have control over your participation and can turn these services off at any time.</li>

                <li><strong>Web beacons and cookies.</strong> Web-enabled mobile applications may use cookies or web beacons and other methods to customize your browsing experience. Please see REELCODEMEDIA.COM's web privacy policy for more information.</li>

                <li><strong>Ad management.</strong> Some mobile applications will utilize ad management partners to help us better serve our customers through improved products, services, and revisions to the mobile applications. This collected information will not identify you to REELCODEMEDIA.COM. It may, however, let us know anonymously, which services and features you are using the most within the application, as well as device type and hardware features, country and language of download.</li>

                <li>Third party sharing. Use of 3rd party services such as social sharing sites (e.g., Facebook & Twitter) is governed by the privacy practices of those services. REELCODEMEDIA.COM does not capture or store your login information or other personally identifiable information for these services, however session info or cookies may be stored.</li>

                <li><strong>Mobile How We Use Collected Information.</strong> We use the information to personalize your experience with the application and to improve your overall experience including the development of new products, services, and features. We also use the information to provide customer support, and when applicable, register and administer your account. Additionally, we may use the information you provide to contact you about updates to our service.</li>

                <li><strong>eCommerce.</strong> Information you provide may be used to fulfill the service(s) or carry out the transaction(s) you have requested or authorized. For example, if you authorize a purchase through a mobile eCommerce application, we will use provided information to ship and bill you.</li>
                
                <li><strong>How to Contact Us.</strong> If you have any questions or comments about this Privacy Statement, or if you would like us to update information we have about you or your preferences, we invite you to contact our customer service department at <a href="mailto:support@reelcodemedia.com">support@reelcodemedia.com</a>.</li>
            </ul>
         </div>    
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
