import React, { useState } from 'react';
import { User } from '../interfaces';

import { getUser, saveUser } from '../service/user';

function useUser() {
  const [user, setState] = useState<User>();

  async function get() {
    const usrStr = localStorage.getItem('users');
    if (usrStr) {
      const users: User = JSON.parse(usrStr);
      const user = await getUser(users.userId);
      const result = user.data;
      if (result.status === 'success') {
        setState(result.result);
      }
    }
  }

  async function save(form: FormData) {
    if (user) saveUser(user);
  }

  function setUser(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    const { name, value } = event.target;
    if (user) {
      if (name.includes('.')) {
        const [key, subKey] = name.split('.');
        if (key) {
          setState({ ...user, [key]: { ...user.address, [subKey]: value } });
        }
      } else setState({ ...user, [name]: value });
    }
  }

  return [user, get, save, setUser] as const;
}

export default useUser;
