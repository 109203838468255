import React from 'react';

import { ThemeContext } from 'styled-components';

import Separator from '../../components/Separator';

import { Row, Col, Container as ContainerBoostrap } from 'react-bootstrap';

import { Container } from './styles';

import code1 from '../../assets/images/latest_images/code-1.png';
import code2 from '../../assets/images/latest_images/code-2.png';
import code3 from '../../assets/images/latest_images/code-3.png';
import code4 from '../../assets/images/latest_images/code-4.png';
import code5 from '../../assets/images/latest_images/code-5.png';
import code6 from '../../assets/images/latest_images/code-6.png';
import code7 from '../../assets/images/latest_images/code-7.png';
import newCodeImg from '../../assets/images/latest_images/frm-img.png';


const Try: React.FC = () => {
  const themeContext = React.useContext(ThemeContext);
  return (

    <>
      <div className="sign__up__rcm">
            <div className="container">
               <h2>Sign Up For RCM 30-Day Trial</h2>
               <h3>No commitments, Cancel Anytime</h3>
            </div>
         </div>

         <div className="rcm__code__improve">
            <div className="container">
               <h2>RCM CODE can improve <span>all kinds of industries</span></h2>
               <div className="rcm__code__gallery">
                  <div className="code__gallery__inner__main">
                     <img src={code1} alt="" />
                     <div className="image__box__two__col">
                        <img src={code2} alt="" />
                        <img src={code3} alt="" />
                     </div>
                  </div>
                  <div className="code__gallery__inner__main">
                     <img src={code4} alt="" />
                     <img src={code5} alt="" />
                  </div>
                  <div className="code__gallery__inner__main">
                     <img src={code6} alt="" />
                     <img src={code7} alt="" />
                  </div>
               </div>
            </div>
         </div>


         <div className="create__rcm__wrapp">
            <div className="container">
               <div className="row">
                  <div className="col-lg-9 col-md-9">
                     <div className="content__wrapp">
                        <h2>Create a new RCM CODE within minutes</h2>
                        <p>Experience the creation of an RCM codeand visualize how your RCM will look.</p>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                     <div className="image__box">
                        <img src={newCodeImg} alt="" />
                     </div>
                     <div className="btn__wrapp">
                        <a onClick={() => window.scrollTo(0, 0)}>Try it now</a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
    </>


   
  );
};

export default Try;
