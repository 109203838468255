import React, { useEffect } from 'react';
import Footer from '../../components/Footer';

// import Title from '../../components/Title';
// import { Container } from './styles';

const TermsConditions: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
      <div className="body__content__main cms__page__wrapp">
        <div className="container">
            <div className="heading__wrapp">
               <h1>Terms & <span>Conditions</span></h1>
            </div>
            <p>Reel Code Media, Inc. ("RCM” or “Reel Code”) operates the Reel Code Media website, web services, and the Reel Code Media Lite mobile application. (collectively and individually, the “Service”). RCM is sometimes hereinafter referred to as “we” or “us.”</p>
            <p>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THE SERVICE. THIS IS A LEGAL AGREEMENT BETWEEN RCM AND YOU, THE INDIVIDUAL OR ENTITY USING OR ACCESSING THE SERVICE. BY USING OR ACCESSING THE SERVICE, OR CLICKING “ACCEPT” OR “I AGREE BELOW,” YOU ARE CONFIRMING YOUR UNCONDITIONAL ACCEPTANCE OF THE FOLLOWING TERMS AND CONDITIONS. IF YOU DO NOT ACCEPT THESE TERMS AND CONDITIONS, DO NOT USE THE SERVICE OR APPLICATION. YOU MUST BE AT LEAST 13 YEARS OLD TO USE THE SERVICE.</p>
            <ul>
                <li><strong>Products, Content and Specifications.</strong> All features, content, specifications, products and prices of products and services described or depicted on the Service are subject to change at any time without notice. Certain weights, measures and similar descriptions are approximate and are provided for convenience purposes only. We make all reasonable efforts to accurately display the attributes of our products, including the applicable colors; however, the actual color you see will depend on your computer system and we cannot guarantee that your computer will accurately display such colors. The inclusion of any products or services in the Service at a particular time does not imply or warrant that these products or services will be available at any time. It is your responsibility to ascertain and obey all applicable local, state, federal and international laws (including minimum age requirements) in regard to the possession, use and sale of any item purchased from the Service. By placing an order, you represent that the products ordered will be used only in a lawful manner.</li>
                <li><strong>Product Orders.</strong> All orders placed through the Service are subject to RCM’s acceptance, which is in its sole and absolute discretion. Without limitation, this means that RCM may refuse to accept or may cancel any order, whether or not the order has been confirmed, for any or no reason, in its sole and absolute discretion, and without liability to you or any third party. If your credit card has already been charged for an order that is subsequently cancelled, RCM shall issue a credit to your credit card account.</li>
                <li><strong>Shipping Limitations.</strong> When an order is placed, it will be shipped to an address designated by the purchaser as long as that shipping address is compliant with the shipping restrictions contained on the Service. All purchases from the Service are made pursuant to a shipment contract. As a result, risk of loss and title for items purchased from the Service pass to you upon delivery of the items to the carrier. You are responsible for filing any claims with carriers for damaged and/or lost shipments. In some cases, shipping insurance may be available from the carrier, and we encourage our customers to purchase such shipping insurance when available.</li>
                <li><strong>Accuracy of Information.</strong> We attempt to ensure that information on the Service is complete, accurate and current. Despite our efforts, the information on the Service may occasionally be inaccurate, incomplete or out of date. We make no representation as to the completeness, accuracy or currentness of any information on the Service. For example, products included on the Service may be unavailable, may have different attributes than those listed, or may actually carry a different price than that stated on the Service. In addition, we may make changes in information about price and availability without notice. While it is our practice to confirm orders by email, the receipt of an email order confirmation does not constitute our acceptance of an order or our confirmation of an offer to sell a product or service. We reserve the right, without prior notice, to limit the order quantity on any product or service and/or to refuse service to any customer. We also may require verification of information prior to the acceptance and/or shipment of any order.</li>
                <li><strong>Use of the Service.</strong> The Service design and all text, graphics, information, content, and other material displayed on or that can be downloaded from the Service are either the property of, or used with permission by, RCM and are protected by copyright, trademark and other laws and may not be used except as permitted in theseTerms and Conditions or with the prior written permission of the owner of such material. You may not modify the information or materials located on the Service in any way or reproduce or publicly display, perform, or distribute or otherwise use any such materials for any public or commercial purpose. Any unauthorized use of any such information or materials may violate copyright laws, trademark laws, laws of privacy and publicity, and other laws and regulations. No portion or element of the Service or its content may be copied or retransmitted via any means. You are responsible for maintaining the confidentiality of your account information and password and for restricting access to such information and to your computer. You agree to accept responsibility for all activities that occur under your account or password.</li>
                <li><strong>Copyrights.</strong>The copyright in all content is and remains owned by RCM. Except as may be otherwise indicated in specific documents within the Service, you are authorized to view, play, print and download documents, audio and video found on the Service for personal, informational, and non-commercial purposes only. You may not modify any of the information and you may not copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer or sell any content. Except as authorized under the law relating to copyright, you may not reuse any content without first obtaining the written consent of RCM. For purposes of these terms, the use of any such material on any other Service or networked computer environment is prohibited. You will not remove any copyright, trademark or other proprietary notices from material found on the Service. In the event you download software (including but not limited to screensavers, icons, videos and wallpapers) from the Service, the software, including any files, images incorporated in or generated by the software, and data accompanying the software (the “Software”) are licensed to you by RCM. RCM does not transfer title to the Software to you. You may own the medium on which the Software is recorded, but RCM retains full and complete title to the Software, and all intellectual property rights therein. You may not redistribute, sell, decompile, reverse engineer, disassemble, or otherwise reduce the Software to a human-readable form.</li>
                <li><strong>Trademarks.</strong> Certain trademarks, trade names, RCM marks and logos used or displayed on the Service are registered and unregistered trademarks, trade names and service marks of RCM and/or our affiliates. Other trademarks, trade names and service marks used or displayed on the Service are the registered and unregistered trademarks, trade names and service marks of their respective owners. Nothing contained on the Service grants or should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any trademarks, trade names, service marks or logos displayed on the Service without our written permission or the written permission of such third party owner. You may not use, copy, reproduce, republish, upload, post, transmit, distribute, or modify RCM trademarks in any way, including in advertising or publicity pertaining to distribution of materials on the Service, without RCM’s prior written consent. The use of RCM trademarks on any other Service or network computer environment, for example the storage or reproduction of (a part of) the Service in any external internet site or the creation of links, hypertext, links or deep links between the Service and any other internet site, is prohibited without the express written consent of RCM.</li>
                <li><strong>Linking to the Service.</strong> Creating or maintaining any link from another website to any page on the Service without our prior written permission is prohibited. Running or displaying the Service or any material displayed on the Service in frames or through similar means on another Service without our prior written permission is prohibited. Any permitted links to the Service must comply will all applicable laws, rules, and regulations.</li>
                <li><strong>Third Party Links.</strong> From time to time and in an attempt to provide increased value to our visitors, the Service may contain links to websites that are not owned, operated or controlled by us or our affiliates. All such links are provided solely as a convenience to you. If you use these links, you will leave the Service. Neither we nor any of our affiliates are responsible for any content, materials or other information located on or accessible from any other website. Neither we nor any of our affiliates endorse, guarantee, or make any representations or warranties regarding any other website, or any content, materials or other information located or accessible from such websites, or the results that you may obtain from using such websites. If you decide to access any other website linked to or from the Service, you do so entirely at your own risk. Such linked websites may have separate terms and conditions and privacy and data collection practices, independent of RCM. Please review the privacy policy of any third-party website that you have linked to from the Service before you use such third-party website.</li>
                <li><strong>Inappropriate Material.</strong> You are prohibited from posting or transmitting any unlawful, threatening, infringing, false, misleading, inflammatory, invasive of privacy, abusive, discriminating, illegal, defamatory, libelous, obscene, pornographic or profane material or any material that could constitute or encourage conduct that would be considered a criminal offense or give rise to civil liability, violate the rights of any party or otherwise violate any law. In addition to any remedies that we may have at law or in equity, if we reasonably determine that you have violated or are likely to violate the foregoing prohibitions, we may take any action we reasonably deem necessary to cure or prevent the violation, including without limitation, the immediate removal from the Service of the related materials. We will fully cooperate with any law enforcement authorities or court order or subpoena requesting or directing us to disclose the identity of anyone posting such materials.</li>
                <li><strong>User Information.</strong>
                    <ul>
                        <li>Other than personally identifiable information, which is subject to the Service's Privacy Policy (available atwww.reelcodemedia.com), any material, information, suggestions, ideas, concepts, know-how, techniques, questions, comments or other communication you transmit or post to the Service in any manner (including, without limitation, in or on bulletin boards, chat rooms, community pages or other forums on the Service) (“User Communications”) is and will be considered non-confidential and non-proprietary. We, each of our affiliates and/or our designees may use, without notice, compensation or acknowledgement to you, any or all User Communications for any purpose whatsoever, including, without limitation, reproduction, transmission, disclosure, publication, broadcast, development, manufacturing and/or marketing in any manner whatsoever for any or all commercial or non-commercial purposes. User Communications include the text, software, scripts, graphics, photos, sounds, music, videos, audiovisual combinations, interactive features and other materials you may view on, access through, or contribute to the Service.</li>
                        <li>We do not guarantee the accuracy, integrity or quality of User Communications, and cannot assure that harmful, inaccurate, deceptive, offensive, threatening, defamatory, unlawful or otherwise objectionable User Communications will not appear on the Service. You acknowledge that by providing you with the ability to access and view User Communications on the Service, RCM is merely acting as a passive conduit for such distribution and is not undertaking any obligation or liability relating to any User Communications or activities of users on the Service. Without limiting the generality of the foregoing, you acknowledge and agree that the information, materials and opinions expressed or included in any User Communications are not necessarily those of RCM or its affiliated or related entities or content providers. We will have no liability related to the content of any such User Communications, whether or not arising under the laws of copyright, libel, privacy, obscenity, or otherwise. RCM does not, and you agree that RCM has no obligation to, monitor or review any User Communications. We shall have no obligation to use, return, review, or respond to any User Communications.</li>
                        <li>Notwithstanding the foregoing, you acknowledge and agree that RCM has the absolute right to monitor User Communications posted to the Service in its sole discretion. In addition, RCM reserves the right to alter, edit, refuse to post or remove any such User Communications, in whole or in part, for any reason or for no reason, and to disclose such User Communications and the circumstances surrounding their transmission to any third party.</li>
                        <li>You agree that you shall immediately notify RCM in writing of any objectionable content appearing on the Service. RCM will make good-faith efforts to investigate allegations that any User Communications violate these Terms and Conditions but (a) makes no warranty to you that it will edit, remove, or continue to permit the display of any specific User Communications, whether or not subject to such allegations, and (b) will have no liability whatsoever for editing, removing, or continuing to permit the display of any User Communications whatsoever.</li>
                        <li>Any use of any content on the Service, including, without limitation, User Communications will be at your own risk. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, YOU ACKNOWLEDGE AND AGREE THAT RCM IS NOT RESPONSIBLE FOR ANY USER GENERATED CONTENT THAT PURPORTS TO GIVE MEDICAL ADVICE OR ADVICE REGARDING PERSONAL, GROUP OR CORPORATE SAFETY AND SECURITY, FITNESS TRAINING, EXERCISE, OR DIET. ALWAYS SEEK THE ADVICE OF YOUR PHYSICIAN OR OTHER QUALIFIED HEALTH PROVIDER WITH ANY QUESTIONS YOU MAY HAVE REGARDING A MEDICAL CONDITION OR A PHYSICAL FITNESS PROGRAM.</li>
                        <li>You may not post or otherwise distribute content to the Service which RCM in its sole discretion determines to be abusive, harassing, threatening, defamatory, obscene, an impersonation of others, fraudulent, deceptive, misleading, in violation of a copyright, trademark or other intellectual property right of another, invasive of privacy or publicity rights, in violation of any other right of RCM or another party, illegal, or otherwise objectionable to RCM. You may not upload commercial content on the Service or use the Service to solicit others to join or become members of any other commercial online Service or other organization. You may be required to register and/or agree to additional terms of use prior to being permitted to post or otherwise distribute User Communications to the Service.</li>
                    </ul>
                </li>
            </ul>
         </div>    
      </div>

      <Footer />
    </>
  );
};

export default TermsConditions;
