import React from 'react';

import { Button, Col, Row } from 'react-bootstrap';

import { Container } from './styles';

import useIcons from '../../../hooks/useIcons';

import close from '../../../assets/icons/close.svg';

import { CustomIconsProps } from '../../../interfaces';

const CustomIcons: React.FC<CustomIconsProps> = (props) => {
  const keys = useIcons();
  const { apply } = props;

  const handleDragStart = (event: React.DragEvent<HTMLImageElement>) => {
    event.dataTransfer.setData('id', event.currentTarget.id);
  };

  return (
    <Container className={props.show ? '' : 'hide'}>
      <div className='button-close'>
        <button onClick={props.close}>
          <img src={close} alt='close'></img>
        </button>
      </div>
      <Row>
        <Col className='icons-list'>
          {keys.map(([key, value]) => (
            <img
              id={key}
              draggable={true}
              key={key}
              src={value}
              alt={`Option ${key}`}
              title={`${key}`}
              onDragStart={handleDragStart}
            ></img>
          ))}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='button-apply'>
            <Button variant='dark' onClick={apply}>
              Apply
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CustomIcons;
