import styled from 'styled-components';

export const Container = styled.div`
  table {
    thead {
      margin: 0;
      background-color: ${(props) => props.theme.colors.backgrounds.six};
    }
    tr {
      th {
        &:first-child {
          flex: 0 0 25%;
        }
        &:last-child {
          flex: 0 0 75%;
        }
      }
    }
    tbody {
      display: flex;
      overflow: auto;
      height: 30rem;
      justify-content: space-between;
      flex-wrap: wrap;
      font-family: var(--font-secundary);
    }

    tr {
      flex: 0 0 100%;

      :nth-child(even) {
        background-color: ${(props) => props.theme.colors.backgrounds.six};
      }

      td {
        &:first-child {
          flex: 0 0 25%;
        }
        &:last-child {
          flex: 0 0 75%;
        }
      }
    }
  }
`;
