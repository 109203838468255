import React from 'react';

import { Container, Col, Row } from 'react-bootstrap';

import Menu from '../Menu';

const Dashboard: React.FC = () => {
  return (
    <Container>
      <Row>
        <Menu/>

        <Col md={8}>
          <h1></h1>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
