export interface Step {
  id: number;
  title: string;
  placeholder: string;
  tip: string;
  currentId?: number;
  hoverTip: boolean;
  onClick?: React.MouseEventHandler;
}

export enum Position {
  cornerTopLeft,
  cornerTopRight,
  cornerBottomLeft,
  cornerBottomRight
}

interface Corner {
  position: Position;
  icon?: string;
  iconId: number;
  contentTypeId: number;
}

export interface CustomIconsProps {
  show: boolean;
  corners: Corner[];
  apply?: React.FormEventHandler;
  close?: React.MouseEventHandler;
}

export interface ContentTypeProps {
  show: boolean;
  apply?: React.FormEventHandler;
  close?: React.MouseEventHandler;
  fields: ContentTypeFields[];
}

export interface ReelGenerator {
  title: string;
  currentStep: number;
  image: Image;
  name: string;
  indsutryId: number;
  layout?: 'layout1' | 'layout2' | 'layout3' | string;
  oldLayout: string;
  galleryId: number;
  contentType: ContentTypeProps;
  steps: Step[];
  icons: CustomIconsProps;
}

export interface ContentTypeFields {
  contentTypeId: number;
  value: string;
  iconId?: number;
  cornerId?: number;
}

export interface Image {
  file?: File;
  path: string;
  name: string;
  type: string;
}

export interface Model {
  id: number;
  description: string;
}

export interface SubscriptionOptions extends Model {
  analyticsLevelId: number;
  annualFee: number;
  loyaltyProgram: boolean;
  marketingSolution: boolean;
  monthlyFee: number;
  monthlyFrames: number;
  name: string;
  socialIntegration: boolean;
  technicalSupport: boolean;
  trialEnabled: boolean;
  videoUploadLimit: number;
}

export interface SubscriptionOptionsResult extends Result {
  result: SubscriptionOptions[];
}

export interface User {
  userId: number;
  accessToken: string;
  email: string;
  subscriptionStatus: string;
  displayName: string;
  firstName: string;
  lastName: string;
  expired: boolean;
  website: string;
  password: string;
  totalFrames: number;
  address: {
    id: number;
    addressType: 1;
    addressLine1: string;
    addressLine2: null;
    city: string;
    state: string;
    zipCode: string;
    contactNumber: string;
    country: number;
    countryId: number;
    userId: number;
  };
}

export interface UserSubscription extends Model {
  amount: number;
  cardNumber: string;
  paymentTypeId: number;
  paymentTypeName: string;
  requestSubscriptionDate: string;
  requestSubscriptionLevelId: number;
  subscriptionDate: string;
  subscriptionLevel: string;
  subscriptionLevelId: number;
  subscriptionMode: string;
  subscriptionModeId: number;
  subscriptionStatus: string;
  subscriptionToDate: number;
  toBeBilled: string;
}

export interface UserSubscriptionResult extends Result {
  result: UserSubscription;
}

export interface UserResult extends Result {
  result: User;
}

export interface UserLoginHistory extends Model {
  emailId: string;
  loginTime: string;
}

export interface UserLoginHistoryResult extends Result {
  result: UserLoginHistory[];
}

export interface FolderList extends Result {
  result: Model[];
}

export interface Folder extends Result {
  result: Model;
}

export interface FrameSearch {
  pageSize: number;
  pageSequence: number;
  activated: number;
  search: string;
  frameCategory: string;
}

export interface Frame {
  id: number;
  name: string;
  imageUrl: string;
  frameImageUrl: string;
  industryId: number;
  thumbnailUrl: string;
  galleryId: number;
  updatedImageURL: string;
  idFrameType: number;
  frameBackgroundUrl: string;
  updatedImageUrlMobile: string;
  frameStatus: string;
  borderColor: string;
  corners?: FrameCorner[];
  totalCampaigns: number;
  newCampaigns: number;
  activeCampaigns: number;
  industryName: string;
  imageWidth: number;
  imageHeight: number;
  generator: string;
}

export interface FrameCorner {
  id: number;
  contentTypeId: number;
  cornerTypeId: number;
  iconId: number;
  createdOn?: Date;
  cornerContentTypeId?: number;
  cornerContentValue?: string;
  contentValue: string;
  countryCode?: string;
}

interface FrameResultPage extends Result {
  foundRecords: number;
  pageSequence: number;
  pageSize: number;
  records: Frame[];
  totalRecords: number;
  totalPages: number;
}
export interface FrameResult extends Result {
  result: Frame;
}

export interface ContactUs {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  subject: string;
  message: string;
  industry: 1;
}

export interface Result {
  status: string;
  err_code?: string;
  err_message?: string;
  err_msg?: string; //api has no pattern
}

export interface FrameList extends Result {
  result: FrameResultPage;
}
export interface RegisterResult extends Result {
  result?: Register;
}
export interface Register {
  id?: number;
  country: string;
  countryId: number;
  email: string;
  password: string;
  firstName: string;
  addressLine1: string;
  city: string;
  zipCode: string;
  state: string;
  captcha: string;
  industry: number;
  website: string;
  lastName: string;
  confirmEmail: string;
  challengeField: string;
  responseField: string;
  phone: string;
  displayName: string;
  repeatPassword: string;
  domainId: number;
}

export interface GalleryResult extends Result {
  result: Gallery;
}

interface Gallery {
  galleryId: number;
  imagePath: string;
  thumbImagePath: string;
  size: number;
  width: number;
  height: number;
}

export interface NewFrame {
  frameTypeId: 0 | 1 | 2 | number;
  gallery_id: number;
}

export interface NewFrameResult extends Result {
  result: Frame;
}

export interface DownloadResult extends Result {
  result: Download;
}

interface Download {
  downloadUrl: string;
}

export interface ResultSummary extends Result {
  foundRecords: number;
  pageSequence: number;
  pageSize: number;
  result: SummaryRecord;
}

export interface SummaryRecord {
  records: Summary[];
}

export interface Summary {
  frameName: string;
  id: number;
  frameId: number;
  scanned: 0;
  viewed: 0;
  swipedBottomLeft: 0;
  swipedBottomRight: 0;
  swipedTopLeft: 0;
  swipedTopRight: 0;
  swipedTotal: 0;
  updatedImageURL: string;
  updatedOn: Date;
}

export interface ChartResult extends Result {
  result: ChartData[];
}

export interface ChartData {
  dayId: number | null;
  weekId: number | null;
  monthId: number | null;
  quarterId: number | null;
  yearId: number | null;
  dayIdStr: string | null;
  weekIdStr: string | null;
  monthIdStr: string | null;
  quarterIdStr: string | null;
  yearIdStr: string | null;
  viewCount: number;
  scanCount: number;
  swipeCount: number;
  topLeftSwipeCount: number;
  topRightSwipeCount: number;
  bottomLeftSwipeCount: number;
  bottomRightSwipeCount: number;
}

export interface Analytics {
  name: string;
  dayFilter: boolean;
  weekFilter: boolean;
  monthFilter: boolean;
  quarterFilter: boolean;
  yearFilter: boolean;
  industryId: 0;
  frameId: number;
  countryId: 0;
}

export interface Purchase extends Model {
  transaction: string;
  amount: number;
  createdOn: string;
  status: number;
  reference: number;
  taxAmount: number;
}
export interface PurchaseResult extends Result {
  result: Purchase[];
}

export interface Contacts {
  id: number | null;
  name: string;
  email: string;
  groupId: number;
  itemId: number;
}

export interface ContactsResult extends Result {
  result: Contacts[];
}

export interface ContactsGroup {
  id: number;
  name: string;
}

export interface ContactsGroupResult extends Result {
  result: ContactsGroup[];
}
