export interface Price {
  name: string;
  prices: string[];
  types: string[];
  options: string[];
}

export const prices: Price[] = [
  {
    name: 'STARTER',
    prices: ['$12/ MONTH', '$99/YEAR'],
    types: ['monthly', 'yearly'],
    options: [
      '30 - day FREE trial',
      '1 RCM Frame per month',
      'Unlimited image changes',
      'Unlimited content changes, editable content',
      '30 second video per frame corner',
      'Unlimited scans per month',
      'Unlimited downloads per month',
      'Track # of scans for each content',
      'Track total # of scans',
      'Filter by project',
      'Unlimited tech support'
    ]
  },
  {
    name: 'BASIC',
    prices: ['$89/ MONTH', '$979/YEAR'],
    types: ['monthly', 'yearly'],
    options: [
      '30 - day FREE trial',
      'Up to 10 RCM Frames per month',
      'Unlimited image changes',
      'Unlimited content changes, editable content',
      '2 minute video per frame',
      'Unlimited scans per month',
      'Unlimited downloads per month',
      'Track # of scans for each content',
      'Track total # of scans',
      'Filter by project',
      'Unlimited tech support'
    ]
  },
  {
    name: 'ADVANCED',
    prices: ['$249/ MONTH', '$2,699/YEAR'],
    types: ['monthly', 'yearly'],
    options: [
      '30 - day FREE trial',
      'Up to 10 RCM Frames per month',
      'Unlimited image changes',
      'Unlimited content changes, editable content',
      '2 minute video per frame',
      'Unlimited scans per month',
      'Unlimited downloads per month',
      'Track # of scans for each content',
      'Track total # of scans',
      'Filter by project',
      'Unlimited tech support'
    ]
  },
  {
    name: 'PREMIUM',
    prices: ['$399/ MONTH', '$4,290/YEAR'],
    types: ['monthly', 'yearly'],
    options: [
      '30 - day FREE trial',
      'Up to 50 RCM Frames per month',
      'Unlimited image changes',
      'Unlimited content changes, editable content',
      '4 minute  video per frame corner',
      'Unlimited scans per month',
      'Unlimited downloads per month',
      'Track # of scans for each content',
      'Track total # of scans',
      'Filter by project',
      'Unlimited tech support'
    ]
  },
  {
    name: 'GOLD',
    prices: ['$699/ MONTH', '$7,390/YEAR'],
    types: ['monthly', 'yearly'],
    options: [
      '30 - day FREE trial',
      'Up to 100 RCM Frames per month',
      'Unlimited image changes',
      'Unlimited content changes, editable content',
      '6 minute video per frame',
      'Unlimited scans per month',
      'Unlimited downloads per month',
      'Track # of scans for each content',
      'Track total # of scans',
      'Filter by project',
      'Unlimited tech support'
    ]
  },
  {
    name: 'PLATINUM',
    prices: ['$5,999/ MONTH', '$56,900/YEAR'],
    types: ['monthly', 'yearly'],
    options: [
      '30 - day FREE trial',
      'Up to 1,000 RCM Frames per month',
      'Unlimited image changes',
      'Unlimited content changes, editable content',
      '8 minute video per frame',
      'Unlimited scans per month',
      'Unlimited downloads per month',
      'Track # of scans for each content',
      'Track total # of scans',
      'Filter by project',
      'Unlimited tech support'
    ]
  }
];
