import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const Container = styled(Modal)`
  .folder-modal {
    display: flex;
    align-items: center;
    &-input {
      margin-right: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
    }
    &-label {
      font-size: 1.6rem;
      margin: 0;
    }
  }

  .modal-body {
    @media (max-width: 425px) {
      p {
        max-width: 30rem;
        margin: 0 auto;
        display: block;
      }
    }
    a {
      color: ${(props) => props.theme.colors.backgrounds.two};
    }
  }
`;
