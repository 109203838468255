import { getAllByLabelText } from '@testing-library/react';
import {
  DownloadResult,
  Frame,
  FrameCorner,
  FrameList,
  FrameResult,
  FrameSearch,
  NewFrame,
  NewFrameResult,
  ReelGenerator,
  Result,
  ResultSummary
} from '../interfaces';

import { uploadGallery, get, deleteApi, post } from './api';
import { createIndustry } from './industry';

export async function createNewFrame(generator: ReelGenerator, down = false) {
  const blob = await fetch(generator.image.path).then((r) => r.blob());

  const file = new File([blob], generator.image.name, {
    type: generator.image.type
  });

  const result = await uploadGallery(file);
  if (result.data.status !== 'success') return result.data;

  const galleryResult = result.data;

  if (galleryResult.status === 'success') {
    const frameTypeId = getLayout(generator.layout || 'layout1');

    const frameResponse = await createFrame({
      gallery_id: galleryResult.result.galleryId,
      frameTypeId
    });

    if (frameResponse.data.status === 'success') {
      await createIndustry(frameResponse.data.result.id);

      frameResponse.data.result.corners?.forEach((corner, index) => {
        corner.cornerContentValue = generator.contentType.fields[index].value;
        corner.cornerContentTypeId =
          generator.icons.corners[index].contentTypeId;
        corner.cornerTypeId = generator.icons.corners[index].position + 1;
        corner.iconId = generator.icons.corners[index].iconId;
        updateCorner(corner);
      });
      localStorage.removeItem('frame');

      /* if (down) {
        downloadFrame(frameResponse.data.result.id);
      }*/
    }
    return frameResponse.data;
  }
}

export async function saveName(frame: Frame) {
  const result = await saveFrameName(frame);
  return result.data;
}

export async function findFrame(frameId: string) {
  const result = await getFrame(frameId);
  return result.data;
}

export async function getSummary() {
  const result = await post<ResultSummary>(
    { pageSize: 50, pageSequence: 0, activated: 0, search: '' },
    'frame/search/mobile/summary/page'
  );
  return result.data;
}

//cornerTypeId == position
//cornerContentTypeId == select option
export async function updateCorner(frame: FrameCorner) {
  const data = {
    cornerId: frame.id,
    cornerTypeId: frame.cornerTypeId,
    cornerContentTypeId: frame.cornerContentTypeId,
    cornerContentValue: frame.cornerContentValue,
    countryCode: 'us',
    iconId: frame.iconId
  };

  return await post<Result>(data, '/frame/updateCorner');
}

export async function updateCornerValues(
  generator: ReelGenerator,
  id: number,
  down = false
) {
  if (id && generator.name) {
    await saveFrameName({
      id: id,
      name: generator.name,
      industryId: 20
    } as Frame);
  }

  generator.contentType.fields.forEach((corner, index) => {
    const data = {
      id: corner.cornerId || 0,
      iconId: generator.icons.corners[index].iconId,
      cornerTypeId: generator.icons.corners[index].position + 1,
      contentTypeId: corner.contentTypeId,
      cornerContentTypeId: corner.contentTypeId,
      contentValue: corner.value,
      cornerContentValue: corner.value
    };
    updateCorner(data);
  });
}

export async function deleteFrame(id: number) {
  return await deleteApi(id, 'frame');
}

export async function saveFrameName(frame: Frame) {
  const { id, name, industryId } = frame;
  return await get<Result>(`frame/updateFrame/${id}/${name}/${industryId}`);
}

export async function saveFrameNameById(
  id: number,
  name: string,
  industryId: number
) {
  return await get<Result>(`frame/updateFrame/${id}/${name}/${industryId}`);
}

export function getFrames(search: FrameSearch) {
  return post<FrameList>(search, 'frame/search/page');
}

export function getFrame(id: string) {
  return get<FrameResult>(`frame/current/user/${id}`);
}

export function downloadFrame(id: number) {
  return get<DownloadResult>(`frame/download/${id}`);
}

export function createFrame(data: NewFrame) {
  return post<NewFrameResult>(data, '/frame');
}

export function changeLayout(
  frameId: number,
  layout: string,
  galleryId: number
) {
  const frameTypeId = getLayout(layout);

  const data = {
    frameTypeId,
    galleryId
  };
  return post<Result>(data, `/frame/${frameId}/keep-img`);
}

export function getGallery() {
  return get<Result>('gallery');
}

export async function changeImage(frameId: number, generator: ReelGenerator) {
  const blob = await fetch(generator.image.path).then((r) => r.blob());

  const file = new File([blob], generator.image.name, {
    type: generator.image.type
  });

  const result = await uploadGallery(file);
  if (result.data.status !== 'success') return result.data;

  const galleryResult = result.data;
  const frameTypeId = getLayout(generator.layout || 'layout1');

  const data = {
    gallery_id: galleryResult.result.galleryId,
    frameTypeId
  };

  return post<Result>(data, `/frame/${frameId}`);
}

function getLayout(layout: string) {
  switch (layout) {
    case 'layout1':
      return 1;
    case 'layout2':
      return 2;
    default:
      return 0;
  }
}
