import styled from 'styled-components';

export const Container = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    text-align: center;
    font-family: var(--font-secundary);
    font-size: 1.6rem;
    font-weight: 700;
  }
`;
