import axios from 'axios';

import {
  Result,
  FolderList,
  Register,
  RegisterResult,
  User,
  GalleryResult,
  UserResult
} from '../interfaces';

const url = 'https://reelcodemedia.com/api/';

const getAuthHeader = (contentType = 'application/json;charset=UTF-8') => {
  const token = localStorage.getItem('users');
  const users: User = JSON.parse(token || '{}');

  return {
    headers: {
      Authorization: token ? users.accessToken : '',
      'Content-Type': contentType,
      Language: 'en'
    }
  };
};

const client = axios.create({ baseURL: url });
client.defaults.withCredentials = true;

client.interceptors.request.use(
  function (config) {
    toogleGif();
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  function (response) {
    if (response.data.status === 'fail') {
      if (response.data.exception_type === 'Authentication Exception')
        localStorage.removeItem('users');
    }
    toogleGif(false);
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export function toogleGif(show = true) {
  const gif = document.getElementById('page-loader');
  const blocker = document.getElementById('ui-blocker');
  document.body.style.overflow = show ? 'hidden' : 'auto';
  if (gif) gif.style.display = show ? 'block' : 'none';
  if (blocker) blocker.style.display = show ? 'block' : 'none';
}

export function post<T>(
  data: any,
  endpoint: string,
  multidata: boolean = false
) {
  if (multidata) {
    return client.post<T>(
      endpoint,
      getFromData(data),
      getAuthHeader('multipart/form-data')
    );
  }
  return client.post<T>(endpoint, data, getAuthHeader());
}

export function postFormData<T>(
  data: any,
  endpoint: string,
  multidata: boolean = false
) {
  if (multidata) {
    return client.post<T>(
      endpoint,
      getFromData(data),
      getAuthHeader('multipart/form-data')
    );
  }
  return client.post<T>(endpoint, data, getAuthHeader());
}

export function deleteApi(id: number, endpoint: string) {
  return client.delete<Result>(`${endpoint}/${id}`, getAuthHeader());
}

export function get<T>(endpoint: string) {
  return client.get<T>(endpoint, getAuthHeader());
}

export function put<T>(data: any, endpoint: string) {
  return client.put<T>(endpoint, data, getAuthHeader());
}

export function signIn(email: string, password: string) {
  return client.post<UserResult>('users/login', {
    email,
    password
  });
}

export function getCategories() {
  return get<FolderList>('frame/categories');
}

export function newAccount(data: Register) {
  return client.post<RegisterResult>('directRegistration', getFromData(data), {
    headers: { Language: 'en' }
  });
}

export function uploadGallery(file: File) {
  const data = {
    upload: file
  };

  return client.post<GalleryResult>(
    'gallery/',
    getFromData(data),
    getAuthHeader('multipart/form-data')
  );
}

export function deleteGallery(id: number[]) {
  return client.post<Result>('gallery/delete', { galleryIds: id.toString() });
}

function getFromData(data: Object): FormData {
  const formData = new FormData();
  const keys = Object.entries(data);

  keys.forEach((key) => {
    if (key[0] === 'address') {
      formData.append(key[0], JSON.stringify(key[1]));
    } else formData.append(key[0], key[1]);
  });
  return formData;
}
