import React, { useRef } from 'react';
import RcmModal from '../../../../components/Modal';

import { Form, Row, Col, ModalProps, Button } from 'react-bootstrap';
import useContactsGroup from '../../../../hooks/useContactsGroup';

// import { Container } from './styles';

const Group: React.FC<ModalProps> = (props:any) => {
  const [contactsGroup, addContactGroup] = useContactsGroup();
  const refInput = useRef<HTMLInputElement>(null);

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (refInput.current) {
      const contactGroup = {
        id: 0,
        name: refInput.current.value
      };
      addContactGroup(contactGroup);
      props.onHide();
    }
    e.preventDefault();
  };

  return (
    <RcmModal
      title='Add New Group'
      message=''
      show={props.show}
      onHide={props.onHide}
    >
      <Form onSubmit={handleOnSubmit}>
        <Row>
          <Col>
            <Form.Group controlId='formName'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Name'
                required
                ref={refInput}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className='d-flex align-items-center actions-group'>
            <Form.Group controlId='formButton' className=''>
              <Button variant='success' type='submit'>
                <span className='mr-2'>Save</span>
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </RcmModal>
  );
};

export default Group;
