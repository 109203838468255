import React, { useState } from 'react';

import { Row, Col } from 'react-bootstrap';

import preview from '../../../assets/images/preview.jpg';
import overlay from '../../../assets/images/overlay.png';
import overlayLeftRight from '../../../assets/images/overlay-lf.png';
import overlayTopBottom from '../../../assets/images/overlay-tb.png';

import { Container } from './styles';
import { ReelGenerator, Position } from '../../../interfaces';

import Title from '../../../components/Title';

import useIcons from '../../../hooks/useIcons';
import { findByName, IconPosition } from '../../../service/icon';

const Preview: React.FC<ReelGenerator> = (props) => {
  const { image, children, currentStep, icons, layout } = props;
  const { cornerTopLeft, cornerTopRight, cornerBottomLeft, cornerBottomRight } =
    Position;

  const [corners, setCorners] = useState({ ...icons.corners });

  const keys = useIcons();

  function findIcon(name: string = 'COUPON') {
    return keys.find(([key, value]) => key === name)?.[1];
  }

  function getOverlay() {
    if (layout === 'layout1') return overlayLeftRight;

    if (layout === 'layout2') return overlayTopBottom;

    if (layout === 'layout3') return overlay;
  }

  const handleOnDrop = (event: React.DragEvent<HTMLSpanElement>) => {
    event.preventDefault();
    let number = 0;
    if (event.currentTarget?.parentNode instanceof HTMLElement) {
      number = Number(event.currentTarget?.parentNode.id);
    }

    const cornerId = event.currentTarget.id
      ? Number(event.currentTarget.id)
      : number;
    props.icons.corners[cornerId].icon = event.dataTransfer.getData('id');
    event.currentTarget.focus();

    let position: IconPosition = IconPosition.TL;
    if (cornerId === 1) {
      position = IconPosition.TR;
    }

    if (cornerId === 2) {
      position = IconPosition.BL;
    }

    if (cornerId === 3) {
      position = IconPosition.BR;
    }

    props.icons.corners[cornerId].iconId = findByName(
      props.icons.corners[cornerId].icon || '',
      position
    );

    setCorners({ ...props.icons.corners });
    event.currentTarget.classList.remove('focus');
  };

  const handleOnDragOver = (event: React.DragEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.currentTarget.classList.add('focus');

    Array.from(document.querySelectorAll('.focus'))
      .find((e) => e.id !== event.currentTarget.id)
      ?.classList.remove('focus');
  };

  return (
    <Container>
      <Row>
        <Col>
          <Title>Preview</Title>
          <div className='wrapper'>
            <div className={`top-icons ${currentStep > 2 ? '' : 'hide'}`}>
              <span
                onDrop={handleOnDrop}
                onDragOver={handleOnDragOver}
                className='corner-content'
                id={`${cornerTopLeft}`}
              >
                <svg>
                  <path
                    d='M53.7266 24.9247C52.9672 11.0309 41.4544 0 27.3688 0C12.7884 0 0.969238 11.8192 0.969238 26.3996C0.969238 40.5901 12.1661 52.1651 26.2078 52.7724L26.181 52.7991H53.7684V24.8829L53.7266 24.9247Z'
                    className='pointer'
                  />
                </svg>

                {corners[0].icon && (
                  <img
                    className='corner-icon'
                    src={findIcon(corners[0].icon)}
                    alt='corner icon'
                  />
                )}
              </span>
              <span
                onDrop={handleOnDrop}
                onDragOver={handleOnDragOver}
                className='corner-content'
                id={`${cornerTopRight}`}
              >
                <svg>
                  <path
                    d='M0.917748 24.9247C1.68249 11.0309 13.19 0 27.2755 0C41.8559 0 53.6751 11.8192 53.6751 26.3996C53.6751 40.5901 42.4782 52.1651 28.4366 52.7724L28.4634 52.7991H0.875977V24.8829L0.917748 24.9247Z'
                    className='pointer'
                  />
                </svg>

                {corners[1].icon && (
                  <img
                    onDrop={handleOnDrop}
                    onDragOver={handleOnDragOver}
                    className='corner-icon'
                    src={findIcon(corners[1].icon)}
                    alt='corner icon'
                  />
                )}
              </span>
            </div>

            <img
              alt='preview'
              src={image.path ? image.path : preview}
              className='preview'
            />
            {currentStep >= 2 && layout && (
              <img alt='preview' src={getOverlay()} className='preview' />
            )}

            <div className={`bottom-icons ${currentStep > 2 ? '' : 'hide'}`}>
              <span
                onDrop={handleOnDrop}
                onDragOver={handleOnDragOver}
                className='corner-content'
                id={`${cornerBottomLeft}`}
              >
                <svg>
                  <path
                    d='M52.7574 27.8744C51.998 41.7683 40.4851 52.7991 26.3996 52.7991C11.8192 52.7991 0 40.98 0 26.3996C0 12.2091 11.1969 0.63407 25.2385 0.0267766L25.2118 0H52.7991V27.9162L52.7574 27.8744Z'
                    className='pointer'
                  />
                </svg>

                {corners[2].icon && (
                  <img
                    className='corner-icon'
                    src={findIcon(corners[2].icon)}
                    alt='corner icon'
                  ></img>
                )}
              </span>
              <span
                onDrop={handleOnDrop}
                onDragOver={handleOnDragOver}
                className='corner-content'
                id={`${cornerBottomRight}`}
              >
                <svg>
                  <path
                    d='M0.917748 27.8744C1.68249 41.7683 13.19 52.7991 27.2755 52.7991C41.8559 52.7991 53.6751 40.98 53.6751 26.3996C53.6751 12.2091 42.4782 0.63407 28.4366 0.0267766L28.4634 0H0.875977V27.9162L0.917748 27.8744Z'
                    className='pointer'
                  />
                </svg>

                {corners[3].icon && (
                  <img
                    className='corner-icon'
                    src={findIcon(corners[3].icon)}
                    alt='corner icon'
                  ></img>
                )}
              </span>
            </div>
          </div>
        </Col>
      </Row>
      {children}
    </Container>
  );
};

export default Preview;
